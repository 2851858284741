*[data-v-c2d2ef56] {
    padding: 0;
    margin: 0;
    outline: 0;
}
ol[data-v-c2d2ef56],
ul[data-v-c2d2ef56] {
    list-style-type: none;
    margin: 0;
}
ul li[data-v-c2d2ef56] {
    color: #000;
}
h1[data-v-c2d2ef56],
h2[data-v-c2d2ef56],
h3[data-v-c2d2ef56],
h4[data-v-c2d2ef56],
h5[data-v-c2d2ef56],
h6[data-v-c2d2ef56],
a[data-v-c2d2ef56],
p[data-v-c2d2ef56],
i[data-v-c2d2ef56],
span[data-v-c2d2ef56],
input[data-v-c2d2ef56] {
    padding: 0;
    margin: 0;
}
h1[data-v-c2d2ef56],
h2[data-v-c2d2ef56],
h3[data-v-c2d2ef56],
h4[data-v-c2d2ef56],
h5[data-v-c2d2ef56], h6[data-v-c2d2ef56] {
    color: #000;
}
body[data-v-c2d2ef56] {
    font-family: 'Manrope', sans-serif;
}
a[data-v-c2d2ef56],
a[data-v-c2d2ef56]:hover {
    color: #000;
    text-decoration: none;
}
img[data-v-c2d2ef56] {
    vertical-align: middle;
}

/* main css start */
.main-section[data-v-c2d2ef56] {
    padding: 2rem 0;
}
.header-top h6[data-v-c2d2ef56] {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #64748B;
    margin-bottom: 0.2rem;
}
.header-txt[data-v-c2d2ef56] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.header-txt h1[data-v-c2d2ef56] {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #0F172A;
}
.header-bttn[data-v-c2d2ef56] {
    display: flex;
    align-items: center;
}
.header-bttn a[data-v-c2d2ef56]:first-child {
    border: 1px solid #2563EB;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #2563EB;
    display: inline-block;
    padding: 0.7rem 1rem;
    transition: all linear .3s;
}
.header-bttn a[data-v-c2d2ef56]:first-child:hover {
    background-color: #2563EB;
    color: #fff;
}
.header-bttn a[data-v-c2d2ef56]:last-child {
    background-color: #2563EB;
    border: 1px solid #2563EB;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    display: inline-block;
    padding: 0.65rem 1rem;
    margin-left: 1rem;
    transition: all linear .3s;
}
.header-bttn a[data-v-c2d2ef56]:last-child:hover {
    background-color: #fff;
    color: #2563EB;
}
.header-bttn a i[data-v-c2d2ef56] {
    margin-right: 0.2rem;
}
.header-bttn a:last-child i[data-v-c2d2ef56] {
    background: #3B82F6;
    display: inline-block;
    padding: 5px;
    border-radius: 4px;
    font-size: 10px;
}
.header-bttn a:last-child:hover i[data-v-c2d2ef56] {
    color: #fff;
}

/* main css end */


/* faq css start */
.faq_main_wrap .parent-card-header[data-v-c2d2ef56] {
    background: #F1F5F9;
    border-radius: 8px;
}
.faq_txt_wrap .card-header-inner[data-v-c2d2ef56] {
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 4px;
    margin-top: 0.5rem;
}
.faq_txt_wrap .card-header-inner .btn .title-4[data-v-c2d2ef56],
.faq_main_wrap .parent-card-header .btn .title-4[data-v-c2d2ef56] {
    display: flex;
    align-items: center;
}
.faq_txt_wrap .card-header-inner .btn .title-4 img[data-v-c2d2ef56],
.faq_main_wrap .parent-card-header .btn .title-4 img[data-v-c2d2ef56] {
    margin-right: 8px;
}
.faq_txt_wrap .card-header-inner .btn .title-6[data-v-c2d2ef56],
.faq_main_wrap .parent-card-header .btn .title-6[data-v-c2d2ef56] {
    display: flex;
    align-items: center;
}
.faq_txt_wrap .card-header-inner .btn .title-6 i[data-v-c2d2ef56],
.faq_main_wrap .parent-card-header .btn .title-6 i[data-v-c2d2ef56] {
    font-size: 20px;
    transition: all linear .3s;
}
.faq_txt_wrap .card-header-inner .btn[data-v-c2d2ef56],
.faq_main_wrap .parent-card-header .btn[data-v-c2d2ef56] {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.faq_main_wrap .parent-card[data-v-c2d2ef56] {
    margin-bottom: 1rem;
}
.faq_txt_wrap .card-header-inner .btn[data-v-c2d2ef56]:focus-within,
.faq_main_wrap .parent-card-header .btn[data-v-c2d2ef56]:focus-within {
    box-shadow: none;
}
.faq_main_wrap .parent-card-header .btn span.title-4[data-v-c2d2ef56] {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1E293B;
}
.faq_txt_wrap .card-header-inner .btn span.title-4[data-v-c2d2ef56] {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1E293B;
}
.pointer-main-wrap[data-v-c2d2ef56] {
    margin-left: 2.5rem;
    position: relative;
}
.pointer-main-wrap[data-v-c2d2ef56]::before {
    position: absolute;
    content: "";
    left: -22px;
    top: -9px;
    height: 95%;
    width: 2px;
    background-color: #CBD5E1;
}
.pointer-main-wrap .card-header-inner .btn[data-v-c2d2ef56] {
    padding: 3px 5px;
}
.pointer-main-wrap .card-header-inner[data-v-c2d2ef56] {
    background: #F8FAFC;
    position: relative;
}
.pointer-main-wrap .card-header-inner[data-v-c2d2ef56]::before {
    position: absolute;
    content: "";
    left: -22px;
    top: 40%;
    height: 2px;
    width: 2%;
    background-color: #CBD5E1;
}
.pointer-main-wrap .card-header-inner .btn span.title-4[data-v-c2d2ef56] {
    color: #1D4ED8;
}
.faq_txt_wrap .card-header-inner .dropdown-menu[data-v-c2d2ef56],
.faq_main_wrap .parent-card-header .dropdown-menu[data-v-c2d2ef56] {
    padding: 0;
}
.faq_txt_wrap .card-header-inner .dropdown-menu ul li a[data-v-c2d2ef56],
.faq_main_wrap .parent-card-header .dropdown-menu ul li a[data-v-c2d2ef56] {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: rgba(3, 2, 39, 0.7);
    padding: 10px 20px;
    display: block;
}
.faq_txt_wrap .card-header-inner .dropdown-menu ul li:last-child a[data-v-c2d2ef56],
.faq_main_wrap .parent-card-header .dropdown-menu ul li:last-child a[data-v-c2d2ef56] {
    background: #FEF2F2;
}
.faq_main_wrap [data-toggle="collapse"][aria-expanded="true"] .title-6 i[data-v-c2d2ef56] {
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important;
    color: #E02844 !important;
}
.faq_txt_wrap .card-header-inner[data-v-c2d2ef56] {
    position: relative;
}
.faq_txt_wrap .title-4 i[data-v-c2d2ef56] {
    background: #F0F9FF;
    border: 1px solid #CBD5E1;
    border-radius: 3px;
    display: inline-block;
    padding: 4px 5px;
    color: #0F172A;
    font-size: 10px;
    position: absolute;
    left: -11px;
}
.addmore-point[data-v-c2d2ef56] {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 3.5rem;
}
.addmore-point a[data-v-c2d2ef56] {
    background: #F0F9FF;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2563EB;
    display: inline-block;
    padding: .5rem 1.3rem;
    transition: all linear .2s;
}
.addmore-point a[data-v-c2d2ef56]:hover {
    background: #DBEAFE;
}

/* faq css end */


/* modal css start */
.modal-section .modal-content[data-v-c2d2ef56] {
    background: #F8FAFC;
    border: 1px solid #CBD5E1;
    border-radius: 8px;
    padding-bottom: 1.5rem;
}
.modal-section .modal-content .modal_text h4[data-v-c2d2ef56] {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #0F172A;
    margin-bottom: 1rem;
}
.modal-section .modal-content .modal_text p[data-v-c2d2ef56] {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 150%;
    color: #334155;
    margin-bottom: 1rem;
}
.modal-section .modal-content .modal_text .form-group[data-v-c2d2ef56] {
    position: relative;
}
.modal-section .modal-content .modal_text .form-group label[data-v-c2d2ef56] {
    position: absolute;
    left: 12px;
    top: 3px;
    font-size: 10px;
    color: #334155;
}
.modal-section .modal-content .modal_text .form-control[data-v-c2d2ef56] {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    height: 46px;
    padding-top: 15px;
    color: #334155;
}
.btn[data-v-c2d2ef56]:focus-within,
.form-control[data-v-c2d2ef56]:focus-within {
    box-shadow: none;
}
.modal-bttn[data-v-c2d2ef56] {
    margin-top: 2rem;
    text-align: end;
}
.modal-bttn a[data-v-c2d2ef56]:first-child {
    background: #fff;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding: 10px 40px;
    color: #DC2626;
    border: 1px solid #DC2626;
    transition: all linear .2s;
    margin-right: 6px;
}
.modal-bttn a[data-v-c2d2ef56]:last-child {
    background: #2563EB;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding: 10px 40px;
    color: #FFFFFF;
    border: 1px solid #2563EB;
    transition: all linear .2s;
}
.modal-bttn a[data-v-c2d2ef56]:first-child:hover {
    background-color: #DC2626;
    color: #fff;
}
.modal-bttn a[data-v-c2d2ef56]:last-child:hover {
    background-color: #fff;
    color: #2563EB;
}
.dlt-bttn a[data-v-c2d2ef56]:last-child {
    background-color: #fff;
    color: #2563EB;
}
.dlt-bttn a[data-v-c2d2ef56]:last-child:hover {
    background-color: #2563EB;
    color: #fff;
}
.modal-section .modal-content .modal_text_2 h6[data-v-c2d2ef56] {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #2563EB;
    margin-bottom: 10px;
}
.modal-section .modal-content .modal_text_2 .form-group img.pls[data-v-c2d2ef56] {
    position: absolute;
    right: 13px;
    top: 14px;
    cursor: pointer;
}
.modal-section .modal-content .modal_text_2 .dropdown-menu .d-flex[data-v-c2d2ef56] {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 10px;
}
.modal-section .modal-content .modal_text_2 .dropdown-menu[data-v-c2d2ef56] {
    min-width: 17rem !important;
    padding-left: 15px;
    padding-right: 15px;
}
.modal-section .modal-content .modal_text_2 .dropdown-menu .d-flex[data-v-c2d2ef56]:hover{
    background: #F0F9FF;
}
.modal-section .modal-content .modal_text_2 .d-flex span[data-v-c2d2ef56] {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px; 
    color: #1D4ED8;
    margin-left: 10px;
}

/* modal css end */