/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
[data-v-c2d2ef56]:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
*[data-v-c2d2ef56],[data-v-c2d2ef56]::after,[data-v-c2d2ef56]::before {
  box-sizing: border-box;
}
html[data-v-c2d2ef56] {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article[data-v-c2d2ef56],
aside[data-v-c2d2ef56],
figcaption[data-v-c2d2ef56],
figure[data-v-c2d2ef56],
footer[data-v-c2d2ef56],
header[data-v-c2d2ef56],
hgroup[data-v-c2d2ef56],
main[data-v-c2d2ef56],
nav[data-v-c2d2ef56],
section[data-v-c2d2ef56] {
  display: block;
}
body[data-v-c2d2ef56] {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"][data-v-c2d2ef56]:focus {
  outline: 0 !important;
}
hr[data-v-c2d2ef56] {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1[data-v-c2d2ef56],
h2[data-v-c2d2ef56],
h3[data-v-c2d2ef56],
h4[data-v-c2d2ef56],
h5[data-v-c2d2ef56],
h6[data-v-c2d2ef56] {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p[data-v-c2d2ef56] {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title][data-v-c2d2ef56],
abbr[title][data-v-c2d2ef56] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address[data-v-c2d2ef56] {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl[data-v-c2d2ef56],
ol[data-v-c2d2ef56],
ul[data-v-c2d2ef56] {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol[data-v-c2d2ef56],
ol ul[data-v-c2d2ef56],
ul ol[data-v-c2d2ef56],
ul ul[data-v-c2d2ef56] {
  margin-bottom: 0;
}
dt[data-v-c2d2ef56] {
  font-weight: 700;
}
dd[data-v-c2d2ef56] {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote[data-v-c2d2ef56] {
  margin: 0 0 1rem;
}
b[data-v-c2d2ef56],
strong[data-v-c2d2ef56] {
  font-weight: bolder;
}
small[data-v-c2d2ef56] {
  font-size: 80%;
}
sub[data-v-c2d2ef56],
sup[data-v-c2d2ef56] {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub[data-v-c2d2ef56] {
  bottom: -0.25em;
}
sup[data-v-c2d2ef56] {
  top: -0.5em;
}
a[data-v-c2d2ef56] {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a[data-v-c2d2ef56]:hover {
  color: #0056b3;
  text-decoration: underline;
}
a[data-v-c2d2ef56]:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a[data-v-c2d2ef56]:not([href]):not([tabindex]):focus,
a[data-v-c2d2ef56]:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a[data-v-c2d2ef56]:not([href]):not([tabindex]):focus {
  outline: 0;
}
code[data-v-c2d2ef56],
kbd[data-v-c2d2ef56],
pre[data-v-c2d2ef56],
samp[data-v-c2d2ef56] {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}
pre[data-v-c2d2ef56] {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure[data-v-c2d2ef56] {
  margin: 0 0 1rem;
}
img[data-v-c2d2ef56] {
  vertical-align: middle;
  border-style: none;
}
svg[data-v-c2d2ef56] {
  overflow: hidden;
  vertical-align: middle;
}
table[data-v-c2d2ef56] {
  border-collapse: collapse;
}
caption[data-v-c2d2ef56] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th[data-v-c2d2ef56] {
  text-align: inherit;
}
label[data-v-c2d2ef56] {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button[data-v-c2d2ef56] {
  border-radius: 0;
}
button[data-v-c2d2ef56]:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button[data-v-c2d2ef56],
input[data-v-c2d2ef56],
optgroup[data-v-c2d2ef56],
select[data-v-c2d2ef56],
textarea[data-v-c2d2ef56] {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button[data-v-c2d2ef56],
input[data-v-c2d2ef56] {
  overflow: visible;
}
button[data-v-c2d2ef56],
select[data-v-c2d2ef56] {
  text-transform: none;
}
select[data-v-c2d2ef56] {
  word-wrap: normal;
}
[type="button"][data-v-c2d2ef56],
[type="reset"][data-v-c2d2ef56],
[type="submit"][data-v-c2d2ef56],
button[data-v-c2d2ef56] {
  -webkit-appearance: button;
}
[type="button"][data-v-c2d2ef56]:not(:disabled),
[type="reset"][data-v-c2d2ef56]:not(:disabled),
[type="submit"][data-v-c2d2ef56]:not(:disabled),
button[data-v-c2d2ef56]:not(:disabled) {
  cursor: pointer;
}
[type="button"][data-v-c2d2ef56]::-moz-focus-inner,
[type="reset"][data-v-c2d2ef56]::-moz-focus-inner,
[type="submit"][data-v-c2d2ef56]::-moz-focus-inner,
button[data-v-c2d2ef56]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"][data-v-c2d2ef56],
input[type="radio"][data-v-c2d2ef56] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"][data-v-c2d2ef56],
input[type="datetime-local"][data-v-c2d2ef56],
input[type="month"][data-v-c2d2ef56],
input[type="time"][data-v-c2d2ef56] {
  -webkit-appearance: listbox;
}
textarea[data-v-c2d2ef56] {
  overflow: auto;
  resize: vertical;
}
fieldset[data-v-c2d2ef56] {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend[data-v-c2d2ef56] {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress[data-v-c2d2ef56] {
  vertical-align: baseline;
}
[type="number"][data-v-c2d2ef56]::-webkit-inner-spin-button,
[type="number"][data-v-c2d2ef56]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"][data-v-c2d2ef56] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"][data-v-c2d2ef56]::-webkit-search-decoration {
  -webkit-appearance: none;
}
[data-v-c2d2ef56]::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output[data-v-c2d2ef56] {
  display: inline-block;
}
summary[data-v-c2d2ef56] {
  display: list-item;
  cursor: pointer;
}
template[data-v-c2d2ef56] {
  display: none;
}
[hidden][data-v-c2d2ef56] {
  display: none !important;
}
.h1[data-v-c2d2ef56],
.h2[data-v-c2d2ef56],
.h3[data-v-c2d2ef56],
.h4[data-v-c2d2ef56],
.h5[data-v-c2d2ef56],
.h6[data-v-c2d2ef56],
h1[data-v-c2d2ef56],
h2[data-v-c2d2ef56],
h3[data-v-c2d2ef56],
h4[data-v-c2d2ef56],
h5[data-v-c2d2ef56],
h6[data-v-c2d2ef56] {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h1[data-v-c2d2ef56],
h1[data-v-c2d2ef56] {
  font-size: 2.5rem;
}
.h2[data-v-c2d2ef56],
h2[data-v-c2d2ef56] {
  font-size: 2rem;
}
.h3[data-v-c2d2ef56],
h3[data-v-c2d2ef56] {
  font-size: 1.75rem;
}
.h4[data-v-c2d2ef56],
h4[data-v-c2d2ef56] {
  font-size: 1.5rem;
}
.h5[data-v-c2d2ef56],
h5[data-v-c2d2ef56] {
  font-size: 1.25rem;
}
.h6[data-v-c2d2ef56],
h6[data-v-c2d2ef56] {
  font-size: 1rem;
}
.lead[data-v-c2d2ef56] {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1[data-v-c2d2ef56] {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2[data-v-c2d2ef56] {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3[data-v-c2d2ef56] {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4[data-v-c2d2ef56] {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr[data-v-c2d2ef56] {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small[data-v-c2d2ef56],
small[data-v-c2d2ef56] {
  font-size: 80%;
  font-weight: 400;
}
.mark[data-v-c2d2ef56],
mark[data-v-c2d2ef56] {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled[data-v-c2d2ef56] {
  padding-left: 0;
  list-style: none;
}
.list-inline[data-v-c2d2ef56] {
  padding-left: 0;
  list-style: none;
}
.list-inline-item[data-v-c2d2ef56] {
  display: inline-block;
}
.list-inline-item[data-v-c2d2ef56]:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism[data-v-c2d2ef56] {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote[data-v-c2d2ef56] {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer[data-v-c2d2ef56] {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer[data-v-c2d2ef56]::before {
  content: "\2014\00A0";
}
.img-fluid[data-v-c2d2ef56] {
  max-width: 100%;
  height: auto;
}
.img-thumbnail[data-v-c2d2ef56] {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure[data-v-c2d2ef56] {
  display: inline-block;
}
.figure-img[data-v-c2d2ef56] {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption[data-v-c2d2ef56] {
  font-size: 90%;
  color: #6c757d;
}
code[data-v-c2d2ef56] {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code[data-v-c2d2ef56] {
  color: inherit;
}
kbd[data-v-c2d2ef56] {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd[data-v-c2d2ef56] {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre[data-v-c2d2ef56] {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code[data-v-c2d2ef56] {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable[data-v-c2d2ef56] {
  max-height: 340px;
  overflow-y: scroll;
}
.container[data-v-c2d2ef56] {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
.container[data-v-c2d2ef56] {
    max-width: 540px;
}
}
@media (min-width: 768px) {
.container[data-v-c2d2ef56] {
    max-width: 720px;
}
}
@media (min-width: 992px) {
.container[data-v-c2d2ef56] {
    max-width: 960px;
}
}
@media (min-width: 1200px) {
.container[data-v-c2d2ef56] {
    max-width: 1140px;
}
}
.container-fluid[data-v-c2d2ef56] {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row[data-v-c2d2ef56] {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters[data-v-c2d2ef56] {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col[data-v-c2d2ef56],
.no-gutters > [class*="col-"][data-v-c2d2ef56] {
  padding-right: 0;
  padding-left: 0;
}
.col[data-v-c2d2ef56],
.col-1[data-v-c2d2ef56],
.col-10[data-v-c2d2ef56],
.col-11[data-v-c2d2ef56],
.col-12[data-v-c2d2ef56],
.col-2[data-v-c2d2ef56],
.col-3[data-v-c2d2ef56],
.col-4[data-v-c2d2ef56],
.col-5[data-v-c2d2ef56],
.col-6[data-v-c2d2ef56],
.col-7[data-v-c2d2ef56],
.col-8[data-v-c2d2ef56],
.col-9[data-v-c2d2ef56],
.col-auto[data-v-c2d2ef56],
.col-lg[data-v-c2d2ef56],
.col-lg-1[data-v-c2d2ef56],
.col-lg-10[data-v-c2d2ef56],
.col-lg-11[data-v-c2d2ef56],
.col-lg-12[data-v-c2d2ef56],
.col-lg-2[data-v-c2d2ef56],
.col-lg-3[data-v-c2d2ef56],
.col-lg-4[data-v-c2d2ef56],
.col-lg-5[data-v-c2d2ef56],
.col-lg-6[data-v-c2d2ef56],
.col-lg-7[data-v-c2d2ef56],
.col-lg-8[data-v-c2d2ef56],
.col-lg-9[data-v-c2d2ef56],
.col-lg-auto[data-v-c2d2ef56],
.col-md[data-v-c2d2ef56],
.col-md-1[data-v-c2d2ef56],
.col-md-10[data-v-c2d2ef56],
.col-md-11[data-v-c2d2ef56],
.col-md-12[data-v-c2d2ef56],
.col-md-2[data-v-c2d2ef56],
.col-md-3[data-v-c2d2ef56],
.col-md-4[data-v-c2d2ef56],
.col-md-5[data-v-c2d2ef56],
.col-md-6[data-v-c2d2ef56],
.col-md-7[data-v-c2d2ef56],
.col-md-8[data-v-c2d2ef56],
.col-md-9[data-v-c2d2ef56],
.col-md-auto[data-v-c2d2ef56],
.col-sm[data-v-c2d2ef56],
.col-sm-1[data-v-c2d2ef56],
.col-sm-10[data-v-c2d2ef56],
.col-sm-11[data-v-c2d2ef56],
.col-sm-12[data-v-c2d2ef56],
.col-sm-2[data-v-c2d2ef56],
.col-sm-3[data-v-c2d2ef56],
.col-sm-4[data-v-c2d2ef56],
.col-sm-5[data-v-c2d2ef56],
.col-sm-6[data-v-c2d2ef56],
.col-sm-7[data-v-c2d2ef56],
.col-sm-8[data-v-c2d2ef56],
.col-sm-9[data-v-c2d2ef56],
.col-sm-auto[data-v-c2d2ef56],
.col-xl[data-v-c2d2ef56],
.col-xl-1[data-v-c2d2ef56],
.col-xl-10[data-v-c2d2ef56],
.col-xl-11[data-v-c2d2ef56],
.col-xl-12[data-v-c2d2ef56],
.col-xl-2[data-v-c2d2ef56],
.col-xl-3[data-v-c2d2ef56],
.col-xl-4[data-v-c2d2ef56],
.col-xl-5[data-v-c2d2ef56],
.col-xl-6[data-v-c2d2ef56],
.col-xl-7[data-v-c2d2ef56],
.col-xl-8[data-v-c2d2ef56],
.col-xl-9[data-v-c2d2ef56],
.col-xl-auto[data-v-c2d2ef56] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col[data-v-c2d2ef56] {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto[data-v-c2d2ef56] {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1[data-v-c2d2ef56] {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2[data-v-c2d2ef56] {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3[data-v-c2d2ef56] {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4[data-v-c2d2ef56] {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5[data-v-c2d2ef56] {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6[data-v-c2d2ef56] {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7[data-v-c2d2ef56] {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8[data-v-c2d2ef56] {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9[data-v-c2d2ef56] {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10[data-v-c2d2ef56] {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11[data-v-c2d2ef56] {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12[data-v-c2d2ef56] {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first[data-v-c2d2ef56] {
  order: -1;
}
.order-last[data-v-c2d2ef56] {
  order: 13;
}
.order-0[data-v-c2d2ef56] {
  order: 0;
}
.order-1[data-v-c2d2ef56] {
  order: 1;
}
.order-2[data-v-c2d2ef56] {
  order: 2;
}
.order-3[data-v-c2d2ef56] {
  order: 3;
}
.order-4[data-v-c2d2ef56] {
  order: 4;
}
.order-5[data-v-c2d2ef56] {
  order: 5;
}
.order-6[data-v-c2d2ef56] {
  order: 6;
}
.order-7[data-v-c2d2ef56] {
  order: 7;
}
.order-8[data-v-c2d2ef56] {
  order: 8;
}
.order-9[data-v-c2d2ef56] {
  order: 9;
}
.order-10[data-v-c2d2ef56] {
  order: 10;
}
.order-11[data-v-c2d2ef56] {
  order: 11;
}
.order-12[data-v-c2d2ef56] {
  order: 12;
}
.offset-1[data-v-c2d2ef56] {
  margin-left: 8.333333%;
}
.offset-2[data-v-c2d2ef56] {
  margin-left: 16.666667%;
}
.offset-3[data-v-c2d2ef56] {
  margin-left: 25%;
}
.offset-4[data-v-c2d2ef56] {
  margin-left: 33.333333%;
}
.offset-5[data-v-c2d2ef56] {
  margin-left: 41.666667%;
}
.offset-6[data-v-c2d2ef56] {
  margin-left: 50%;
}
.offset-7[data-v-c2d2ef56] {
  margin-left: 58.333333%;
}
.offset-8[data-v-c2d2ef56] {
  margin-left: 66.666667%;
}
.offset-9[data-v-c2d2ef56] {
  margin-left: 75%;
}
.offset-10[data-v-c2d2ef56] {
  margin-left: 83.333333%;
}
.offset-11[data-v-c2d2ef56] {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
.col-sm[data-v-c2d2ef56] {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.col-sm-auto[data-v-c2d2ef56] {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}
.col-sm-1[data-v-c2d2ef56] {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.col-sm-2[data-v-c2d2ef56] {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.col-sm-3[data-v-c2d2ef56] {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-sm-4[data-v-c2d2ef56] {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.col-sm-5[data-v-c2d2ef56] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
.col-sm-6[data-v-c2d2ef56] {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-sm-7[data-v-c2d2ef56] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-sm-8[data-v-c2d2ef56] {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.col-sm-9[data-v-c2d2ef56] {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-sm-10[data-v-c2d2ef56] {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
.col-sm-11[data-v-c2d2ef56] {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}
.col-sm-12[data-v-c2d2ef56] {
    flex: 0 0 100%;
    max-width: 100%;
}
.order-sm-first[data-v-c2d2ef56] {
    order: -1;
}
.order-sm-last[data-v-c2d2ef56] {
    order: 13;
}
.order-sm-0[data-v-c2d2ef56] {
    order: 0;
}
.order-sm-1[data-v-c2d2ef56] {
    order: 1;
}
.order-sm-2[data-v-c2d2ef56] {
    order: 2;
}
.order-sm-3[data-v-c2d2ef56] {
    order: 3;
}
.order-sm-4[data-v-c2d2ef56] {
    order: 4;
}
.order-sm-5[data-v-c2d2ef56] {
    order: 5;
}
.order-sm-6[data-v-c2d2ef56] {
    order: 6;
}
.order-sm-7[data-v-c2d2ef56] {
    order: 7;
}
.order-sm-8[data-v-c2d2ef56] {
    order: 8;
}
.order-sm-9[data-v-c2d2ef56] {
    order: 9;
}
.order-sm-10[data-v-c2d2ef56] {
    order: 10;
}
.order-sm-11[data-v-c2d2ef56] {
    order: 11;
}
.order-sm-12[data-v-c2d2ef56] {
    order: 12;
}
.offset-sm-0[data-v-c2d2ef56] {
    margin-left: 0;
}
.offset-sm-1[data-v-c2d2ef56] {
    margin-left: 8.333333%;
}
.offset-sm-2[data-v-c2d2ef56] {
    margin-left: 16.666667%;
}
.offset-sm-3[data-v-c2d2ef56] {
    margin-left: 25%;
}
.offset-sm-4[data-v-c2d2ef56] {
    margin-left: 33.333333%;
}
.offset-sm-5[data-v-c2d2ef56] {
    margin-left: 41.666667%;
}
.offset-sm-6[data-v-c2d2ef56] {
    margin-left: 50%;
}
.offset-sm-7[data-v-c2d2ef56] {
    margin-left: 58.333333%;
}
.offset-sm-8[data-v-c2d2ef56] {
    margin-left: 66.666667%;
}
.offset-sm-9[data-v-c2d2ef56] {
    margin-left: 75%;
}
.offset-sm-10[data-v-c2d2ef56] {
    margin-left: 83.333333%;
}
.offset-sm-11[data-v-c2d2ef56] {
    margin-left: 91.666667%;
}
}
@media (min-width: 768px) {
.col-md[data-v-c2d2ef56] {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.col-md-auto[data-v-c2d2ef56] {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}
.col-md-1[data-v-c2d2ef56] {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.col-md-2[data-v-c2d2ef56] {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.col-md-3[data-v-c2d2ef56] {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-md-4[data-v-c2d2ef56] {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.col-md-5[data-v-c2d2ef56] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
.col-md-6[data-v-c2d2ef56] {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-md-7[data-v-c2d2ef56] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-md-8[data-v-c2d2ef56] {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.col-md-9[data-v-c2d2ef56] {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-md-10[data-v-c2d2ef56] {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
.col-md-11[data-v-c2d2ef56] {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}
.col-md-12[data-v-c2d2ef56] {
    flex: 0 0 100%;
    max-width: 100%;
}
.order-md-first[data-v-c2d2ef56] {
    order: -1;
}
.order-md-last[data-v-c2d2ef56] {
    order: 13;
}
.order-md-0[data-v-c2d2ef56] {
    order: 0;
}
.order-md-1[data-v-c2d2ef56] {
    order: 1;
}
.order-md-2[data-v-c2d2ef56] {
    order: 2;
}
.order-md-3[data-v-c2d2ef56] {
    order: 3;
}
.order-md-4[data-v-c2d2ef56] {
    order: 4;
}
.order-md-5[data-v-c2d2ef56] {
    order: 5;
}
.order-md-6[data-v-c2d2ef56] {
    order: 6;
}
.order-md-7[data-v-c2d2ef56] {
    order: 7;
}
.order-md-8[data-v-c2d2ef56] {
    order: 8;
}
.order-md-9[data-v-c2d2ef56] {
    order: 9;
}
.order-md-10[data-v-c2d2ef56] {
    order: 10;
}
.order-md-11[data-v-c2d2ef56] {
    order: 11;
}
.order-md-12[data-v-c2d2ef56] {
    order: 12;
}
.offset-md-0[data-v-c2d2ef56] {
    margin-left: 0;
}
.offset-md-1[data-v-c2d2ef56] {
    margin-left: 8.333333%;
}
.offset-md-2[data-v-c2d2ef56] {
    margin-left: 16.666667%;
}
.offset-md-3[data-v-c2d2ef56] {
    margin-left: 25%;
}
.offset-md-4[data-v-c2d2ef56] {
    margin-left: 33.333333%;
}
.offset-md-5[data-v-c2d2ef56] {
    margin-left: 41.666667%;
}
.offset-md-6[data-v-c2d2ef56] {
    margin-left: 50%;
}
.offset-md-7[data-v-c2d2ef56] {
    margin-left: 58.333333%;
}
.offset-md-8[data-v-c2d2ef56] {
    margin-left: 66.666667%;
}
.offset-md-9[data-v-c2d2ef56] {
    margin-left: 75%;
}
.offset-md-10[data-v-c2d2ef56] {
    margin-left: 83.333333%;
}
.offset-md-11[data-v-c2d2ef56] {
    margin-left: 91.666667%;
}
}
@media (min-width: 992px) {
.col-lg[data-v-c2d2ef56] {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.col-lg-auto[data-v-c2d2ef56] {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}
.col-lg-1[data-v-c2d2ef56] {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.col-lg-2[data-v-c2d2ef56] {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.col-lg-3[data-v-c2d2ef56] {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-lg-4[data-v-c2d2ef56] {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.col-lg-5[data-v-c2d2ef56] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
.col-lg-6[data-v-c2d2ef56] {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-lg-7[data-v-c2d2ef56] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-lg-8[data-v-c2d2ef56] {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.col-lg-9[data-v-c2d2ef56] {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-lg-10[data-v-c2d2ef56] {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
.col-lg-11[data-v-c2d2ef56] {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}
.col-lg-12[data-v-c2d2ef56] {
    flex: 0 0 100%;
    max-width: 100%;
}
.order-lg-first[data-v-c2d2ef56] {
    order: -1;
}
.order-lg-last[data-v-c2d2ef56] {
    order: 13;
}
.order-lg-0[data-v-c2d2ef56] {
    order: 0;
}
.order-lg-1[data-v-c2d2ef56] {
    order: 1;
}
.order-lg-2[data-v-c2d2ef56] {
    order: 2;
}
.order-lg-3[data-v-c2d2ef56] {
    order: 3;
}
.order-lg-4[data-v-c2d2ef56] {
    order: 4;
}
.order-lg-5[data-v-c2d2ef56] {
    order: 5;
}
.order-lg-6[data-v-c2d2ef56] {
    order: 6;
}
.order-lg-7[data-v-c2d2ef56] {
    order: 7;
}
.order-lg-8[data-v-c2d2ef56] {
    order: 8;
}
.order-lg-9[data-v-c2d2ef56] {
    order: 9;
}
.order-lg-10[data-v-c2d2ef56] {
    order: 10;
}
.order-lg-11[data-v-c2d2ef56] {
    order: 11;
}
.order-lg-12[data-v-c2d2ef56] {
    order: 12;
}
.offset-lg-0[data-v-c2d2ef56] {
    margin-left: 0;
}
.offset-lg-1[data-v-c2d2ef56] {
    margin-left: 8.333333%;
}
.offset-lg-2[data-v-c2d2ef56] {
    margin-left: 16.666667%;
}
.offset-lg-3[data-v-c2d2ef56] {
    margin-left: 25%;
}
.offset-lg-4[data-v-c2d2ef56] {
    margin-left: 33.333333%;
}
.offset-lg-5[data-v-c2d2ef56] {
    margin-left: 41.666667%;
}
.offset-lg-6[data-v-c2d2ef56] {
    margin-left: 50%;
}
.offset-lg-7[data-v-c2d2ef56] {
    margin-left: 58.333333%;
}
.offset-lg-8[data-v-c2d2ef56] {
    margin-left: 66.666667%;
}
.offset-lg-9[data-v-c2d2ef56] {
    margin-left: 75%;
}
.offset-lg-10[data-v-c2d2ef56] {
    margin-left: 83.333333%;
}
.offset-lg-11[data-v-c2d2ef56] {
    margin-left: 91.666667%;
}
}
@media (min-width: 1200px) {
.col-xl[data-v-c2d2ef56] {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.col-xl-auto[data-v-c2d2ef56] {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}
.col-xl-1[data-v-c2d2ef56] {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.col-xl-2[data-v-c2d2ef56] {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.col-xl-3[data-v-c2d2ef56] {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-xl-4[data-v-c2d2ef56] {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.col-xl-5[data-v-c2d2ef56] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
.col-xl-6[data-v-c2d2ef56] {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-xl-7[data-v-c2d2ef56] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-xl-8[data-v-c2d2ef56] {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.col-xl-9[data-v-c2d2ef56] {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-xl-10[data-v-c2d2ef56] {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
.col-xl-11[data-v-c2d2ef56] {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}
.col-xl-12[data-v-c2d2ef56] {
    flex: 0 0 100%;
    max-width: 100%;
}
.order-xl-first[data-v-c2d2ef56] {
    order: -1;
}
.order-xl-last[data-v-c2d2ef56] {
    order: 13;
}
.order-xl-0[data-v-c2d2ef56] {
    order: 0;
}
.order-xl-1[data-v-c2d2ef56] {
    order: 1;
}
.order-xl-2[data-v-c2d2ef56] {
    order: 2;
}
.order-xl-3[data-v-c2d2ef56] {
    order: 3;
}
.order-xl-4[data-v-c2d2ef56] {
    order: 4;
}
.order-xl-5[data-v-c2d2ef56] {
    order: 5;
}
.order-xl-6[data-v-c2d2ef56] {
    order: 6;
}
.order-xl-7[data-v-c2d2ef56] {
    order: 7;
}
.order-xl-8[data-v-c2d2ef56] {
    order: 8;
}
.order-xl-9[data-v-c2d2ef56] {
    order: 9;
}
.order-xl-10[data-v-c2d2ef56] {
    order: 10;
}
.order-xl-11[data-v-c2d2ef56] {
    order: 11;
}
.order-xl-12[data-v-c2d2ef56] {
    order: 12;
}
.offset-xl-0[data-v-c2d2ef56] {
    margin-left: 0;
}
.offset-xl-1[data-v-c2d2ef56] {
    margin-left: 8.333333%;
}
.offset-xl-2[data-v-c2d2ef56] {
    margin-left: 16.666667%;
}
.offset-xl-3[data-v-c2d2ef56] {
    margin-left: 25%;
}
.offset-xl-4[data-v-c2d2ef56] {
    margin-left: 33.333333%;
}
.offset-xl-5[data-v-c2d2ef56] {
    margin-left: 41.666667%;
}
.offset-xl-6[data-v-c2d2ef56] {
    margin-left: 50%;
}
.offset-xl-7[data-v-c2d2ef56] {
    margin-left: 58.333333%;
}
.offset-xl-8[data-v-c2d2ef56] {
    margin-left: 66.666667%;
}
.offset-xl-9[data-v-c2d2ef56] {
    margin-left: 75%;
}
.offset-xl-10[data-v-c2d2ef56] {
    margin-left: 83.333333%;
}
.offset-xl-11[data-v-c2d2ef56] {
    margin-left: 91.666667%;
}
}
.table[data-v-c2d2ef56] {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table td[data-v-c2d2ef56],
.table th[data-v-c2d2ef56] {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th[data-v-c2d2ef56] {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody[data-v-c2d2ef56] {
  border-top: 2px solid #dee2e6;
}
.table-sm td[data-v-c2d2ef56],
.table-sm th[data-v-c2d2ef56] {
  padding: 0.3rem;
}
.table-bordered[data-v-c2d2ef56] {
  border: 1px solid #dee2e6;
}
.table-bordered td[data-v-c2d2ef56],
.table-bordered th[data-v-c2d2ef56] {
  border: 1px solid #dee2e6;
}
.table-bordered thead td[data-v-c2d2ef56],
.table-bordered thead th[data-v-c2d2ef56] {
  border-bottom-width: 2px;
}
.table-borderless tbody + tbody[data-v-c2d2ef56],
.table-borderless td[data-v-c2d2ef56],
.table-borderless th[data-v-c2d2ef56],
.table-borderless thead th[data-v-c2d2ef56] {
  border: 0;
}
.table-striped tbody tr[data-v-c2d2ef56]:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr[data-v-c2d2ef56]:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary[data-v-c2d2ef56],
.table-primary > td[data-v-c2d2ef56],
.table-primary > th[data-v-c2d2ef56] {
  background-color: #b8daff;
}
.table-primary tbody + tbody[data-v-c2d2ef56],
.table-primary td[data-v-c2d2ef56],
.table-primary th[data-v-c2d2ef56],
.table-primary thead th[data-v-c2d2ef56] {
  border-color: #7abaff;
}
.table-hover .table-primary[data-v-c2d2ef56]:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td[data-v-c2d2ef56],
.table-hover .table-primary:hover > th[data-v-c2d2ef56] {
  background-color: #9fcdff;
}
.table-secondary[data-v-c2d2ef56],
.table-secondary > td[data-v-c2d2ef56],
.table-secondary > th[data-v-c2d2ef56] {
  background-color: #d6d8db;
}
.table-secondary tbody + tbody[data-v-c2d2ef56],
.table-secondary td[data-v-c2d2ef56],
.table-secondary th[data-v-c2d2ef56],
.table-secondary thead th[data-v-c2d2ef56] {
  border-color: #b3b7bb;
}
.table-hover .table-secondary[data-v-c2d2ef56]:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td[data-v-c2d2ef56],
.table-hover .table-secondary:hover > th[data-v-c2d2ef56] {
  background-color: #c8cbcf;
}
.table-success[data-v-c2d2ef56],
.table-success > td[data-v-c2d2ef56],
.table-success > th[data-v-c2d2ef56] {
  background-color: #c3e6cb;
}
.table-success tbody + tbody[data-v-c2d2ef56],
.table-success td[data-v-c2d2ef56],
.table-success th[data-v-c2d2ef56],
.table-success thead th[data-v-c2d2ef56] {
  border-color: #8fd19e;
}
.table-hover .table-success[data-v-c2d2ef56]:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td[data-v-c2d2ef56],
.table-hover .table-success:hover > th[data-v-c2d2ef56] {
  background-color: #b1dfbb;
}
.table-info[data-v-c2d2ef56],
.table-info > td[data-v-c2d2ef56],
.table-info > th[data-v-c2d2ef56] {
  background-color: #bee5eb;
}
.table-info tbody + tbody[data-v-c2d2ef56],
.table-info td[data-v-c2d2ef56],
.table-info th[data-v-c2d2ef56],
.table-info thead th[data-v-c2d2ef56] {
  border-color: #86cfda;
}
.table-hover .table-info[data-v-c2d2ef56]:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td[data-v-c2d2ef56],
.table-hover .table-info:hover > th[data-v-c2d2ef56] {
  background-color: #abdde5;
}
.table-warning[data-v-c2d2ef56],
.table-warning > td[data-v-c2d2ef56],
.table-warning > th[data-v-c2d2ef56] {
  background-color: #ffeeba;
}
.table-warning tbody + tbody[data-v-c2d2ef56],
.table-warning td[data-v-c2d2ef56],
.table-warning th[data-v-c2d2ef56],
.table-warning thead th[data-v-c2d2ef56] {
  border-color: #ffdf7e;
}
.table-hover .table-warning[data-v-c2d2ef56]:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td[data-v-c2d2ef56],
.table-hover .table-warning:hover > th[data-v-c2d2ef56] {
  background-color: #ffe8a1;
}
.table-danger[data-v-c2d2ef56],
.table-danger > td[data-v-c2d2ef56],
.table-danger > th[data-v-c2d2ef56] {
  background-color: #f5c6cb;
}
.table-danger tbody + tbody[data-v-c2d2ef56],
.table-danger td[data-v-c2d2ef56],
.table-danger th[data-v-c2d2ef56],
.table-danger thead th[data-v-c2d2ef56] {
  border-color: #ed969e;
}
.table-hover .table-danger[data-v-c2d2ef56]:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td[data-v-c2d2ef56],
.table-hover .table-danger:hover > th[data-v-c2d2ef56] {
  background-color: #f1b0b7;
}
.table-light[data-v-c2d2ef56],
.table-light > td[data-v-c2d2ef56],
.table-light > th[data-v-c2d2ef56] {
  background-color: #fdfdfe;
}
.table-light tbody + tbody[data-v-c2d2ef56],
.table-light td[data-v-c2d2ef56],
.table-light th[data-v-c2d2ef56],
.table-light thead th[data-v-c2d2ef56] {
  border-color: #fbfcfc;
}
.table-hover .table-light[data-v-c2d2ef56]:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td[data-v-c2d2ef56],
.table-hover .table-light:hover > th[data-v-c2d2ef56] {
  background-color: #ececf6;
}
.table-dark[data-v-c2d2ef56],
.table-dark > td[data-v-c2d2ef56],
.table-dark > th[data-v-c2d2ef56] {
  background-color: #c6c8ca;
}
.table-dark tbody + tbody[data-v-c2d2ef56],
.table-dark td[data-v-c2d2ef56],
.table-dark th[data-v-c2d2ef56],
.table-dark thead th[data-v-c2d2ef56] {
  border-color: #95999c;
}
.table-hover .table-dark[data-v-c2d2ef56]:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td[data-v-c2d2ef56],
.table-hover .table-dark:hover > th[data-v-c2d2ef56] {
  background-color: #b9bbbe;
}
.table-active[data-v-c2d2ef56],
.table-active > td[data-v-c2d2ef56],
.table-active > th[data-v-c2d2ef56] {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active[data-v-c2d2ef56]:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td[data-v-c2d2ef56],
.table-hover .table-active:hover > th[data-v-c2d2ef56] {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th[data-v-c2d2ef56] {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th[data-v-c2d2ef56] {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark[data-v-c2d2ef56] {
  color: #fff;
  background-color: #343a40;
}
.table-dark td[data-v-c2d2ef56],
.table-dark th[data-v-c2d2ef56],
.table-dark thead th[data-v-c2d2ef56] {
  border-color: #454d55;
}
.table-dark.table-bordered[data-v-c2d2ef56] {
  border: 0;
}
.table-dark.table-striped tbody tr[data-v-c2d2ef56]:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
.table-responsive-sm[data-v-c2d2ef56] {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-responsive-sm > .table-bordered[data-v-c2d2ef56] {
    border: 0;
}
}
@media (max-width: 767.98px) {
.table-responsive-md[data-v-c2d2ef56] {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-responsive-md > .table-bordered[data-v-c2d2ef56] {
    border: 0;
}
}
@media (max-width: 991.98px) {
.table-responsive-lg[data-v-c2d2ef56] {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-responsive-lg > .table-bordered[data-v-c2d2ef56] {
    border: 0;
}
}
@media (max-width: 1199.98px) {
.table-responsive-xl[data-v-c2d2ef56] {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-responsive-xl > .table-bordered[data-v-c2d2ef56] {
    border: 0;
}
}
.table-responsive[data-v-c2d2ef56] {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered[data-v-c2d2ef56] {
  border: 0;
}
.form-control[data-v-c2d2ef56] {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.form-control[data-v-c2d2ef56] {
    transition: none;
}
}
.form-control[data-v-c2d2ef56]::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control[data-v-c2d2ef56]:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control[data-v-c2d2ef56]::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control[data-v-c2d2ef56]:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control[data-v-c2d2ef56]::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control[data-v-c2d2ef56]:disabled,
.form-control[readonly][data-v-c2d2ef56] {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control[data-v-c2d2ef56]:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file[data-v-c2d2ef56],
.form-control-range[data-v-c2d2ef56] {
  display: block;
  width: 100%;
}
.col-form-label[data-v-c2d2ef56] {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg[data-v-c2d2ef56] {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm[data-v-c2d2ef56] {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext[data-v-c2d2ef56] {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg[data-v-c2d2ef56],
.form-control-plaintext.form-control-sm[data-v-c2d2ef56] {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm[data-v-c2d2ef56] {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg[data-v-c2d2ef56] {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[multiple][data-v-c2d2ef56],
select.form-control[size][data-v-c2d2ef56] {
  height: auto;
}
textarea.form-control[data-v-c2d2ef56] {
  height: auto;
}
.form-group[data-v-c2d2ef56] {
  margin-bottom: 1rem;
}
.form-text[data-v-c2d2ef56] {
  display: block;
  margin-top: 0.25rem;
}
.form-row[data-v-c2d2ef56] {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col[data-v-c2d2ef56],
.form-row > [class*="col-"][data-v-c2d2ef56] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check[data-v-c2d2ef56] {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input[data-v-c2d2ef56] {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label[data-v-c2d2ef56] {
  color: #6c757d;
}
.form-check-label[data-v-c2d2ef56] {
  margin-bottom: 0;
}
.form-check-inline[data-v-c2d2ef56] {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input[data-v-c2d2ef56] {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback[data-v-c2d2ef56] {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip[data-v-c2d2ef56] {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-control.is-valid[data-v-c2d2ef56],
.was-validated .form-control[data-v-c2d2ef56]:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid[data-v-c2d2ef56]:focus,
.was-validated .form-control[data-v-c2d2ef56]:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.form-control.is-valid ~ .valid-feedback[data-v-c2d2ef56],
.form-control.is-valid ~ .valid-tooltip[data-v-c2d2ef56],
.was-validated .form-control:valid ~ .valid-feedback[data-v-c2d2ef56],
.was-validated .form-control:valid ~ .valid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.was-validated textarea.form-control[data-v-c2d2ef56]:valid,
textarea.form-control.is-valid[data-v-c2d2ef56] {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.custom-select.is-valid[data-v-c2d2ef56],
.was-validated .custom-select[data-v-c2d2ef56]:valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.custom-select.is-valid[data-v-c2d2ef56]:focus,
.was-validated .custom-select[data-v-c2d2ef56]:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-select.is-valid ~ .valid-feedback[data-v-c2d2ef56],
.custom-select.is-valid ~ .valid-tooltip[data-v-c2d2ef56],
.was-validated .custom-select:valid ~ .valid-feedback[data-v-c2d2ef56],
.was-validated .custom-select:valid ~ .valid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.form-control-file.is-valid ~ .valid-feedback[data-v-c2d2ef56],
.form-control-file.is-valid ~ .valid-tooltip[data-v-c2d2ef56],
.was-validated .form-control-file:valid ~ .valid-feedback[data-v-c2d2ef56],
.was-validated .form-control-file:valid ~ .valid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.form-check-input.is-valid ~ .form-check-label[data-v-c2d2ef56],
.was-validated .form-check-input:valid ~ .form-check-label[data-v-c2d2ef56] {
  color: #28a745;
}
.form-check-input.is-valid ~ .valid-feedback[data-v-c2d2ef56],
.form-check-input.is-valid ~ .valid-tooltip[data-v-c2d2ef56],
.was-validated .form-check-input:valid ~ .valid-feedback[data-v-c2d2ef56],
.was-validated .form-check-input:valid ~ .valid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label[data-v-c2d2ef56],
.was-validated .custom-control-input:valid ~ .custom-control-label[data-v-c2d2ef56] {
  color: #28a745;
}
.custom-control-input.is-valid ~ .custom-control-label[data-v-c2d2ef56]::before,
.was-validated .custom-control-input:valid ~ .custom-control-label[data-v-c2d2ef56]::before {
  border-color: #28a745;
}
.custom-control-input.is-valid ~ .valid-feedback[data-v-c2d2ef56],
.custom-control-input.is-valid ~ .valid-tooltip[data-v-c2d2ef56],
.was-validated .custom-control-input:valid ~ .valid-feedback[data-v-c2d2ef56],
.was-validated .custom-control-input:valid ~ .valid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.custom-control-input.is-valid:checked ~ .custom-control-label[data-v-c2d2ef56]::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label[data-v-c2d2ef56]::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.custom-control-input.is-valid:focus ~ .custom-control-label[data-v-c2d2ef56]::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label[data-v-c2d2ef56]::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label[data-v-c2d2ef56]::before,
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label[data-v-c2d2ef56]::before {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .custom-file-label[data-v-c2d2ef56],
.was-validated .custom-file-input:valid ~ .custom-file-label[data-v-c2d2ef56] {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .valid-feedback[data-v-c2d2ef56],
.custom-file-input.is-valid ~ .valid-tooltip[data-v-c2d2ef56],
.was-validated .custom-file-input:valid ~ .valid-feedback[data-v-c2d2ef56],
.was-validated .custom-file-input:valid ~ .valid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.custom-file-input.is-valid:focus ~ .custom-file-label[data-v-c2d2ef56],
.was-validated .custom-file-input:valid:focus ~ .custom-file-label[data-v-c2d2ef56] {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback[data-v-c2d2ef56] {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.invalid-tooltip[data-v-c2d2ef56] {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-control.is-invalid[data-v-c2d2ef56],
.was-validated .form-control[data-v-c2d2ef56]:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid[data-v-c2d2ef56]:focus,
.was-validated .form-control[data-v-c2d2ef56]:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-control.is-invalid ~ .invalid-feedback[data-v-c2d2ef56],
.form-control.is-invalid ~ .invalid-tooltip[data-v-c2d2ef56],
.was-validated .form-control:invalid ~ .invalid-feedback[data-v-c2d2ef56],
.was-validated .form-control:invalid ~ .invalid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.was-validated textarea.form-control[data-v-c2d2ef56]:invalid,
textarea.form-control.is-invalid[data-v-c2d2ef56] {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.custom-select.is-invalid[data-v-c2d2ef56],
.was-validated .custom-select[data-v-c2d2ef56]:invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.custom-select.is-invalid[data-v-c2d2ef56]:focus,
.was-validated .custom-select[data-v-c2d2ef56]:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-select.is-invalid ~ .invalid-feedback[data-v-c2d2ef56],
.custom-select.is-invalid ~ .invalid-tooltip[data-v-c2d2ef56],
.was-validated .custom-select:invalid ~ .invalid-feedback[data-v-c2d2ef56],
.was-validated .custom-select:invalid ~ .invalid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.form-control-file.is-invalid ~ .invalid-feedback[data-v-c2d2ef56],
.form-control-file.is-invalid ~ .invalid-tooltip[data-v-c2d2ef56],
.was-validated .form-control-file:invalid ~ .invalid-feedback[data-v-c2d2ef56],
.was-validated .form-control-file:invalid ~ .invalid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.form-check-input.is-invalid ~ .form-check-label[data-v-c2d2ef56],
.was-validated .form-check-input:invalid ~ .form-check-label[data-v-c2d2ef56] {
  color: #dc3545;
}
.form-check-input.is-invalid ~ .invalid-feedback[data-v-c2d2ef56],
.form-check-input.is-invalid ~ .invalid-tooltip[data-v-c2d2ef56],
.was-validated .form-check-input:invalid ~ .invalid-feedback[data-v-c2d2ef56],
.was-validated .form-check-input:invalid ~ .invalid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label[data-v-c2d2ef56],
.was-validated .custom-control-input:invalid ~ .custom-control-label[data-v-c2d2ef56] {
  color: #dc3545;
}
.custom-control-input.is-invalid ~ .custom-control-label[data-v-c2d2ef56]::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label[data-v-c2d2ef56]::before {
  border-color: #dc3545;
}
.custom-control-input.is-invalid ~ .invalid-feedback[data-v-c2d2ef56],
.custom-control-input.is-invalid ~ .invalid-tooltip[data-v-c2d2ef56],
.was-validated .custom-control-input:invalid ~ .invalid-feedback[data-v-c2d2ef56],
.was-validated .custom-control-input:invalid ~ .invalid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label[data-v-c2d2ef56]::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label[data-v-c2d2ef56]::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label[data-v-c2d2ef56]::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label[data-v-c2d2ef56]::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label[data-v-c2d2ef56]::before,
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label[data-v-c2d2ef56]::before {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .custom-file-label[data-v-c2d2ef56],
.was-validated .custom-file-input:invalid ~ .custom-file-label[data-v-c2d2ef56] {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .invalid-feedback[data-v-c2d2ef56],
.custom-file-input.is-invalid ~ .invalid-tooltip[data-v-c2d2ef56],
.was-validated .custom-file-input:invalid ~ .invalid-feedback[data-v-c2d2ef56],
.was-validated .custom-file-input:invalid ~ .invalid-tooltip[data-v-c2d2ef56] {
  display: block;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label[data-v-c2d2ef56],
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label[data-v-c2d2ef56] {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline[data-v-c2d2ef56] {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check[data-v-c2d2ef56] {
  width: 100%;
}
@media (min-width: 576px) {
.form-inline label[data-v-c2d2ef56] {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}
.form-inline .form-group[data-v-c2d2ef56] {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
}
.form-inline .form-control[data-v-c2d2ef56] {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}
.form-inline .form-control-plaintext[data-v-c2d2ef56] {
    display: inline-block;
}
.form-inline .custom-select[data-v-c2d2ef56],
  .form-inline .input-group[data-v-c2d2ef56] {
    width: auto;
}
.form-inline .form-check[data-v-c2d2ef56] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
}
.form-inline .form-check-input[data-v-c2d2ef56] {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
}
.form-inline .custom-control[data-v-c2d2ef56] {
    align-items: center;
    justify-content: center;
}
.form-inline .custom-control-label[data-v-c2d2ef56] {
    margin-bottom: 0;
}
}
.btn[data-v-c2d2ef56] {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.btn[data-v-c2d2ef56] {
    transition: none;
}
}
.btn[data-v-c2d2ef56]:hover {
  color: #212529;
  text-decoration: none;
}
.btn.focus[data-v-c2d2ef56],
.btn[data-v-c2d2ef56]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled[data-v-c2d2ef56],
.btn[data-v-c2d2ef56]:disabled {
  opacity: 0.65;
}
a.btn.disabled[data-v-c2d2ef56],
fieldset:disabled a.btn[data-v-c2d2ef56] {
  pointer-events: none;
}
.btn-primary[data-v-c2d2ef56] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary.focus[data-v-c2d2ef56],
.btn-primary[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled[data-v-c2d2ef56],
.btn-primary[data-v-c2d2ef56]:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-primary[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-primary[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-secondary[data-v-c2d2ef56] {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary.focus[data-v-c2d2ef56],
.btn-secondary[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled[data-v-c2d2ef56],
.btn-secondary[data-v-c2d2ef56]:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-secondary[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-secondary[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success[data-v-c2d2ef56] {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success.focus[data-v-c2d2ef56],
.btn-success[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled[data-v-c2d2ef56],
.btn-success[data-v-c2d2ef56]:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-success[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-success[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-info[data-v-c2d2ef56] {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info.focus[data-v-c2d2ef56],
.btn-info[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled[data-v-c2d2ef56],
.btn-info[data-v-c2d2ef56]:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-info[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-info[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-warning[data-v-c2d2ef56] {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning[data-v-c2d2ef56]:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning.focus[data-v-c2d2ef56],
.btn-warning[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled[data-v-c2d2ef56],
.btn-warning[data-v-c2d2ef56]:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-warning[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle[data-v-c2d2ef56] {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-warning[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-danger[data-v-c2d2ef56] {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger.focus[data-v-c2d2ef56],
.btn-danger[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled[data-v-c2d2ef56],
.btn-danger[data-v-c2d2ef56]:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-danger[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-danger[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-light[data-v-c2d2ef56] {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light[data-v-c2d2ef56]:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light.focus[data-v-c2d2ef56],
.btn-light[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled[data-v-c2d2ef56],
.btn-light[data-v-c2d2ef56]:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-light[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle[data-v-c2d2ef56] {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-light[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark[data-v-c2d2ef56] {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.focus[data-v-c2d2ef56],
.btn-dark[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled[data-v-c2d2ef56],
.btn-dark[data-v-c2d2ef56]:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-dark[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-dark[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-outline-primary[data-v-c2d2ef56] {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary.focus[data-v-c2d2ef56],
.btn-outline-primary[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled[data-v-c2d2ef56],
.btn-outline-primary[data-v-c2d2ef56]:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-outline-primary[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-outline-primary[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-secondary[data-v-c2d2ef56] {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary.focus[data-v-c2d2ef56],
.btn-outline-secondary[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled[data-v-c2d2ef56],
.btn-outline-secondary[data-v-c2d2ef56]:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-outline-secondary[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-outline-secondary[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success[data-v-c2d2ef56] {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success.focus[data-v-c2d2ef56],
.btn-outline-success[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled[data-v-c2d2ef56],
.btn-outline-success[data-v-c2d2ef56]:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-outline-success[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-outline-success[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info[data-v-c2d2ef56] {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info.focus[data-v-c2d2ef56],
.btn-outline-info[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled[data-v-c2d2ef56],
.btn-outline-info[data-v-c2d2ef56]:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-outline-info[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-outline-info[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning[data-v-c2d2ef56] {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning[data-v-c2d2ef56]:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning.focus[data-v-c2d2ef56],
.btn-outline-warning[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled[data-v-c2d2ef56],
.btn-outline-warning[data-v-c2d2ef56]:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-outline-warning[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle[data-v-c2d2ef56] {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-outline-warning[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger[data-v-c2d2ef56] {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger.focus[data-v-c2d2ef56],
.btn-outline-danger[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled[data-v-c2d2ef56],
.btn-outline-danger[data-v-c2d2ef56]:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-outline-danger[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-outline-danger[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-light[data-v-c2d2ef56] {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light[data-v-c2d2ef56]:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light.focus[data-v-c2d2ef56],
.btn-outline-light[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled[data-v-c2d2ef56],
.btn-outline-light[data-v-c2d2ef56]:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-outline-light[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle[data-v-c2d2ef56] {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-outline-light[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark[data-v-c2d2ef56] {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark.focus[data-v-c2d2ef56],
.btn-outline-dark[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled[data-v-c2d2ef56],
.btn-outline-dark[data-v-c2d2ef56]:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active[data-v-c2d2ef56],
.btn-outline-dark[data-v-c2d2ef56]:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle[data-v-c2d2ef56] {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled).active[data-v-c2d2ef56]:focus,
.btn-outline-dark[data-v-c2d2ef56]:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle[data-v-c2d2ef56]:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link[data-v-c2d2ef56] {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link[data-v-c2d2ef56]:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link.focus[data-v-c2d2ef56],
.btn-link[data-v-c2d2ef56]:focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link.disabled[data-v-c2d2ef56],
.btn-link[data-v-c2d2ef56]:disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-group-lg > .btn[data-v-c2d2ef56],
.btn-lg[data-v-c2d2ef56] {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn[data-v-c2d2ef56],
.btn-sm[data-v-c2d2ef56] {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block[data-v-c2d2ef56] {
  display: block;
  width: 100%;
}
.btn-block + .btn-block[data-v-c2d2ef56] {
  margin-top: 0.5rem;
}
input[type="button"].btn-block[data-v-c2d2ef56],
input[type="reset"].btn-block[data-v-c2d2ef56],
input[type="submit"].btn-block[data-v-c2d2ef56] {
  width: 100%;
}
.fade[data-v-c2d2ef56] {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
.fade[data-v-c2d2ef56] {
    transition: none;
}
}
.fade[data-v-c2d2ef56]:not(.show) {
  opacity: 0;
}
.collapse[data-v-c2d2ef56]:not(.show) {
  display: none;
}
.collapsing[data-v-c2d2ef56] {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
.collapsing[data-v-c2d2ef56] {
    transition: none;
}
}
.dropdown[data-v-c2d2ef56],
.dropleft[data-v-c2d2ef56],
.dropright[data-v-c2d2ef56],
.dropup[data-v-c2d2ef56] {
  position: relative;
}
.dropdown-toggle[data-v-c2d2ef56] {
  white-space: nowrap;
}
.dropdown-toggle[data-v-c2d2ef56]::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle[data-v-c2d2ef56]:empty::after {
  margin-left: 0;
}
.dropdown-menu[data-v-c2d2ef56] {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-left[data-v-c2d2ef56] {
  right: auto;
  left: 0;
}
.dropdown-menu-right[data-v-c2d2ef56] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
.dropdown-menu-sm-left[data-v-c2d2ef56] {
    right: auto;
    left: 0;
}
.dropdown-menu-sm-right[data-v-c2d2ef56] {
    right: 0;
    left: auto;
}
}
@media (min-width: 768px) {
.dropdown-menu-md-left[data-v-c2d2ef56] {
    right: auto;
    left: 0;
}
.dropdown-menu-md-right[data-v-c2d2ef56] {
    right: 0;
    left: auto;
}
}
@media (min-width: 992px) {
.dropdown-menu-lg-left[data-v-c2d2ef56] {
    right: auto;
    left: 0;
}
.dropdown-menu-lg-right[data-v-c2d2ef56] {
    right: 0;
    left: auto;
}
}
@media (min-width: 1200px) {
.dropdown-menu-xl-left[data-v-c2d2ef56] {
    right: auto;
    left: 0;
}
.dropdown-menu-xl-right[data-v-c2d2ef56] {
    right: 0;
    left: auto;
}
}
.dropup .dropdown-menu[data-v-c2d2ef56] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle[data-v-c2d2ef56]::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle[data-v-c2d2ef56]:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu[data-v-c2d2ef56] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle[data-v-c2d2ef56]::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle[data-v-c2d2ef56]:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle[data-v-c2d2ef56]::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu[data-v-c2d2ef56] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle[data-v-c2d2ef56]::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle[data-v-c2d2ef56]::after {
  display: none;
}
.dropleft .dropdown-toggle[data-v-c2d2ef56]::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle[data-v-c2d2ef56]:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle[data-v-c2d2ef56]::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="bottom"][data-v-c2d2ef56],
.dropdown-menu[x-placement^="left"][data-v-c2d2ef56],
.dropdown-menu[x-placement^="right"][data-v-c2d2ef56],
.dropdown-menu[x-placement^="top"][data-v-c2d2ef56] {
  right: auto;
  bottom: auto;
}
.dropdown-divider[data-v-c2d2ef56] {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item[data-v-c2d2ef56] {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item[data-v-c2d2ef56]:focus,
.dropdown-item[data-v-c2d2ef56]:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active[data-v-c2d2ef56],
.dropdown-item[data-v-c2d2ef56]:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled[data-v-c2d2ef56],
.dropdown-item[data-v-c2d2ef56]:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show[data-v-c2d2ef56] {
  display: block;
}
.dropdown-header[data-v-c2d2ef56] {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text[data-v-c2d2ef56] {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.btn-group[data-v-c2d2ef56],
.btn-group-vertical[data-v-c2d2ef56] {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn[data-v-c2d2ef56],
.btn-group > .btn[data-v-c2d2ef56] {
  position: relative;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn[data-v-c2d2ef56]:hover,
.btn-group > .btn[data-v-c2d2ef56]:hover {
  z-index: 1;
}
.btn-group-vertical > .btn.active[data-v-c2d2ef56],
.btn-group-vertical > .btn[data-v-c2d2ef56]:active,
.btn-group-vertical > .btn[data-v-c2d2ef56]:focus,
.btn-group > .btn.active[data-v-c2d2ef56],
.btn-group > .btn[data-v-c2d2ef56]:active,
.btn-group > .btn[data-v-c2d2ef56]:focus {
  z-index: 1;
}
.btn-toolbar[data-v-c2d2ef56] {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group[data-v-c2d2ef56] {
  width: auto;
}
.btn-group > .btn-group[data-v-c2d2ef56]:not(:first-child),
.btn-group > .btn[data-v-c2d2ef56]:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn[data-v-c2d2ef56],
.btn-group > .btn[data-v-c2d2ef56]:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn[data-v-c2d2ef56],
.btn-group > .btn[data-v-c2d2ef56]:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split[data-v-c2d2ef56] {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split[data-v-c2d2ef56]::after,
.dropright .dropdown-toggle-split[data-v-c2d2ef56]::after,
.dropup .dropdown-toggle-split[data-v-c2d2ef56]::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split[data-v-c2d2ef56]::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split[data-v-c2d2ef56],
.btn-sm + .dropdown-toggle-split[data-v-c2d2ef56] {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split[data-v-c2d2ef56],
.btn-lg + .dropdown-toggle-split[data-v-c2d2ef56] {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical[data-v-c2d2ef56] {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn[data-v-c2d2ef56],
.btn-group-vertical > .btn-group[data-v-c2d2ef56] {
  width: 100%;
}
.btn-group-vertical > .btn-group[data-v-c2d2ef56]:not(:first-child),
.btn-group-vertical > .btn[data-v-c2d2ef56]:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn[data-v-c2d2ef56],
.btn-group-vertical > .btn[data-v-c2d2ef56]:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn[data-v-c2d2ef56],
.btn-group-vertical > .btn[data-v-c2d2ef56]:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn[data-v-c2d2ef56],
.btn-group-toggle > .btn-group > .btn[data-v-c2d2ef56] {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="checkbox"][data-v-c2d2ef56],
.btn-group-toggle > .btn input[type="radio"][data-v-c2d2ef56],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"][data-v-c2d2ef56],
.btn-group-toggle > .btn-group > .btn input[type="radio"][data-v-c2d2ef56] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group[data-v-c2d2ef56] {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-file[data-v-c2d2ef56],
.input-group > .custom-select[data-v-c2d2ef56],
.input-group > .form-control[data-v-c2d2ef56],
.input-group > .form-control-plaintext[data-v-c2d2ef56] {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .custom-file + .custom-file[data-v-c2d2ef56],
.input-group > .custom-file + .custom-select[data-v-c2d2ef56],
.input-group > .custom-file + .form-control[data-v-c2d2ef56],
.input-group > .custom-select + .custom-file[data-v-c2d2ef56],
.input-group > .custom-select + .custom-select[data-v-c2d2ef56],
.input-group > .custom-select + .form-control[data-v-c2d2ef56],
.input-group > .form-control + .custom-file[data-v-c2d2ef56],
.input-group > .form-control + .custom-select[data-v-c2d2ef56],
.input-group > .form-control + .form-control[data-v-c2d2ef56],
.input-group > .form-control-plaintext + .custom-file[data-v-c2d2ef56],
.input-group > .form-control-plaintext + .custom-select[data-v-c2d2ef56],
.input-group > .form-control-plaintext + .form-control[data-v-c2d2ef56] {
  margin-left: -1px;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label[data-v-c2d2ef56],
.input-group > .custom-select[data-v-c2d2ef56]:focus,
.input-group > .form-control[data-v-c2d2ef56]:focus {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input[data-v-c2d2ef56]:focus {
  z-index: 4;
}
.input-group > .custom-select[data-v-c2d2ef56]:not(:last-child),
.input-group > .form-control[data-v-c2d2ef56]:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-select[data-v-c2d2ef56]:not(:first-child),
.input-group > .form-control[data-v-c2d2ef56]:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file[data-v-c2d2ef56] {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label[data-v-c2d2ef56],
.input-group > .custom-file:not(:last-child) .custom-file-label[data-v-c2d2ef56]::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label[data-v-c2d2ef56] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-append[data-v-c2d2ef56],
.input-group-prepend[data-v-c2d2ef56] {
  display: flex;
}
.input-group-append .btn[data-v-c2d2ef56],
.input-group-prepend .btn[data-v-c2d2ef56] {
  position: relative;
  z-index: 2;
}
.input-group-append .btn[data-v-c2d2ef56]:focus,
.input-group-prepend .btn[data-v-c2d2ef56]:focus {
  z-index: 3;
}
.input-group-append .btn + .btn[data-v-c2d2ef56],
.input-group-append .btn + .input-group-text[data-v-c2d2ef56],
.input-group-append .input-group-text + .btn[data-v-c2d2ef56],
.input-group-append .input-group-text + .input-group-text[data-v-c2d2ef56],
.input-group-prepend .btn + .btn[data-v-c2d2ef56],
.input-group-prepend .btn + .input-group-text[data-v-c2d2ef56],
.input-group-prepend .input-group-text + .btn[data-v-c2d2ef56],
.input-group-prepend .input-group-text + .input-group-text[data-v-c2d2ef56] {
  margin-left: -1px;
}
.input-group-prepend[data-v-c2d2ef56] {
  margin-right: -1px;
}
.input-group-append[data-v-c2d2ef56] {
  margin-left: -1px;
}
.input-group-text[data-v-c2d2ef56] {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type="checkbox"][data-v-c2d2ef56],
.input-group-text input[type="radio"][data-v-c2d2ef56] {
  margin-top: 0;
}
.input-group-lg > .custom-select[data-v-c2d2ef56],
.input-group-lg > .form-control[data-v-c2d2ef56]:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}
.input-group-lg > .custom-select[data-v-c2d2ef56],
.input-group-lg > .form-control[data-v-c2d2ef56],
.input-group-lg > .input-group-append > .btn[data-v-c2d2ef56],
.input-group-lg > .input-group-append > .input-group-text[data-v-c2d2ef56],
.input-group-lg > .input-group-prepend > .btn[data-v-c2d2ef56],
.input-group-lg > .input-group-prepend > .input-group-text[data-v-c2d2ef56] {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .custom-select[data-v-c2d2ef56],
.input-group-sm > .form-control[data-v-c2d2ef56]:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}
.input-group-sm > .custom-select[data-v-c2d2ef56],
.input-group-sm > .form-control[data-v-c2d2ef56],
.input-group-sm > .input-group-append > .btn[data-v-c2d2ef56],
.input-group-sm > .input-group-append > .input-group-text[data-v-c2d2ef56],
.input-group-sm > .input-group-prepend > .btn[data-v-c2d2ef56],
.input-group-sm > .input-group-prepend > .input-group-text[data-v-c2d2ef56] {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select[data-v-c2d2ef56],
.input-group-sm > .custom-select[data-v-c2d2ef56] {
  padding-right: 1.75rem;
}
.input-group
  > .input-group-append:last-child
  > .btn[data-v-c2d2ef56]:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text[data-v-c2d2ef56]:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn[data-v-c2d2ef56],
.input-group > .input-group-append:not(:last-child) > .input-group-text[data-v-c2d2ef56],
.input-group > .input-group-prepend > .btn[data-v-c2d2ef56],
.input-group > .input-group-prepend > .input-group-text[data-v-c2d2ef56] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn[data-v-c2d2ef56],
.input-group > .input-group-append > .input-group-text[data-v-c2d2ef56],
.input-group > .input-group-prepend:first-child > .btn[data-v-c2d2ef56]:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text[data-v-c2d2ef56]:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn[data-v-c2d2ef56],
.input-group > .input-group-prepend:not(:first-child) > .input-group-text[data-v-c2d2ef56] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control[data-v-c2d2ef56] {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline[data-v-c2d2ef56] {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input[data-v-c2d2ef56] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label[data-v-c2d2ef56]::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label[data-v-c2d2ef56]::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label[data-v-c2d2ef56]::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label[data-v-c2d2ef56]::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label[data-v-c2d2ef56] {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label[data-v-c2d2ef56]::before {
  background-color: #e9ecef;
}
.custom-control-label[data-v-c2d2ef56] {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label[data-v-c2d2ef56]::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label[data-v-c2d2ef56]::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.custom-checkbox .custom-control-label[data-v-c2d2ef56]::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label[data-v-c2d2ef56]::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label[data-v-c2d2ef56]::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label[data-v-c2d2ef56]::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label[data-v-c2d2ef56]::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label[data-v-c2d2ef56]::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-radio .custom-control-label[data-v-c2d2ef56]::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label[data-v-c2d2ef56]::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label[data-v-c2d2ef56]::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-switch[data-v-c2d2ef56] {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label[data-v-c2d2ef56]::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label[data-v-c2d2ef56]::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.custom-switch .custom-control-label[data-v-c2d2ef56]::after {
    transition: none;
}
}
.custom-switch .custom-control-input:checked ~ .custom-control-label[data-v-c2d2ef56]::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label[data-v-c2d2ef56]::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-select[data-v-c2d2ef56] {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
}
.custom-select[data-v-c2d2ef56]:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select[data-v-c2d2ef56]:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple][data-v-c2d2ef56],
.custom-select[size][data-v-c2d2ef56]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select[data-v-c2d2ef56]:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select[data-v-c2d2ef56]::-ms-expand {
  display: none;
}
.custom-select-sm[data-v-c2d2ef56] {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg[data-v-c2d2ef56] {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file[data-v-c2d2ef56] {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.custom-file-input[data-v-c2d2ef56] {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label[data-v-c2d2ef56] {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label[data-v-c2d2ef56] {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label[data-v-c2d2ef56]::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse][data-v-c2d2ef56]::after {
  content: attr(data-browse);
}
.custom-file-label[data-v-c2d2ef56] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label[data-v-c2d2ef56]::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range[data-v-c2d2ef56] {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}
.custom-range[data-v-c2d2ef56]:focus {
  outline: 0;
}
.custom-range[data-v-c2d2ef56]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range[data-v-c2d2ef56]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range[data-v-c2d2ef56]:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range[data-v-c2d2ef56]::-moz-focus-outer {
  border: 0;
}
.custom-range[data-v-c2d2ef56]::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
.custom-range[data-v-c2d2ef56]::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
}
}
.custom-range[data-v-c2d2ef56]::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range[data-v-c2d2ef56]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range[data-v-c2d2ef56]::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
.custom-range[data-v-c2d2ef56]::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
}
}
.custom-range[data-v-c2d2ef56]::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range[data-v-c2d2ef56]::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range[data-v-c2d2ef56]::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
.custom-range[data-v-c2d2ef56]::-ms-thumb {
    -ms-transition: none;
    transition: none;
}
}
.custom-range[data-v-c2d2ef56]::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range[data-v-c2d2ef56]::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range[data-v-c2d2ef56]::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range[data-v-c2d2ef56]::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range[data-v-c2d2ef56]:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range[data-v-c2d2ef56]:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range[data-v-c2d2ef56]:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range[data-v-c2d2ef56]:disabled::-moz-range-track {
  cursor: default;
}
.custom-range[data-v-c2d2ef56]:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label[data-v-c2d2ef56]::before,
.custom-file-label[data-v-c2d2ef56],
.custom-select[data-v-c2d2ef56] {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.custom-control-label[data-v-c2d2ef56]::before,
  .custom-file-label[data-v-c2d2ef56],
  .custom-select[data-v-c2d2ef56] {
    transition: none;
}
}
.nav[data-v-c2d2ef56] {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link[data-v-c2d2ef56] {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link[data-v-c2d2ef56]:focus,
.nav-link[data-v-c2d2ef56]:hover {
  text-decoration: none;
}
.nav-link.disabled[data-v-c2d2ef56] {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs[data-v-c2d2ef56] {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item[data-v-c2d2ef56] {
  margin-bottom: -1px;
}
.nav-tabs .nav-link[data-v-c2d2ef56] {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link[data-v-c2d2ef56]:focus,
.nav-tabs .nav-link[data-v-c2d2ef56]:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled[data-v-c2d2ef56] {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link[data-v-c2d2ef56],
.nav-tabs .nav-link.active[data-v-c2d2ef56] {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu[data-v-c2d2ef56] {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link[data-v-c2d2ef56] {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active[data-v-c2d2ef56],
.nav-pills .show > .nav-link[data-v-c2d2ef56] {
  color: #fff;
  background-color: #007bff;
}
.nav-fill .nav-item[data-v-c2d2ef56] {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item[data-v-c2d2ef56] {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane[data-v-c2d2ef56] {
  display: none;
}
.tab-content > .active[data-v-c2d2ef56] {
  display: block;
}
.navbar[data-v-c2d2ef56] {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container[data-v-c2d2ef56],
.navbar > .container-fluid[data-v-c2d2ef56] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand[data-v-c2d2ef56] {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand[data-v-c2d2ef56]:focus,
.navbar-brand[data-v-c2d2ef56]:hover {
  text-decoration: none;
}
.navbar-nav[data-v-c2d2ef56] {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link[data-v-c2d2ef56] {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu[data-v-c2d2ef56] {
  position: static;
  float: none;
}
.navbar-text[data-v-c2d2ef56] {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse[data-v-c2d2ef56] {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler[data-v-c2d2ef56] {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler[data-v-c2d2ef56]:focus,
.navbar-toggler[data-v-c2d2ef56]:hover {
  text-decoration: none;
}
.navbar-toggler-icon[data-v-c2d2ef56] {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
.navbar-expand-sm > .container[data-v-c2d2ef56],
  .navbar-expand-sm > .container-fluid[data-v-c2d2ef56] {
    padding-right: 0;
    padding-left: 0;
}
}
@media (min-width: 576px) {
.navbar-expand-sm[data-v-c2d2ef56] {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand-sm .navbar-nav[data-v-c2d2ef56] {
    flex-direction: row;
}
.navbar-expand-sm .navbar-nav .dropdown-menu[data-v-c2d2ef56] {
    position: absolute;
}
.navbar-expand-sm .navbar-nav .nav-link[data-v-c2d2ef56] {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.navbar-expand-sm > .container[data-v-c2d2ef56],
  .navbar-expand-sm > .container-fluid[data-v-c2d2ef56] {
    flex-wrap: nowrap;
}
.navbar-expand-sm .navbar-collapse[data-v-c2d2ef56] {
    display: flex !important;
    flex-basis: auto;
}
.navbar-expand-sm .navbar-toggler[data-v-c2d2ef56] {
    display: none;
}
}
@media (max-width: 767.98px) {
.navbar-expand-md > .container[data-v-c2d2ef56],
  .navbar-expand-md > .container-fluid[data-v-c2d2ef56] {
    padding-right: 0;
    padding-left: 0;
}
}
@media (min-width: 768px) {
.navbar-expand-md[data-v-c2d2ef56] {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand-md .navbar-nav[data-v-c2d2ef56] {
    flex-direction: row;
}
.navbar-expand-md .navbar-nav .dropdown-menu[data-v-c2d2ef56] {
    position: absolute;
}
.navbar-expand-md .navbar-nav .nav-link[data-v-c2d2ef56] {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.navbar-expand-md > .container[data-v-c2d2ef56],
  .navbar-expand-md > .container-fluid[data-v-c2d2ef56] {
    flex-wrap: nowrap;
}
.navbar-expand-md .navbar-collapse[data-v-c2d2ef56] {
    display: flex !important;
    flex-basis: auto;
}
.navbar-expand-md .navbar-toggler[data-v-c2d2ef56] {
    display: none;
}
}
@media (max-width: 991.98px) {
.navbar-expand-lg > .container[data-v-c2d2ef56],
  .navbar-expand-lg > .container-fluid[data-v-c2d2ef56] {
    padding-right: 0;
    padding-left: 0;
}
}
@media (min-width: 992px) {
.navbar-expand-lg[data-v-c2d2ef56] {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand-lg .navbar-nav[data-v-c2d2ef56] {
    flex-direction: row;
}
.navbar-expand-lg .navbar-nav .dropdown-menu[data-v-c2d2ef56] {
    position: absolute;
}
.navbar-expand-lg .navbar-nav .nav-link[data-v-c2d2ef56] {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.navbar-expand-lg > .container[data-v-c2d2ef56],
  .navbar-expand-lg > .container-fluid[data-v-c2d2ef56] {
    flex-wrap: nowrap;
}
.navbar-expand-lg .navbar-collapse[data-v-c2d2ef56] {
    display: flex !important;
    flex-basis: auto;
}
.navbar-expand-lg .navbar-toggler[data-v-c2d2ef56] {
    display: none;
}
}
@media (max-width: 1199.98px) {
.navbar-expand-xl > .container[data-v-c2d2ef56],
  .navbar-expand-xl > .container-fluid[data-v-c2d2ef56] {
    padding-right: 0;
    padding-left: 0;
}
}
@media (min-width: 1200px) {
.navbar-expand-xl[data-v-c2d2ef56] {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand-xl .navbar-nav[data-v-c2d2ef56] {
    flex-direction: row;
}
.navbar-expand-xl .navbar-nav .dropdown-menu[data-v-c2d2ef56] {
    position: absolute;
}
.navbar-expand-xl .navbar-nav .nav-link[data-v-c2d2ef56] {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.navbar-expand-xl > .container[data-v-c2d2ef56],
  .navbar-expand-xl > .container-fluid[data-v-c2d2ef56] {
    flex-wrap: nowrap;
}
.navbar-expand-xl .navbar-collapse[data-v-c2d2ef56] {
    display: flex !important;
    flex-basis: auto;
}
.navbar-expand-xl .navbar-toggler[data-v-c2d2ef56] {
    display: none;
}
}
.navbar-expand[data-v-c2d2ef56] {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container[data-v-c2d2ef56],
.navbar-expand > .container-fluid[data-v-c2d2ef56] {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav[data-v-c2d2ef56] {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu[data-v-c2d2ef56] {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link[data-v-c2d2ef56] {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container[data-v-c2d2ef56],
.navbar-expand > .container-fluid[data-v-c2d2ef56] {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse[data-v-c2d2ef56] {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler[data-v-c2d2ef56] {
  display: none;
}
.navbar-light .navbar-brand[data-v-c2d2ef56] {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand[data-v-c2d2ef56]:focus,
.navbar-light .navbar-brand[data-v-c2d2ef56]:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link[data-v-c2d2ef56] {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link[data-v-c2d2ef56]:focus,
.navbar-light .navbar-nav .nav-link[data-v-c2d2ef56]:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled[data-v-c2d2ef56] {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link[data-v-c2d2ef56],
.navbar-light .navbar-nav .nav-link.active[data-v-c2d2ef56],
.navbar-light .navbar-nav .nav-link.show[data-v-c2d2ef56],
.navbar-light .navbar-nav .show > .nav-link[data-v-c2d2ef56] {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler[data-v-c2d2ef56] {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon[data-v-c2d2ef56] {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text[data-v-c2d2ef56] {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a[data-v-c2d2ef56] {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a[data-v-c2d2ef56]:focus,
.navbar-light .navbar-text a[data-v-c2d2ef56]:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand[data-v-c2d2ef56] {
  color: #fff;
}
.navbar-dark .navbar-brand[data-v-c2d2ef56]:focus,
.navbar-dark .navbar-brand[data-v-c2d2ef56]:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link[data-v-c2d2ef56] {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link[data-v-c2d2ef56]:focus,
.navbar-dark .navbar-nav .nav-link[data-v-c2d2ef56]:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled[data-v-c2d2ef56] {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link[data-v-c2d2ef56],
.navbar-dark .navbar-nav .nav-link.active[data-v-c2d2ef56],
.navbar-dark .navbar-nav .nav-link.show[data-v-c2d2ef56],
.navbar-dark .navbar-nav .show > .nav-link[data-v-c2d2ef56] {
  color: #fff;
}
.navbar-dark .navbar-toggler[data-v-c2d2ef56] {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon[data-v-c2d2ef56] {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text[data-v-c2d2ef56] {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a[data-v-c2d2ef56] {
  color: #fff;
}
.navbar-dark .navbar-text a[data-v-c2d2ef56]:focus,
.navbar-dark .navbar-text a[data-v-c2d2ef56]:hover {
  color: #fff;
}
.card[data-v-c2d2ef56] {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr[data-v-c2d2ef56] {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item[data-v-c2d2ef56]:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item[data-v-c2d2ef56]:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-body[data-v-c2d2ef56] {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card-title[data-v-c2d2ef56] {
  margin-bottom: 0.75rem;
}
.card-subtitle[data-v-c2d2ef56] {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text[data-v-c2d2ef56]:last-child {
  margin-bottom: 0;
}
.card-link[data-v-c2d2ef56]:hover {
  text-decoration: none;
}
.card-link + .card-link[data-v-c2d2ef56] {
  margin-left: 1.25rem;
}
.card-header[data-v-c2d2ef56] {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header[data-v-c2d2ef56]:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item[data-v-c2d2ef56]:first-child {
  border-top: 0;
}
.card-footer[data-v-c2d2ef56] {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer[data-v-c2d2ef56]:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs[data-v-c2d2ef56] {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills[data-v-c2d2ef56] {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay[data-v-c2d2ef56] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img[data-v-c2d2ef56] {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.card-img-top[data-v-c2d2ef56] {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom[data-v-c2d2ef56] {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck[data-v-c2d2ef56] {
  display: flex;
  flex-direction: column;
}
.card-deck .card[data-v-c2d2ef56] {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
.card-deck[data-v-c2d2ef56] {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.card-deck .card[data-v-c2d2ef56] {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
}
}
.card-group[data-v-c2d2ef56] {
  display: flex;
  flex-direction: column;
}
.card-group > .card[data-v-c2d2ef56] {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
.card-group[data-v-c2d2ef56] {
    flex-flow: row wrap;
}
.card-group > .card[data-v-c2d2ef56] {
    flex: 1 0;
    margin-bottom: 0;
}
.card-group > .card + .card[data-v-c2d2ef56] {
    margin-left: 0;
    border-left: 0;
}
.card-group > .card[data-v-c2d2ef56]:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.card-group > .card:not(:last-child) .card-header[data-v-c2d2ef56],
  .card-group > .card:not(:last-child) .card-img-top[data-v-c2d2ef56] {
    border-top-right-radius: 0;
}
.card-group > .card:not(:last-child) .card-footer[data-v-c2d2ef56],
  .card-group > .card:not(:last-child) .card-img-bottom[data-v-c2d2ef56] {
    border-bottom-right-radius: 0;
}
.card-group > .card[data-v-c2d2ef56]:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.card-group > .card:not(:first-child) .card-header[data-v-c2d2ef56],
  .card-group > .card:not(:first-child) .card-img-top[data-v-c2d2ef56] {
    border-top-left-radius: 0;
}
.card-group > .card:not(:first-child) .card-footer[data-v-c2d2ef56],
  .card-group > .card:not(:first-child) .card-img-bottom[data-v-c2d2ef56] {
    border-bottom-left-radius: 0;
}
}
.card-columns .card[data-v-c2d2ef56] {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
.card-columns[data-v-c2d2ef56] {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
}
.card-columns .card[data-v-c2d2ef56] {
    display: inline-block;
    width: 100%;
}
}
.accordion > .card[data-v-c2d2ef56] {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header[data-v-c2d2ef56]:first-child {
  border-radius: 0;
}
.accordion > .card[data-v-c2d2ef56]:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card[data-v-c2d2ef56]:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card[data-v-c2d2ef56]:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header[data-v-c2d2ef56] {
  margin-bottom: -1px;
}
.breadcrumb[data-v-c2d2ef56] {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item[data-v-c2d2ef56] {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item[data-v-c2d2ef56]::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item[data-v-c2d2ef56]:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item[data-v-c2d2ef56]:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active[data-v-c2d2ef56] {
  color: #6c757d;
}
.pagination[data-v-c2d2ef56] {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link[data-v-c2d2ef56] {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link[data-v-c2d2ef56]:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link[data-v-c2d2ef56]:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-item:first-child .page-link[data-v-c2d2ef56] {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link[data-v-c2d2ef56] {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link[data-v-c2d2ef56] {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link[data-v-c2d2ef56] {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link[data-v-c2d2ef56] {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link[data-v-c2d2ef56] {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link[data-v-c2d2ef56] {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link[data-v-c2d2ef56] {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link[data-v-c2d2ef56] {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link[data-v-c2d2ef56] {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge[data-v-c2d2ef56] {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.badge[data-v-c2d2ef56] {
    transition: none;
}
}
a.badge[data-v-c2d2ef56]:focus,
a.badge[data-v-c2d2ef56]:hover {
  text-decoration: none;
}
.badge[data-v-c2d2ef56]:empty {
  display: none;
}
.btn .badge[data-v-c2d2ef56] {
  position: relative;
  top: -1px;
}
.badge-pill[data-v-c2d2ef56] {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary[data-v-c2d2ef56] {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary[data-v-c2d2ef56]:focus,
a.badge-primary[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary.focus[data-v-c2d2ef56],
a.badge-primary[data-v-c2d2ef56]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.badge-secondary[data-v-c2d2ef56] {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary[data-v-c2d2ef56]:focus,
a.badge-secondary[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary.focus[data-v-c2d2ef56],
a.badge-secondary[data-v-c2d2ef56]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success[data-v-c2d2ef56] {
  color: #fff;
  background-color: #28a745;
}
a.badge-success[data-v-c2d2ef56]:focus,
a.badge-success[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success.focus[data-v-c2d2ef56],
a.badge-success[data-v-c2d2ef56]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.badge-info[data-v-c2d2ef56] {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info[data-v-c2d2ef56]:focus,
a.badge-info[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info.focus[data-v-c2d2ef56],
a.badge-info[data-v-c2d2ef56]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning[data-v-c2d2ef56] {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning[data-v-c2d2ef56]:focus,
a.badge-warning[data-v-c2d2ef56]:hover {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning.focus[data-v-c2d2ef56],
a.badge-warning[data-v-c2d2ef56]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger[data-v-c2d2ef56] {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger[data-v-c2d2ef56]:focus,
a.badge-danger[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger.focus[data-v-c2d2ef56],
a.badge-danger[data-v-c2d2ef56]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.badge-light[data-v-c2d2ef56] {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light[data-v-c2d2ef56]:focus,
a.badge-light[data-v-c2d2ef56]:hover {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light.focus[data-v-c2d2ef56],
a.badge-light[data-v-c2d2ef56]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark[data-v-c2d2ef56] {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark[data-v-c2d2ef56]:focus,
a.badge-dark[data-v-c2d2ef56]:hover {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark.focus[data-v-c2d2ef56],
a.badge-dark[data-v-c2d2ef56]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.jumbotron[data-v-c2d2ef56] {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
.jumbotron[data-v-c2d2ef56] {
    padding: 4rem 2rem;
}
}
.jumbotron-fluid[data-v-c2d2ef56] {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert[data-v-c2d2ef56] {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading[data-v-c2d2ef56] {
  color: inherit;
}
.alert-link[data-v-c2d2ef56] {
  font-weight: 700;
}
.alert-dismissible[data-v-c2d2ef56] {
  padding-right: 4rem;
}
.alert-dismissible .close[data-v-c2d2ef56] {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary[data-v-c2d2ef56] {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr[data-v-c2d2ef56] {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link[data-v-c2d2ef56] {
  color: #002752;
}
.alert-secondary[data-v-c2d2ef56] {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr[data-v-c2d2ef56] {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link[data-v-c2d2ef56] {
  color: #202326;
}
.alert-success[data-v-c2d2ef56] {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr[data-v-c2d2ef56] {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link[data-v-c2d2ef56] {
  color: #0b2e13;
}
.alert-info[data-v-c2d2ef56] {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr[data-v-c2d2ef56] {
  border-top-color: #abdde5;
}
.alert-info .alert-link[data-v-c2d2ef56] {
  color: #062c33;
}
.alert-warning[data-v-c2d2ef56] {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr[data-v-c2d2ef56] {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link[data-v-c2d2ef56] {
  color: #533f03;
}
.alert-danger[data-v-c2d2ef56] {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr[data-v-c2d2ef56] {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link[data-v-c2d2ef56] {
  color: #491217;
}
.alert-light[data-v-c2d2ef56] {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr[data-v-c2d2ef56] {
  border-top-color: #ececf6;
}
.alert-light .alert-link[data-v-c2d2ef56] {
  color: #686868;
}
.alert-dark[data-v-c2d2ef56] {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr[data-v-c2d2ef56] {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link[data-v-c2d2ef56] {
  color: #040505;
}
@-webkit-keyframes progress-bar-stripes-data-v-c2d2ef56 {
from {
    background-position: 1rem 0;
}
to {
    background-position: 0 0;
}
}
@keyframes progress-bar-stripes-data-v-c2d2ef56 {
from {
    background-position: 1rem 0;
}
to {
    background-position: 0 0;
}
}
.progress[data-v-c2d2ef56] {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar[data-v-c2d2ef56] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
.progress-bar[data-v-c2d2ef56] {
    transition: none;
}
}
.progress-bar-striped[data-v-c2d2ef56] {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated[data-v-c2d2ef56] {
  -webkit-animation: progress-bar-stripes-data-v-c2d2ef56 1s linear infinite;
  animation: progress-bar-stripes-data-v-c2d2ef56 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
.progress-bar-animated[data-v-c2d2ef56] {
    -webkit-animation: none;
    animation: none;
}
}
.media[data-v-c2d2ef56] {
  display: flex;
  align-items: flex-start;
}
.media-body[data-v-c2d2ef56] {
  flex: 1 1;
}
.list-group[data-v-c2d2ef56] {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-action[data-v-c2d2ef56] {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action[data-v-c2d2ef56]:focus,
.list-group-item-action[data-v-c2d2ef56]:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action[data-v-c2d2ef56]:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item[data-v-c2d2ef56] {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item[data-v-c2d2ef56]:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item[data-v-c2d2ef56]:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled[data-v-c2d2ef56],
.list-group-item[data-v-c2d2ef56]:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active[data-v-c2d2ef56] {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-horizontal[data-v-c2d2ef56] {
  flex-direction: row;
}
.list-group-horizontal .list-group-item[data-v-c2d2ef56] {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item[data-v-c2d2ef56]:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item[data-v-c2d2ef56]:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
@media (min-width: 576px) {
.list-group-horizontal-sm[data-v-c2d2ef56] {
    flex-direction: row;
}
.list-group-horizontal-sm .list-group-item[data-v-c2d2ef56] {
    margin-right: -1px;
    margin-bottom: 0;
}
.list-group-horizontal-sm .list-group-item[data-v-c2d2ef56]:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}
.list-group-horizontal-sm .list-group-item[data-v-c2d2ef56]:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}
}
@media (min-width: 768px) {
.list-group-horizontal-md[data-v-c2d2ef56] {
    flex-direction: row;
}
.list-group-horizontal-md .list-group-item[data-v-c2d2ef56] {
    margin-right: -1px;
    margin-bottom: 0;
}
.list-group-horizontal-md .list-group-item[data-v-c2d2ef56]:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}
.list-group-horizontal-md .list-group-item[data-v-c2d2ef56]:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}
}
@media (min-width: 992px) {
.list-group-horizontal-lg[data-v-c2d2ef56] {
    flex-direction: row;
}
.list-group-horizontal-lg .list-group-item[data-v-c2d2ef56] {
    margin-right: -1px;
    margin-bottom: 0;
}
.list-group-horizontal-lg .list-group-item[data-v-c2d2ef56]:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}
.list-group-horizontal-lg .list-group-item[data-v-c2d2ef56]:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}
}
@media (min-width: 1200px) {
.list-group-horizontal-xl[data-v-c2d2ef56] {
    flex-direction: row;
}
.list-group-horizontal-xl .list-group-item[data-v-c2d2ef56] {
    margin-right: -1px;
    margin-bottom: 0;
}
.list-group-horizontal-xl .list-group-item[data-v-c2d2ef56]:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}
.list-group-horizontal-xl .list-group-item[data-v-c2d2ef56]:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}
}
.list-group-flush .list-group-item[data-v-c2d2ef56] {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item[data-v-c2d2ef56]:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item[data-v-c2d2ef56]:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item[data-v-c2d2ef56]:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
.list-group-item-primary[data-v-c2d2ef56] {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action[data-v-c2d2ef56]:focus,
.list-group-item-primary.list-group-item-action[data-v-c2d2ef56]:hover {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active[data-v-c2d2ef56] {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.list-group-item-secondary[data-v-c2d2ef56] {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action[data-v-c2d2ef56]:focus,
.list-group-item-secondary.list-group-item-action[data-v-c2d2ef56]:hover {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active[data-v-c2d2ef56] {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success[data-v-c2d2ef56] {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action[data-v-c2d2ef56]:focus,
.list-group-item-success.list-group-item-action[data-v-c2d2ef56]:hover {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active[data-v-c2d2ef56] {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info[data-v-c2d2ef56] {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action[data-v-c2d2ef56]:focus,
.list-group-item-info.list-group-item-action[data-v-c2d2ef56]:hover {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active[data-v-c2d2ef56] {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning[data-v-c2d2ef56] {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action[data-v-c2d2ef56]:focus,
.list-group-item-warning.list-group-item-action[data-v-c2d2ef56]:hover {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active[data-v-c2d2ef56] {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger[data-v-c2d2ef56] {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action[data-v-c2d2ef56]:focus,
.list-group-item-danger.list-group-item-action[data-v-c2d2ef56]:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active[data-v-c2d2ef56] {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.list-group-item-light[data-v-c2d2ef56] {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action[data-v-c2d2ef56]:focus,
.list-group-item-light.list-group-item-action[data-v-c2d2ef56]:hover {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active[data-v-c2d2ef56] {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark[data-v-c2d2ef56] {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action[data-v-c2d2ef56]:focus,
.list-group-item-dark.list-group-item-action[data-v-c2d2ef56]:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active[data-v-c2d2ef56] {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close[data-v-c2d2ef56] {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close[data-v-c2d2ef56]:hover {
  color: #000;
  text-decoration: none;
}
.close[data-v-c2d2ef56]:not(:disabled):not(.disabled):focus,
.close[data-v-c2d2ef56]:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
button.close[data-v-c2d2ef56] {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}
a.close.disabled[data-v-c2d2ef56] {
  pointer-events: none;
}
.toast[data-v-c2d2ef56] {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast[data-v-c2d2ef56]:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing[data-v-c2d2ef56] {
  opacity: 1;
}
.toast.show[data-v-c2d2ef56] {
  display: block;
  opacity: 1;
}
.toast.hide[data-v-c2d2ef56] {
  display: none;
}
.toast-header[data-v-c2d2ef56] {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.toast-body[data-v-c2d2ef56] {
  padding: 0.75rem;
}
.modal-open[data-v-c2d2ef56] {
  overflow: hidden;
}
.modal-open .modal[data-v-c2d2ef56] {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal[data-v-c2d2ef56] {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog[data-v-c2d2ef56] {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog[data-v-c2d2ef56] {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
.modal.fade .modal-dialog[data-v-c2d2ef56] {
    transition: none;
}
}
.modal.show .modal-dialog[data-v-c2d2ef56] {
  -webkit-transform: none;
  transform: none;
}
.modal-dialog-scrollable[data-v-c2d2ef56] {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content[data-v-c2d2ef56] {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-footer[data-v-c2d2ef56],
.modal-dialog-scrollable .modal-header[data-v-c2d2ef56] {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body[data-v-c2d2ef56] {
  overflow-y: auto;
}
.modal-dialog-centered[data-v-c2d2ef56] {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered[data-v-c2d2ef56]::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable[data-v-c2d2ef56] {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content[data-v-c2d2ef56] {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable[data-v-c2d2ef56]::before {
  content: none;
}
.modal-content[data-v-c2d2ef56] {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop[data-v-c2d2ef56] {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade[data-v-c2d2ef56] {
  opacity: 0;
}
.modal-backdrop.show[data-v-c2d2ef56] {
  opacity: 0.5;
}
.modal-header[data-v-c2d2ef56] {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close[data-v-c2d2ef56] {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title[data-v-c2d2ef56] {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body[data-v-c2d2ef56] {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer[data-v-c2d2ef56] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer[data-v-c2d2ef56] > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer[data-v-c2d2ef56] > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-scrollbar-measure[data-v-c2d2ef56] {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
.modal-dialog[data-v-c2d2ef56] {
    max-width: 500px;
    margin: 1.75rem auto;
}
.modal-dialog-scrollable[data-v-c2d2ef56] {
    max-height: calc(100% - 3.5rem);
}
.modal-dialog-scrollable .modal-content[data-v-c2d2ef56] {
    max-height: calc(100vh - 3.5rem);
}
.modal-dialog-centered[data-v-c2d2ef56] {
    min-height: calc(100% - 3.5rem);
}
.modal-dialog-centered[data-v-c2d2ef56]::before {
    height: calc(100vh - 3.5rem);
}
.modal-sm[data-v-c2d2ef56] {
    max-width: 300px;
}
}
@media (min-width: 992px) {
.modal-lg[data-v-c2d2ef56],
  .modal-xl[data-v-c2d2ef56] {
    max-width: 800px;
}
}
@media (min-width: 1200px) {
.modal-xl[data-v-c2d2ef56] {
    max-width: 1140px;
}
}
.tooltip[data-v-c2d2ef56] {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show[data-v-c2d2ef56] {
  opacity: 0.9;
}
.tooltip .arrow[data-v-c2d2ef56] {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow[data-v-c2d2ef56]::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^="top"][data-v-c2d2ef56],
.bs-tooltip-top[data-v-c2d2ef56] {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow[data-v-c2d2ef56],
.bs-tooltip-top .arrow[data-v-c2d2ef56] {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow[data-v-c2d2ef56]::before,
.bs-tooltip-top .arrow[data-v-c2d2ef56]::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[x-placement^="right"][data-v-c2d2ef56],
.bs-tooltip-right[data-v-c2d2ef56] {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow[data-v-c2d2ef56],
.bs-tooltip-right .arrow[data-v-c2d2ef56] {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow[data-v-c2d2ef56]::before,
.bs-tooltip-right .arrow[data-v-c2d2ef56]::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[x-placement^="bottom"][data-v-c2d2ef56],
.bs-tooltip-bottom[data-v-c2d2ef56] {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow[data-v-c2d2ef56],
.bs-tooltip-bottom .arrow[data-v-c2d2ef56] {
  top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow[data-v-c2d2ef56]::before,
.bs-tooltip-bottom .arrow[data-v-c2d2ef56]::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^="left"][data-v-c2d2ef56],
.bs-tooltip-left[data-v-c2d2ef56] {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow[data-v-c2d2ef56],
.bs-tooltip-left .arrow[data-v-c2d2ef56] {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow[data-v-c2d2ef56]::before,
.bs-tooltip-left .arrow[data-v-c2d2ef56]::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner[data-v-c2d2ef56] {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover[data-v-c2d2ef56] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow[data-v-c2d2ef56] {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow[data-v-c2d2ef56]::after,
.popover .arrow[data-v-c2d2ef56]::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^="top"][data-v-c2d2ef56],
.bs-popover-top[data-v-c2d2ef56] {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^="top"] > .arrow[data-v-c2d2ef56],
.bs-popover-top > .arrow[data-v-c2d2ef56] {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="top"] > .arrow[data-v-c2d2ef56]::before,
.bs-popover-top > .arrow[data-v-c2d2ef56]::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="top"] > .arrow[data-v-c2d2ef56]::after,
.bs-popover-top > .arrow[data-v-c2d2ef56]::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^="right"][data-v-c2d2ef56],
.bs-popover-right[data-v-c2d2ef56] {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^="right"] > .arrow[data-v-c2d2ef56],
.bs-popover-right > .arrow[data-v-c2d2ef56] {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="right"] > .arrow[data-v-c2d2ef56]::before,
.bs-popover-right > .arrow[data-v-c2d2ef56]::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="right"] > .arrow[data-v-c2d2ef56]::after,
.bs-popover-right > .arrow[data-v-c2d2ef56]::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"][data-v-c2d2ef56],
.bs-popover-bottom[data-v-c2d2ef56] {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] > .arrow[data-v-c2d2ef56],
.bs-popover-bottom > .arrow[data-v-c2d2ef56] {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow[data-v-c2d2ef56]::before,
.bs-popover-bottom > .arrow[data-v-c2d2ef56]::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow[data-v-c2d2ef56]::after,
.bs-popover-bottom > .arrow[data-v-c2d2ef56]::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"] .popover-header[data-v-c2d2ef56]::before,
.bs-popover-bottom .popover-header[data-v-c2d2ef56]::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^="left"][data-v-c2d2ef56],
.bs-popover-left[data-v-c2d2ef56] {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^="left"] > .arrow[data-v-c2d2ef56],
.bs-popover-left > .arrow[data-v-c2d2ef56] {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="left"] > .arrow[data-v-c2d2ef56]::before,
.bs-popover-left > .arrow[data-v-c2d2ef56]::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="left"] > .arrow[data-v-c2d2ef56]::after,
.bs-popover-left > .arrow[data-v-c2d2ef56]::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header[data-v-c2d2ef56] {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header[data-v-c2d2ef56]:empty {
  display: none;
}
.popover-body[data-v-c2d2ef56] {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel[data-v-c2d2ef56] {
  position: relative;
}
.carousel.pointer-event[data-v-c2d2ef56] {
  touch-action: pan-y;
}
.carousel-inner[data-v-c2d2ef56] {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner[data-v-c2d2ef56]::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item[data-v-c2d2ef56] {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.carousel-item[data-v-c2d2ef56] {
    transition: none;
}
}
.carousel-item-next[data-v-c2d2ef56],
.carousel-item-prev[data-v-c2d2ef56],
.carousel-item.active[data-v-c2d2ef56] {
  display: block;
}
.active.carousel-item-right[data-v-c2d2ef56],
.carousel-item-next[data-v-c2d2ef56]:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.active.carousel-item-left[data-v-c2d2ef56],
.carousel-item-prev[data-v-c2d2ef56]:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.carousel-fade .carousel-item[data-v-c2d2ef56] {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-left[data-v-c2d2ef56],
.carousel-fade .carousel-item-prev.carousel-item-right[data-v-c2d2ef56],
.carousel-fade .carousel-item.active[data-v-c2d2ef56] {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left[data-v-c2d2ef56],
.carousel-fade .active.carousel-item-right[data-v-c2d2ef56] {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
.carousel-fade .active.carousel-item-left[data-v-c2d2ef56],
  .carousel-fade .active.carousel-item-right[data-v-c2d2ef56] {
    transition: none;
}
}
.carousel-control-next[data-v-c2d2ef56],
.carousel-control-prev[data-v-c2d2ef56] {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
.carousel-control-next[data-v-c2d2ef56],
  .carousel-control-prev[data-v-c2d2ef56] {
    transition: none;
}
}
.carousel-control-next[data-v-c2d2ef56]:focus,
.carousel-control-next[data-v-c2d2ef56]:hover,
.carousel-control-prev[data-v-c2d2ef56]:focus,
.carousel-control-prev[data-v-c2d2ef56]:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev[data-v-c2d2ef56] {
  left: 0;
}
.carousel-control-next[data-v-c2d2ef56] {
  right: 0;
}
.carousel-control-next-icon[data-v-c2d2ef56],
.carousel-control-prev-icon[data-v-c2d2ef56] {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.carousel-control-prev-icon[data-v-c2d2ef56] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon[data-v-c2d2ef56] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators[data-v-c2d2ef56] {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li[data-v-c2d2ef56] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
.carousel-indicators li[data-v-c2d2ef56] {
    transition: none;
}
}
.carousel-indicators .active[data-v-c2d2ef56] {
  opacity: 1;
}
.carousel-caption[data-v-c2d2ef56] {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border-data-v-c2d2ef56 {
to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
}
@keyframes spinner-border-data-v-c2d2ef56 {
to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
}
.spinner-border[data-v-c2d2ef56] {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border-data-v-c2d2ef56 0.75s linear infinite;
  animation: spinner-border-data-v-c2d2ef56 0.75s linear infinite;
}
.spinner-border-sm[data-v-c2d2ef56] {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow-data-v-c2d2ef56 {
0% {
    -webkit-transform: scale(0);
    transform: scale(0);
}
50% {
    opacity: 1;
}
}
@keyframes spinner-grow-data-v-c2d2ef56 {
0% {
    -webkit-transform: scale(0);
    transform: scale(0);
}
50% {
    opacity: 1;
}
}
.spinner-grow[data-v-c2d2ef56] {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow-data-v-c2d2ef56 0.75s linear infinite;
  animation: spinner-grow-data-v-c2d2ef56 0.75s linear infinite;
}
.spinner-grow-sm[data-v-c2d2ef56] {
  width: 1rem;
  height: 1rem;
}
.align-baseline[data-v-c2d2ef56] {
  vertical-align: baseline !important;
}
.align-top[data-v-c2d2ef56] {
  vertical-align: top !important;
}
.align-middle[data-v-c2d2ef56] {
  vertical-align: middle !important;
}
.align-bottom[data-v-c2d2ef56] {
  vertical-align: bottom !important;
}
.align-text-bottom[data-v-c2d2ef56] {
  vertical-align: text-bottom !important;
}
.align-text-top[data-v-c2d2ef56] {
  vertical-align: text-top !important;
}
.bg-primary[data-v-c2d2ef56] {
  background-color: #007bff !important;
}
a.bg-primary[data-v-c2d2ef56]:focus,
a.bg-primary[data-v-c2d2ef56]:hover,
button.bg-primary[data-v-c2d2ef56]:focus,
button.bg-primary[data-v-c2d2ef56]:hover {
  background-color: #0062cc !important;
}
.bg-secondary[data-v-c2d2ef56] {
  background-color: #6c757d !important;
}
a.bg-secondary[data-v-c2d2ef56]:focus,
a.bg-secondary[data-v-c2d2ef56]:hover,
button.bg-secondary[data-v-c2d2ef56]:focus,
button.bg-secondary[data-v-c2d2ef56]:hover {
  background-color: #545b62 !important;
}
.bg-success[data-v-c2d2ef56] {
  background-color: #28a745 !important;
}
a.bg-success[data-v-c2d2ef56]:focus,
a.bg-success[data-v-c2d2ef56]:hover,
button.bg-success[data-v-c2d2ef56]:focus,
button.bg-success[data-v-c2d2ef56]:hover {
  background-color: #1e7e34 !important;
}
.bg-info[data-v-c2d2ef56] {
  background-color: #17a2b8 !important;
}
a.bg-info[data-v-c2d2ef56]:focus,
a.bg-info[data-v-c2d2ef56]:hover,
button.bg-info[data-v-c2d2ef56]:focus,
button.bg-info[data-v-c2d2ef56]:hover {
  background-color: #117a8b !important;
}
.bg-warning[data-v-c2d2ef56] {
  background-color: #ffc107 !important;
}
a.bg-warning[data-v-c2d2ef56]:focus,
a.bg-warning[data-v-c2d2ef56]:hover,
button.bg-warning[data-v-c2d2ef56]:focus,
button.bg-warning[data-v-c2d2ef56]:hover {
  background-color: #d39e00 !important;
}
.bg-danger[data-v-c2d2ef56] {
  background-color: #dc3545 !important;
}
a.bg-danger[data-v-c2d2ef56]:focus,
a.bg-danger[data-v-c2d2ef56]:hover,
button.bg-danger[data-v-c2d2ef56]:focus,
button.bg-danger[data-v-c2d2ef56]:hover {
  background-color: #bd2130 !important;
}
.bg-light[data-v-c2d2ef56] {
  background-color: #f8f9fa !important;
}
a.bg-light[data-v-c2d2ef56]:focus,
a.bg-light[data-v-c2d2ef56]:hover,
button.bg-light[data-v-c2d2ef56]:focus,
button.bg-light[data-v-c2d2ef56]:hover {
  background-color: #dae0e5 !important;
}
.bg-dark[data-v-c2d2ef56] {
  background-color: #343a40 !important;
}
a.bg-dark[data-v-c2d2ef56]:focus,
a.bg-dark[data-v-c2d2ef56]:hover,
button.bg-dark[data-v-c2d2ef56]:focus,
button.bg-dark[data-v-c2d2ef56]:hover {
  background-color: #1d2124 !important;
}
.bg-white[data-v-c2d2ef56] {
  background-color: #fff !important;
}
.bg-transparent[data-v-c2d2ef56] {
  background-color: transparent !important;
}
.border[data-v-c2d2ef56] {
  border: 1px solid #dee2e6 !important;
}
.border-top[data-v-c2d2ef56] {
  border-top: 1px solid #dee2e6 !important;
}
.border-right[data-v-c2d2ef56] {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom[data-v-c2d2ef56] {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left[data-v-c2d2ef56] {
  border-left: 1px solid #dee2e6 !important;
}
.border-0[data-v-c2d2ef56] {
  border: 0 !important;
}
.border-top-0[data-v-c2d2ef56] {
  border-top: 0 !important;
}
.border-right-0[data-v-c2d2ef56] {
  border-right: 0 !important;
}
.border-bottom-0[data-v-c2d2ef56] {
  border-bottom: 0 !important;
}
.border-left-0[data-v-c2d2ef56] {
  border-left: 0 !important;
}
.border-primary[data-v-c2d2ef56] {
  border-color: #007bff !important;
}
.border-secondary[data-v-c2d2ef56] {
  border-color: #6c757d !important;
}
.border-success[data-v-c2d2ef56] {
  border-color: #28a745 !important;
}
.border-info[data-v-c2d2ef56] {
  border-color: #17a2b8 !important;
}
.border-warning[data-v-c2d2ef56] {
  border-color: #ffc107 !important;
}
.border-danger[data-v-c2d2ef56] {
  border-color: #dc3545 !important;
}
.border-light[data-v-c2d2ef56] {
  border-color: #f8f9fa !important;
}
.border-dark[data-v-c2d2ef56] {
  border-color: #343a40 !important;
}
.border-white[data-v-c2d2ef56] {
  border-color: #fff !important;
}
.rounded-sm[data-v-c2d2ef56] {
  border-radius: 0.2rem !important;
}
.rounded[data-v-c2d2ef56] {
  border-radius: 0.25rem !important;
}
.rounded-top[data-v-c2d2ef56] {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right[data-v-c2d2ef56] {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom[data-v-c2d2ef56] {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left[data-v-c2d2ef56] {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg[data-v-c2d2ef56] {
  border-radius: 0.3rem !important;
}
.rounded-circle[data-v-c2d2ef56] {
  border-radius: 50% !important;
}
.rounded-pill[data-v-c2d2ef56] {
  border-radius: 50rem !important;
}
.rounded-0[data-v-c2d2ef56] {
  border-radius: 0 !important;
}
.clearfix[data-v-c2d2ef56]::after {
  display: block;
  clear: both;
  content: "";
}
.d-none[data-v-c2d2ef56] {
  display: none !important;
}
.d-inline[data-v-c2d2ef56] {
  display: inline !important;
}
.d-inline-block[data-v-c2d2ef56] {
  display: inline-block !important;
}
.d-block[data-v-c2d2ef56] {
  display: block !important;
}
.d-table[data-v-c2d2ef56] {
  display: table !important;
}
.d-table-row[data-v-c2d2ef56] {
  display: table-row !important;
}
.d-table-cell[data-v-c2d2ef56] {
  display: table-cell !important;
}
.d-flex[data-v-c2d2ef56] {
  display: flex !important;
}
.d-inline-flex[data-v-c2d2ef56] {
  display: inline-flex !important;
}
@media (min-width: 576px) {
.d-sm-none[data-v-c2d2ef56] {
    display: none !important;
}
.d-sm-inline[data-v-c2d2ef56] {
    display: inline !important;
}
.d-sm-inline-block[data-v-c2d2ef56] {
    display: inline-block !important;
}
.d-sm-block[data-v-c2d2ef56] {
    display: block !important;
}
.d-sm-table[data-v-c2d2ef56] {
    display: table !important;
}
.d-sm-table-row[data-v-c2d2ef56] {
    display: table-row !important;
}
.d-sm-table-cell[data-v-c2d2ef56] {
    display: table-cell !important;
}
.d-sm-flex[data-v-c2d2ef56] {
    display: flex !important;
}
.d-sm-inline-flex[data-v-c2d2ef56] {
    display: inline-flex !important;
}
}
@media (min-width: 768px) {
.d-md-none[data-v-c2d2ef56] {
    display: none !important;
}
.d-md-inline[data-v-c2d2ef56] {
    display: inline !important;
}
.d-md-inline-block[data-v-c2d2ef56] {
    display: inline-block !important;
}
.d-md-block[data-v-c2d2ef56] {
    display: block !important;
}
.d-md-table[data-v-c2d2ef56] {
    display: table !important;
}
.d-md-table-row[data-v-c2d2ef56] {
    display: table-row !important;
}
.d-md-table-cell[data-v-c2d2ef56] {
    display: table-cell !important;
}
.d-md-flex[data-v-c2d2ef56] {
    display: flex !important;
}
.d-md-inline-flex[data-v-c2d2ef56] {
    display: inline-flex !important;
}
}
@media (min-width: 992px) {
.d-lg-none[data-v-c2d2ef56] {
    display: none !important;
}
.d-lg-inline[data-v-c2d2ef56] {
    display: inline !important;
}
.d-lg-inline-block[data-v-c2d2ef56] {
    display: inline-block !important;
}
.d-lg-block[data-v-c2d2ef56] {
    display: block !important;
}
.d-lg-table[data-v-c2d2ef56] {
    display: table !important;
}
.d-lg-table-row[data-v-c2d2ef56] {
    display: table-row !important;
}
.d-lg-table-cell[data-v-c2d2ef56] {
    display: table-cell !important;
}
.d-lg-flex[data-v-c2d2ef56] {
    display: flex !important;
}
.d-lg-inline-flex[data-v-c2d2ef56] {
    display: inline-flex !important;
}
}
@media (min-width: 1200px) {
.d-xl-none[data-v-c2d2ef56] {
    display: none !important;
}
.d-xl-inline[data-v-c2d2ef56] {
    display: inline !important;
}
.d-xl-inline-block[data-v-c2d2ef56] {
    display: inline-block !important;
}
.d-xl-block[data-v-c2d2ef56] {
    display: block !important;
}
.d-xl-table[data-v-c2d2ef56] {
    display: table !important;
}
.d-xl-table-row[data-v-c2d2ef56] {
    display: table-row !important;
}
.d-xl-table-cell[data-v-c2d2ef56] {
    display: table-cell !important;
}
.d-xl-flex[data-v-c2d2ef56] {
    display: flex !important;
}
.d-xl-inline-flex[data-v-c2d2ef56] {
    display: inline-flex !important;
}
}
@media print {
.d-print-none[data-v-c2d2ef56] {
    display: none !important;
}
.d-print-inline[data-v-c2d2ef56] {
    display: inline !important;
}
.d-print-inline-block[data-v-c2d2ef56] {
    display: inline-block !important;
}
.d-print-block[data-v-c2d2ef56] {
    display: block !important;
}
.d-print-table[data-v-c2d2ef56] {
    display: table !important;
}
.d-print-table-row[data-v-c2d2ef56] {
    display: table-row !important;
}
.d-print-table-cell[data-v-c2d2ef56] {
    display: table-cell !important;
}
.d-print-flex[data-v-c2d2ef56] {
    display: flex !important;
}
.d-print-inline-flex[data-v-c2d2ef56] {
    display: inline-flex !important;
}
}
.embed-responsive[data-v-c2d2ef56] {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive[data-v-c2d2ef56]::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item[data-v-c2d2ef56],
.embed-responsive embed[data-v-c2d2ef56],
.embed-responsive iframe[data-v-c2d2ef56],
.embed-responsive object[data-v-c2d2ef56],
.embed-responsive video[data-v-c2d2ef56] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9[data-v-c2d2ef56]::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9[data-v-c2d2ef56]::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3[data-v-c2d2ef56]::before {
  padding-top: 75%;
}
.embed-responsive-1by1[data-v-c2d2ef56]::before {
  padding-top: 100%;
}
.flex-row[data-v-c2d2ef56] {
  flex-direction: row !important;
}
.flex-column[data-v-c2d2ef56] {
  flex-direction: column !important;
}
.flex-row-reverse[data-v-c2d2ef56] {
  flex-direction: row-reverse !important;
}
.flex-column-reverse[data-v-c2d2ef56] {
  flex-direction: column-reverse !important;
}
.flex-wrap[data-v-c2d2ef56] {
  flex-wrap: wrap !important;
}
.flex-nowrap[data-v-c2d2ef56] {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse[data-v-c2d2ef56] {
  flex-wrap: wrap-reverse !important;
}
.flex-fill[data-v-c2d2ef56] {
  flex: 1 1 auto !important;
}
.flex-grow-0[data-v-c2d2ef56] {
  flex-grow: 0 !important;
}
.flex-grow-1[data-v-c2d2ef56] {
  flex-grow: 1 !important;
}
.flex-shrink-0[data-v-c2d2ef56] {
  flex-shrink: 0 !important;
}
.flex-shrink-1[data-v-c2d2ef56] {
  flex-shrink: 1 !important;
}
.justify-content-start[data-v-c2d2ef56] {
  justify-content: flex-start !important;
}
.justify-content-end[data-v-c2d2ef56] {
  justify-content: flex-end !important;
}
.justify-content-center[data-v-c2d2ef56] {
  justify-content: center !important;
}
.justify-content-between[data-v-c2d2ef56] {
  justify-content: space-between !important;
}
.justify-content-around[data-v-c2d2ef56] {
  justify-content: space-around !important;
}
.align-items-start[data-v-c2d2ef56] {
  align-items: flex-start !important;
}
.align-items-end[data-v-c2d2ef56] {
  align-items: flex-end !important;
}
.align-items-center[data-v-c2d2ef56] {
  align-items: center !important;
}
.align-items-baseline[data-v-c2d2ef56] {
  align-items: baseline !important;
}
.align-items-stretch[data-v-c2d2ef56] {
  align-items: stretch !important;
}
.align-content-start[data-v-c2d2ef56] {
  align-content: flex-start !important;
}
.align-content-end[data-v-c2d2ef56] {
  align-content: flex-end !important;
}
.align-content-center[data-v-c2d2ef56] {
  align-content: center !important;
}
.align-content-between[data-v-c2d2ef56] {
  align-content: space-between !important;
}
.align-content-around[data-v-c2d2ef56] {
  align-content: space-around !important;
}
.align-content-stretch[data-v-c2d2ef56] {
  align-content: stretch !important;
}
.align-self-auto[data-v-c2d2ef56] {
  align-self: auto !important;
}
.align-self-start[data-v-c2d2ef56] {
  align-self: flex-start !important;
}
.align-self-end[data-v-c2d2ef56] {
  align-self: flex-end !important;
}
.align-self-center[data-v-c2d2ef56] {
  align-self: center !important;
}
.align-self-baseline[data-v-c2d2ef56] {
  align-self: baseline !important;
}
.align-self-stretch[data-v-c2d2ef56] {
  align-self: stretch !important;
}
@media (min-width: 576px) {
.flex-sm-row[data-v-c2d2ef56] {
    flex-direction: row !important;
}
.flex-sm-column[data-v-c2d2ef56] {
    flex-direction: column !important;
}
.flex-sm-row-reverse[data-v-c2d2ef56] {
    flex-direction: row-reverse !important;
}
.flex-sm-column-reverse[data-v-c2d2ef56] {
    flex-direction: column-reverse !important;
}
.flex-sm-wrap[data-v-c2d2ef56] {
    flex-wrap: wrap !important;
}
.flex-sm-nowrap[data-v-c2d2ef56] {
    flex-wrap: nowrap !important;
}
.flex-sm-wrap-reverse[data-v-c2d2ef56] {
    flex-wrap: wrap-reverse !important;
}
.flex-sm-fill[data-v-c2d2ef56] {
    flex: 1 1 auto !important;
}
.flex-sm-grow-0[data-v-c2d2ef56] {
    flex-grow: 0 !important;
}
.flex-sm-grow-1[data-v-c2d2ef56] {
    flex-grow: 1 !important;
}
.flex-sm-shrink-0[data-v-c2d2ef56] {
    flex-shrink: 0 !important;
}
.flex-sm-shrink-1[data-v-c2d2ef56] {
    flex-shrink: 1 !important;
}
.justify-content-sm-start[data-v-c2d2ef56] {
    justify-content: flex-start !important;
}
.justify-content-sm-end[data-v-c2d2ef56] {
    justify-content: flex-end !important;
}
.justify-content-sm-center[data-v-c2d2ef56] {
    justify-content: center !important;
}
.justify-content-sm-between[data-v-c2d2ef56] {
    justify-content: space-between !important;
}
.justify-content-sm-around[data-v-c2d2ef56] {
    justify-content: space-around !important;
}
.align-items-sm-start[data-v-c2d2ef56] {
    align-items: flex-start !important;
}
.align-items-sm-end[data-v-c2d2ef56] {
    align-items: flex-end !important;
}
.align-items-sm-center[data-v-c2d2ef56] {
    align-items: center !important;
}
.align-items-sm-baseline[data-v-c2d2ef56] {
    align-items: baseline !important;
}
.align-items-sm-stretch[data-v-c2d2ef56] {
    align-items: stretch !important;
}
.align-content-sm-start[data-v-c2d2ef56] {
    align-content: flex-start !important;
}
.align-content-sm-end[data-v-c2d2ef56] {
    align-content: flex-end !important;
}
.align-content-sm-center[data-v-c2d2ef56] {
    align-content: center !important;
}
.align-content-sm-between[data-v-c2d2ef56] {
    align-content: space-between !important;
}
.align-content-sm-around[data-v-c2d2ef56] {
    align-content: space-around !important;
}
.align-content-sm-stretch[data-v-c2d2ef56] {
    align-content: stretch !important;
}
.align-self-sm-auto[data-v-c2d2ef56] {
    align-self: auto !important;
}
.align-self-sm-start[data-v-c2d2ef56] {
    align-self: flex-start !important;
}
.align-self-sm-end[data-v-c2d2ef56] {
    align-self: flex-end !important;
}
.align-self-sm-center[data-v-c2d2ef56] {
    align-self: center !important;
}
.align-self-sm-baseline[data-v-c2d2ef56] {
    align-self: baseline !important;
}
.align-self-sm-stretch[data-v-c2d2ef56] {
    align-self: stretch !important;
}
}
@media (min-width: 768px) {
.flex-md-row[data-v-c2d2ef56] {
    flex-direction: row !important;
}
.flex-md-column[data-v-c2d2ef56] {
    flex-direction: column !important;
}
.flex-md-row-reverse[data-v-c2d2ef56] {
    flex-direction: row-reverse !important;
}
.flex-md-column-reverse[data-v-c2d2ef56] {
    flex-direction: column-reverse !important;
}
.flex-md-wrap[data-v-c2d2ef56] {
    flex-wrap: wrap !important;
}
.flex-md-nowrap[data-v-c2d2ef56] {
    flex-wrap: nowrap !important;
}
.flex-md-wrap-reverse[data-v-c2d2ef56] {
    flex-wrap: wrap-reverse !important;
}
.flex-md-fill[data-v-c2d2ef56] {
    flex: 1 1 auto !important;
}
.flex-md-grow-0[data-v-c2d2ef56] {
    flex-grow: 0 !important;
}
.flex-md-grow-1[data-v-c2d2ef56] {
    flex-grow: 1 !important;
}
.flex-md-shrink-0[data-v-c2d2ef56] {
    flex-shrink: 0 !important;
}
.flex-md-shrink-1[data-v-c2d2ef56] {
    flex-shrink: 1 !important;
}
.justify-content-md-start[data-v-c2d2ef56] {
    justify-content: flex-start !important;
}
.justify-content-md-end[data-v-c2d2ef56] {
    justify-content: flex-end !important;
}
.justify-content-md-center[data-v-c2d2ef56] {
    justify-content: center !important;
}
.justify-content-md-between[data-v-c2d2ef56] {
    justify-content: space-between !important;
}
.justify-content-md-around[data-v-c2d2ef56] {
    justify-content: space-around !important;
}
.align-items-md-start[data-v-c2d2ef56] {
    align-items: flex-start !important;
}
.align-items-md-end[data-v-c2d2ef56] {
    align-items: flex-end !important;
}
.align-items-md-center[data-v-c2d2ef56] {
    align-items: center !important;
}
.align-items-md-baseline[data-v-c2d2ef56] {
    align-items: baseline !important;
}
.align-items-md-stretch[data-v-c2d2ef56] {
    align-items: stretch !important;
}
.align-content-md-start[data-v-c2d2ef56] {
    align-content: flex-start !important;
}
.align-content-md-end[data-v-c2d2ef56] {
    align-content: flex-end !important;
}
.align-content-md-center[data-v-c2d2ef56] {
    align-content: center !important;
}
.align-content-md-between[data-v-c2d2ef56] {
    align-content: space-between !important;
}
.align-content-md-around[data-v-c2d2ef56] {
    align-content: space-around !important;
}
.align-content-md-stretch[data-v-c2d2ef56] {
    align-content: stretch !important;
}
.align-self-md-auto[data-v-c2d2ef56] {
    align-self: auto !important;
}
.align-self-md-start[data-v-c2d2ef56] {
    align-self: flex-start !important;
}
.align-self-md-end[data-v-c2d2ef56] {
    align-self: flex-end !important;
}
.align-self-md-center[data-v-c2d2ef56] {
    align-self: center !important;
}
.align-self-md-baseline[data-v-c2d2ef56] {
    align-self: baseline !important;
}
.align-self-md-stretch[data-v-c2d2ef56] {
    align-self: stretch !important;
}
}
@media (min-width: 992px) {
.flex-lg-row[data-v-c2d2ef56] {
    flex-direction: row !important;
}
.flex-lg-column[data-v-c2d2ef56] {
    flex-direction: column !important;
}
.flex-lg-row-reverse[data-v-c2d2ef56] {
    flex-direction: row-reverse !important;
}
.flex-lg-column-reverse[data-v-c2d2ef56] {
    flex-direction: column-reverse !important;
}
.flex-lg-wrap[data-v-c2d2ef56] {
    flex-wrap: wrap !important;
}
.flex-lg-nowrap[data-v-c2d2ef56] {
    flex-wrap: nowrap !important;
}
.flex-lg-wrap-reverse[data-v-c2d2ef56] {
    flex-wrap: wrap-reverse !important;
}
.flex-lg-fill[data-v-c2d2ef56] {
    flex: 1 1 auto !important;
}
.flex-lg-grow-0[data-v-c2d2ef56] {
    flex-grow: 0 !important;
}
.flex-lg-grow-1[data-v-c2d2ef56] {
    flex-grow: 1 !important;
}
.flex-lg-shrink-0[data-v-c2d2ef56] {
    flex-shrink: 0 !important;
}
.flex-lg-shrink-1[data-v-c2d2ef56] {
    flex-shrink: 1 !important;
}
.justify-content-lg-start[data-v-c2d2ef56] {
    justify-content: flex-start !important;
}
.justify-content-lg-end[data-v-c2d2ef56] {
    justify-content: flex-end !important;
}
.justify-content-lg-center[data-v-c2d2ef56] {
    justify-content: center !important;
}
.justify-content-lg-between[data-v-c2d2ef56] {
    justify-content: space-between !important;
}
.justify-content-lg-around[data-v-c2d2ef56] {
    justify-content: space-around !important;
}
.align-items-lg-start[data-v-c2d2ef56] {
    align-items: flex-start !important;
}
.align-items-lg-end[data-v-c2d2ef56] {
    align-items: flex-end !important;
}
.align-items-lg-center[data-v-c2d2ef56] {
    align-items: center !important;
}
.align-items-lg-baseline[data-v-c2d2ef56] {
    align-items: baseline !important;
}
.align-items-lg-stretch[data-v-c2d2ef56] {
    align-items: stretch !important;
}
.align-content-lg-start[data-v-c2d2ef56] {
    align-content: flex-start !important;
}
.align-content-lg-end[data-v-c2d2ef56] {
    align-content: flex-end !important;
}
.align-content-lg-center[data-v-c2d2ef56] {
    align-content: center !important;
}
.align-content-lg-between[data-v-c2d2ef56] {
    align-content: space-between !important;
}
.align-content-lg-around[data-v-c2d2ef56] {
    align-content: space-around !important;
}
.align-content-lg-stretch[data-v-c2d2ef56] {
    align-content: stretch !important;
}
.align-self-lg-auto[data-v-c2d2ef56] {
    align-self: auto !important;
}
.align-self-lg-start[data-v-c2d2ef56] {
    align-self: flex-start !important;
}
.align-self-lg-end[data-v-c2d2ef56] {
    align-self: flex-end !important;
}
.align-self-lg-center[data-v-c2d2ef56] {
    align-self: center !important;
}
.align-self-lg-baseline[data-v-c2d2ef56] {
    align-self: baseline !important;
}
.align-self-lg-stretch[data-v-c2d2ef56] {
    align-self: stretch !important;
}
}
@media (min-width: 1200px) {
.flex-xl-row[data-v-c2d2ef56] {
    flex-direction: row !important;
}
.flex-xl-column[data-v-c2d2ef56] {
    flex-direction: column !important;
}
.flex-xl-row-reverse[data-v-c2d2ef56] {
    flex-direction: row-reverse !important;
}
.flex-xl-column-reverse[data-v-c2d2ef56] {
    flex-direction: column-reverse !important;
}
.flex-xl-wrap[data-v-c2d2ef56] {
    flex-wrap: wrap !important;
}
.flex-xl-nowrap[data-v-c2d2ef56] {
    flex-wrap: nowrap !important;
}
.flex-xl-wrap-reverse[data-v-c2d2ef56] {
    flex-wrap: wrap-reverse !important;
}
.flex-xl-fill[data-v-c2d2ef56] {
    flex: 1 1 auto !important;
}
.flex-xl-grow-0[data-v-c2d2ef56] {
    flex-grow: 0 !important;
}
.flex-xl-grow-1[data-v-c2d2ef56] {
    flex-grow: 1 !important;
}
.flex-xl-shrink-0[data-v-c2d2ef56] {
    flex-shrink: 0 !important;
}
.flex-xl-shrink-1[data-v-c2d2ef56] {
    flex-shrink: 1 !important;
}
.justify-content-xl-start[data-v-c2d2ef56] {
    justify-content: flex-start !important;
}
.justify-content-xl-end[data-v-c2d2ef56] {
    justify-content: flex-end !important;
}
.justify-content-xl-center[data-v-c2d2ef56] {
    justify-content: center !important;
}
.justify-content-xl-between[data-v-c2d2ef56] {
    justify-content: space-between !important;
}
.justify-content-xl-around[data-v-c2d2ef56] {
    justify-content: space-around !important;
}
.align-items-xl-start[data-v-c2d2ef56] {
    align-items: flex-start !important;
}
.align-items-xl-end[data-v-c2d2ef56] {
    align-items: flex-end !important;
}
.align-items-xl-center[data-v-c2d2ef56] {
    align-items: center !important;
}
.align-items-xl-baseline[data-v-c2d2ef56] {
    align-items: baseline !important;
}
.align-items-xl-stretch[data-v-c2d2ef56] {
    align-items: stretch !important;
}
.align-content-xl-start[data-v-c2d2ef56] {
    align-content: flex-start !important;
}
.align-content-xl-end[data-v-c2d2ef56] {
    align-content: flex-end !important;
}
.align-content-xl-center[data-v-c2d2ef56] {
    align-content: center !important;
}
.align-content-xl-between[data-v-c2d2ef56] {
    align-content: space-between !important;
}
.align-content-xl-around[data-v-c2d2ef56] {
    align-content: space-around !important;
}
.align-content-xl-stretch[data-v-c2d2ef56] {
    align-content: stretch !important;
}
.align-self-xl-auto[data-v-c2d2ef56] {
    align-self: auto !important;
}
.align-self-xl-start[data-v-c2d2ef56] {
    align-self: flex-start !important;
}
.align-self-xl-end[data-v-c2d2ef56] {
    align-self: flex-end !important;
}
.align-self-xl-center[data-v-c2d2ef56] {
    align-self: center !important;
}
.align-self-xl-baseline[data-v-c2d2ef56] {
    align-self: baseline !important;
}
.align-self-xl-stretch[data-v-c2d2ef56] {
    align-self: stretch !important;
}
}
.float-left[data-v-c2d2ef56] {
  float: left !important;
}
.float-right[data-v-c2d2ef56] {
  float: right !important;
}
.float-none[data-v-c2d2ef56] {
  float: none !important;
}
@media (min-width: 576px) {
.float-sm-left[data-v-c2d2ef56] {
    float: left !important;
}
.float-sm-right[data-v-c2d2ef56] {
    float: right !important;
}
.float-sm-none[data-v-c2d2ef56] {
    float: none !important;
}
}
@media (min-width: 768px) {
.float-md-left[data-v-c2d2ef56] {
    float: left !important;
}
.float-md-right[data-v-c2d2ef56] {
    float: right !important;
}
.float-md-none[data-v-c2d2ef56] {
    float: none !important;
}
}
@media (min-width: 992px) {
.float-lg-left[data-v-c2d2ef56] {
    float: left !important;
}
.float-lg-right[data-v-c2d2ef56] {
    float: right !important;
}
.float-lg-none[data-v-c2d2ef56] {
    float: none !important;
}
}
@media (min-width: 1200px) {
.float-xl-left[data-v-c2d2ef56] {
    float: left !important;
}
.float-xl-right[data-v-c2d2ef56] {
    float: right !important;
}
.float-xl-none[data-v-c2d2ef56] {
    float: none !important;
}
}
.overflow-auto[data-v-c2d2ef56] {
  overflow: auto !important;
}
.overflow-hidden[data-v-c2d2ef56] {
  overflow: hidden !important;
}
.position-static[data-v-c2d2ef56] {
  position: static !important;
}
.position-relative[data-v-c2d2ef56] {
  position: relative !important;
}
.position-absolute[data-v-c2d2ef56] {
  position: absolute !important;
}
.position-fixed[data-v-c2d2ef56] {
  position: fixed !important;
}
.position-sticky[data-v-c2d2ef56] {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top[data-v-c2d2ef56] {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom[data-v-c2d2ef56] {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
.sticky-top[data-v-c2d2ef56] {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
}
}
.sr-only[data-v-c2d2ef56] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable[data-v-c2d2ef56]:active,
.sr-only-focusable[data-v-c2d2ef56]:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm[data-v-c2d2ef56] {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow[data-v-c2d2ef56] {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg[data-v-c2d2ef56] {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none[data-v-c2d2ef56] {
  box-shadow: none !important;
}
.w-25[data-v-c2d2ef56] {
  width: 25% !important;
}
.w-50[data-v-c2d2ef56] {
  width: 50% !important;
}
.w-75[data-v-c2d2ef56] {
  width: 75% !important;
}
.w-100[data-v-c2d2ef56] {
  width: 100% !important;
}
.w-auto[data-v-c2d2ef56] {
  width: auto !important;
}
.h-25[data-v-c2d2ef56] {
  height: 25% !important;
}
.h-50[data-v-c2d2ef56] {
  height: 50% !important;
}
.h-75[data-v-c2d2ef56] {
  height: 75% !important;
}
.h-100[data-v-c2d2ef56] {
  height: 100% !important;
}
.h-auto[data-v-c2d2ef56] {
  height: auto !important;
}
.mw-100[data-v-c2d2ef56] {
  max-width: 100% !important;
}
.mh-100[data-v-c2d2ef56] {
  max-height: 100% !important;
}
.min-vw-100[data-v-c2d2ef56] {
  min-width: 100vw !important;
}
.min-vh-100[data-v-c2d2ef56] {
  min-height: 100vh !important;
}
.vw-100[data-v-c2d2ef56] {
  width: 100vw !important;
}
.vh-100[data-v-c2d2ef56] {
  height: 100vh !important;
}
.stretched-link[data-v-c2d2ef56]::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.m-0[data-v-c2d2ef56] {
  margin: 0 !important;
}
.mt-0[data-v-c2d2ef56],
.my-0[data-v-c2d2ef56] {
  margin-top: 0 !important;
}
.mr-0[data-v-c2d2ef56],
.mx-0[data-v-c2d2ef56] {
  margin-right: 0 !important;
}
.mb-0[data-v-c2d2ef56],
.my-0[data-v-c2d2ef56] {
  margin-bottom: 0 !important;
}
.ml-0[data-v-c2d2ef56],
.mx-0[data-v-c2d2ef56] {
  margin-left: 0 !important;
}
.m-1[data-v-c2d2ef56] {
  margin: 0.25rem !important;
}
.mt-1[data-v-c2d2ef56],
.my-1[data-v-c2d2ef56] {
  margin-top: 0.25rem !important;
}
.mr-1[data-v-c2d2ef56],
.mx-1[data-v-c2d2ef56] {
  margin-right: 0.25rem !important;
}
.mb-1[data-v-c2d2ef56],
.my-1[data-v-c2d2ef56] {
  margin-bottom: 0.25rem !important;
}
.ml-1[data-v-c2d2ef56],
.mx-1[data-v-c2d2ef56] {
  margin-left: 0.25rem !important;
}
.m-2[data-v-c2d2ef56] {
  margin: 0.5rem !important;
}
.mt-2[data-v-c2d2ef56],
.my-2[data-v-c2d2ef56] {
  margin-top: 0.5rem !important;
}
.mr-2[data-v-c2d2ef56],
.mx-2[data-v-c2d2ef56] {
  margin-right: 0.5rem !important;
}
.mb-2[data-v-c2d2ef56],
.my-2[data-v-c2d2ef56] {
  margin-bottom: 0.5rem !important;
}
.ml-2[data-v-c2d2ef56],
.mx-2[data-v-c2d2ef56] {
  margin-left: 0.5rem !important;
}
.m-3[data-v-c2d2ef56] {
  margin: 1rem !important;
}
.mt-3[data-v-c2d2ef56],
.my-3[data-v-c2d2ef56] {
  margin-top: 1rem !important;
}
.mr-3[data-v-c2d2ef56],
.mx-3[data-v-c2d2ef56] {
  margin-right: 1rem !important;
}
.mb-3[data-v-c2d2ef56],
.my-3[data-v-c2d2ef56] {
  margin-bottom: 1rem !important;
}
.ml-3[data-v-c2d2ef56],
.mx-3[data-v-c2d2ef56] {
  margin-left: 1rem !important;
}
.m-4[data-v-c2d2ef56] {
  margin: 1.5rem !important;
}
.mt-4[data-v-c2d2ef56],
.my-4[data-v-c2d2ef56] {
  margin-top: 1.5rem !important;
}
.mr-4[data-v-c2d2ef56],
.mx-4[data-v-c2d2ef56] {
  margin-right: 1.5rem !important;
}
.mb-4[data-v-c2d2ef56],
.my-4[data-v-c2d2ef56] {
  margin-bottom: 1.5rem !important;
}
.ml-4[data-v-c2d2ef56],
.mx-4[data-v-c2d2ef56] {
  margin-left: 1.5rem !important;
}
.m-5[data-v-c2d2ef56] {
  margin: 3rem !important;
}
.mt-5[data-v-c2d2ef56],
.my-5[data-v-c2d2ef56] {
  margin-top: 3rem !important;
}
.mr-5[data-v-c2d2ef56],
.mx-5[data-v-c2d2ef56] {
  margin-right: 3rem !important;
}
.mb-5[data-v-c2d2ef56],
.my-5[data-v-c2d2ef56] {
  margin-bottom: 3rem !important;
}
.ml-5[data-v-c2d2ef56],
.mx-5[data-v-c2d2ef56] {
  margin-left: 3rem !important;
}
.p-0[data-v-c2d2ef56] {
  padding: 0 !important;
}
.pt-0[data-v-c2d2ef56],
.py-0[data-v-c2d2ef56] {
  padding-top: 0 !important;
}
.pr-0[data-v-c2d2ef56],
.px-0[data-v-c2d2ef56] {
  padding-right: 0 !important;
}
.pb-0[data-v-c2d2ef56],
.py-0[data-v-c2d2ef56] {
  padding-bottom: 0 !important;
}
.pl-0[data-v-c2d2ef56],
.px-0[data-v-c2d2ef56] {
  padding-left: 0 !important;
}
.p-1[data-v-c2d2ef56] {
  padding: 0.25rem !important;
}
.pt-1[data-v-c2d2ef56],
.py-1[data-v-c2d2ef56] {
  padding-top: 0.25rem !important;
}
.pr-1[data-v-c2d2ef56],
.px-1[data-v-c2d2ef56] {
  padding-right: 0.25rem !important;
}
.pb-1[data-v-c2d2ef56],
.py-1[data-v-c2d2ef56] {
  padding-bottom: 0.25rem !important;
}
.pl-1[data-v-c2d2ef56],
.px-1[data-v-c2d2ef56] {
  padding-left: 0.25rem !important;
}
.p-2[data-v-c2d2ef56] {
  padding: 0.5rem !important;
}
.pt-2[data-v-c2d2ef56],
.py-2[data-v-c2d2ef56] {
  padding-top: 0.5rem !important;
}
.pr-2[data-v-c2d2ef56],
.px-2[data-v-c2d2ef56] {
  padding-right: 0.5rem !important;
}
.pb-2[data-v-c2d2ef56],
.py-2[data-v-c2d2ef56] {
  padding-bottom: 0.5rem !important;
}
.pl-2[data-v-c2d2ef56],
.px-2[data-v-c2d2ef56] {
  padding-left: 0.5rem !important;
}
.p-3[data-v-c2d2ef56] {
  padding: 1rem !important;
}
.pt-3[data-v-c2d2ef56],
.py-3[data-v-c2d2ef56] {
  padding-top: 1rem !important;
}
.pr-3[data-v-c2d2ef56],
.px-3[data-v-c2d2ef56] {
  padding-right: 1rem !important;
}
.pb-3[data-v-c2d2ef56],
.py-3[data-v-c2d2ef56] {
  padding-bottom: 1rem !important;
}
.pl-3[data-v-c2d2ef56],
.px-3[data-v-c2d2ef56] {
  padding-left: 1rem !important;
}
.p-4[data-v-c2d2ef56] {
  padding: 1.5rem !important;
}
.pt-4[data-v-c2d2ef56],
.py-4[data-v-c2d2ef56] {
  padding-top: 1.5rem !important;
}
.pr-4[data-v-c2d2ef56],
.px-4[data-v-c2d2ef56] {
  padding-right: 1.5rem !important;
}
.pb-4[data-v-c2d2ef56],
.py-4[data-v-c2d2ef56] {
  padding-bottom: 1.5rem !important;
}
.pl-4[data-v-c2d2ef56],
.px-4[data-v-c2d2ef56] {
  padding-left: 1.5rem !important;
}
.p-5[data-v-c2d2ef56] {
  padding: 3rem !important;
}
.pt-5[data-v-c2d2ef56],
.py-5[data-v-c2d2ef56] {
  padding-top: 3rem !important;
}
.pr-5[data-v-c2d2ef56],
.px-5[data-v-c2d2ef56] {
  padding-right: 3rem !important;
}
.pb-5[data-v-c2d2ef56],
.py-5[data-v-c2d2ef56] {
  padding-bottom: 3rem !important;
}
.pl-5[data-v-c2d2ef56],
.px-5[data-v-c2d2ef56] {
  padding-left: 3rem !important;
}
.m-n1[data-v-c2d2ef56] {
  margin: -0.25rem !important;
}
.mt-n1[data-v-c2d2ef56],
.my-n1[data-v-c2d2ef56] {
  margin-top: -0.25rem !important;
}
.mr-n1[data-v-c2d2ef56],
.mx-n1[data-v-c2d2ef56] {
  margin-right: -0.25rem !important;
}
.mb-n1[data-v-c2d2ef56],
.my-n1[data-v-c2d2ef56] {
  margin-bottom: -0.25rem !important;
}
.ml-n1[data-v-c2d2ef56],
.mx-n1[data-v-c2d2ef56] {
  margin-left: -0.25rem !important;
}
.m-n2[data-v-c2d2ef56] {
  margin: -0.5rem !important;
}
.mt-n2[data-v-c2d2ef56],
.my-n2[data-v-c2d2ef56] {
  margin-top: -0.5rem !important;
}
.mr-n2[data-v-c2d2ef56],
.mx-n2[data-v-c2d2ef56] {
  margin-right: -0.5rem !important;
}
.mb-n2[data-v-c2d2ef56],
.my-n2[data-v-c2d2ef56] {
  margin-bottom: -0.5rem !important;
}
.ml-n2[data-v-c2d2ef56],
.mx-n2[data-v-c2d2ef56] {
  margin-left: -0.5rem !important;
}
.m-n3[data-v-c2d2ef56] {
  margin: -1rem !important;
}
.mt-n3[data-v-c2d2ef56],
.my-n3[data-v-c2d2ef56] {
  margin-top: -1rem !important;
}
.mr-n3[data-v-c2d2ef56],
.mx-n3[data-v-c2d2ef56] {
  margin-right: -1rem !important;
}
.mb-n3[data-v-c2d2ef56],
.my-n3[data-v-c2d2ef56] {
  margin-bottom: -1rem !important;
}
.ml-n3[data-v-c2d2ef56],
.mx-n3[data-v-c2d2ef56] {
  margin-left: -1rem !important;
}
.m-n4[data-v-c2d2ef56] {
  margin: -1.5rem !important;
}
.mt-n4[data-v-c2d2ef56],
.my-n4[data-v-c2d2ef56] {
  margin-top: -1.5rem !important;
}
.mr-n4[data-v-c2d2ef56],
.mx-n4[data-v-c2d2ef56] {
  margin-right: -1.5rem !important;
}
.mb-n4[data-v-c2d2ef56],
.my-n4[data-v-c2d2ef56] {
  margin-bottom: -1.5rem !important;
}
.ml-n4[data-v-c2d2ef56],
.mx-n4[data-v-c2d2ef56] {
  margin-left: -1.5rem !important;
}
.m-n5[data-v-c2d2ef56] {
  margin: -3rem !important;
}
.mt-n5[data-v-c2d2ef56],
.my-n5[data-v-c2d2ef56] {
  margin-top: -3rem !important;
}
.mr-n5[data-v-c2d2ef56],
.mx-n5[data-v-c2d2ef56] {
  margin-right: -3rem !important;
}
.mb-n5[data-v-c2d2ef56],
.my-n5[data-v-c2d2ef56] {
  margin-bottom: -3rem !important;
}
.ml-n5[data-v-c2d2ef56],
.mx-n5[data-v-c2d2ef56] {
  margin-left: -3rem !important;
}
.m-auto[data-v-c2d2ef56] {
  margin: auto !important;
}
.mt-auto[data-v-c2d2ef56],
.my-auto[data-v-c2d2ef56] {
  margin-top: auto !important;
}
.mr-auto[data-v-c2d2ef56],
.mx-auto[data-v-c2d2ef56] {
  margin-right: auto !important;
}
.mb-auto[data-v-c2d2ef56],
.my-auto[data-v-c2d2ef56] {
  margin-bottom: auto !important;
}
.ml-auto[data-v-c2d2ef56],
.mx-auto[data-v-c2d2ef56] {
  margin-left: auto !important;
}
@media (min-width: 576px) {
.m-sm-0[data-v-c2d2ef56] {
    margin: 0 !important;
}
.mt-sm-0[data-v-c2d2ef56],
  .my-sm-0[data-v-c2d2ef56] {
    margin-top: 0 !important;
}
.mr-sm-0[data-v-c2d2ef56],
  .mx-sm-0[data-v-c2d2ef56] {
    margin-right: 0 !important;
}
.mb-sm-0[data-v-c2d2ef56],
  .my-sm-0[data-v-c2d2ef56] {
    margin-bottom: 0 !important;
}
.ml-sm-0[data-v-c2d2ef56],
  .mx-sm-0[data-v-c2d2ef56] {
    margin-left: 0 !important;
}
.m-sm-1[data-v-c2d2ef56] {
    margin: 0.25rem !important;
}
.mt-sm-1[data-v-c2d2ef56],
  .my-sm-1[data-v-c2d2ef56] {
    margin-top: 0.25rem !important;
}
.mr-sm-1[data-v-c2d2ef56],
  .mx-sm-1[data-v-c2d2ef56] {
    margin-right: 0.25rem !important;
}
.mb-sm-1[data-v-c2d2ef56],
  .my-sm-1[data-v-c2d2ef56] {
    margin-bottom: 0.25rem !important;
}
.ml-sm-1[data-v-c2d2ef56],
  .mx-sm-1[data-v-c2d2ef56] {
    margin-left: 0.25rem !important;
}
.m-sm-2[data-v-c2d2ef56] {
    margin: 0.5rem !important;
}
.mt-sm-2[data-v-c2d2ef56],
  .my-sm-2[data-v-c2d2ef56] {
    margin-top: 0.5rem !important;
}
.mr-sm-2[data-v-c2d2ef56],
  .mx-sm-2[data-v-c2d2ef56] {
    margin-right: 0.5rem !important;
}
.mb-sm-2[data-v-c2d2ef56],
  .my-sm-2[data-v-c2d2ef56] {
    margin-bottom: 0.5rem !important;
}
.ml-sm-2[data-v-c2d2ef56],
  .mx-sm-2[data-v-c2d2ef56] {
    margin-left: 0.5rem !important;
}
.m-sm-3[data-v-c2d2ef56] {
    margin: 1rem !important;
}
.mt-sm-3[data-v-c2d2ef56],
  .my-sm-3[data-v-c2d2ef56] {
    margin-top: 1rem !important;
}
.mr-sm-3[data-v-c2d2ef56],
  .mx-sm-3[data-v-c2d2ef56] {
    margin-right: 1rem !important;
}
.mb-sm-3[data-v-c2d2ef56],
  .my-sm-3[data-v-c2d2ef56] {
    margin-bottom: 1rem !important;
}
.ml-sm-3[data-v-c2d2ef56],
  .mx-sm-3[data-v-c2d2ef56] {
    margin-left: 1rem !important;
}
.m-sm-4[data-v-c2d2ef56] {
    margin: 1.5rem !important;
}
.mt-sm-4[data-v-c2d2ef56],
  .my-sm-4[data-v-c2d2ef56] {
    margin-top: 1.5rem !important;
}
.mr-sm-4[data-v-c2d2ef56],
  .mx-sm-4[data-v-c2d2ef56] {
    margin-right: 1.5rem !important;
}
.mb-sm-4[data-v-c2d2ef56],
  .my-sm-4[data-v-c2d2ef56] {
    margin-bottom: 1.5rem !important;
}
.ml-sm-4[data-v-c2d2ef56],
  .mx-sm-4[data-v-c2d2ef56] {
    margin-left: 1.5rem !important;
}
.m-sm-5[data-v-c2d2ef56] {
    margin: 3rem !important;
}
.mt-sm-5[data-v-c2d2ef56],
  .my-sm-5[data-v-c2d2ef56] {
    margin-top: 3rem !important;
}
.mr-sm-5[data-v-c2d2ef56],
  .mx-sm-5[data-v-c2d2ef56] {
    margin-right: 3rem !important;
}
.mb-sm-5[data-v-c2d2ef56],
  .my-sm-5[data-v-c2d2ef56] {
    margin-bottom: 3rem !important;
}
.ml-sm-5[data-v-c2d2ef56],
  .mx-sm-5[data-v-c2d2ef56] {
    margin-left: 3rem !important;
}
.p-sm-0[data-v-c2d2ef56] {
    padding: 0 !important;
}
.pt-sm-0[data-v-c2d2ef56],
  .py-sm-0[data-v-c2d2ef56] {
    padding-top: 0 !important;
}
.pr-sm-0[data-v-c2d2ef56],
  .px-sm-0[data-v-c2d2ef56] {
    padding-right: 0 !important;
}
.pb-sm-0[data-v-c2d2ef56],
  .py-sm-0[data-v-c2d2ef56] {
    padding-bottom: 0 !important;
}
.pl-sm-0[data-v-c2d2ef56],
  .px-sm-0[data-v-c2d2ef56] {
    padding-left: 0 !important;
}
.p-sm-1[data-v-c2d2ef56] {
    padding: 0.25rem !important;
}
.pt-sm-1[data-v-c2d2ef56],
  .py-sm-1[data-v-c2d2ef56] {
    padding-top: 0.25rem !important;
}
.pr-sm-1[data-v-c2d2ef56],
  .px-sm-1[data-v-c2d2ef56] {
    padding-right: 0.25rem !important;
}
.pb-sm-1[data-v-c2d2ef56],
  .py-sm-1[data-v-c2d2ef56] {
    padding-bottom: 0.25rem !important;
}
.pl-sm-1[data-v-c2d2ef56],
  .px-sm-1[data-v-c2d2ef56] {
    padding-left: 0.25rem !important;
}
.p-sm-2[data-v-c2d2ef56] {
    padding: 0.5rem !important;
}
.pt-sm-2[data-v-c2d2ef56],
  .py-sm-2[data-v-c2d2ef56] {
    padding-top: 0.5rem !important;
}
.pr-sm-2[data-v-c2d2ef56],
  .px-sm-2[data-v-c2d2ef56] {
    padding-right: 0.5rem !important;
}
.pb-sm-2[data-v-c2d2ef56],
  .py-sm-2[data-v-c2d2ef56] {
    padding-bottom: 0.5rem !important;
}
.pl-sm-2[data-v-c2d2ef56],
  .px-sm-2[data-v-c2d2ef56] {
    padding-left: 0.5rem !important;
}
.p-sm-3[data-v-c2d2ef56] {
    padding: 1rem !important;
}
.pt-sm-3[data-v-c2d2ef56],
  .py-sm-3[data-v-c2d2ef56] {
    padding-top: 1rem !important;
}
.pr-sm-3[data-v-c2d2ef56],
  .px-sm-3[data-v-c2d2ef56] {
    padding-right: 1rem !important;
}
.pb-sm-3[data-v-c2d2ef56],
  .py-sm-3[data-v-c2d2ef56] {
    padding-bottom: 1rem !important;
}
.pl-sm-3[data-v-c2d2ef56],
  .px-sm-3[data-v-c2d2ef56] {
    padding-left: 1rem !important;
}
.p-sm-4[data-v-c2d2ef56] {
    padding: 1.5rem !important;
}
.pt-sm-4[data-v-c2d2ef56],
  .py-sm-4[data-v-c2d2ef56] {
    padding-top: 1.5rem !important;
}
.pr-sm-4[data-v-c2d2ef56],
  .px-sm-4[data-v-c2d2ef56] {
    padding-right: 1.5rem !important;
}
.pb-sm-4[data-v-c2d2ef56],
  .py-sm-4[data-v-c2d2ef56] {
    padding-bottom: 1.5rem !important;
}
.pl-sm-4[data-v-c2d2ef56],
  .px-sm-4[data-v-c2d2ef56] {
    padding-left: 1.5rem !important;
}
.p-sm-5[data-v-c2d2ef56] {
    padding: 3rem !important;
}
.pt-sm-5[data-v-c2d2ef56],
  .py-sm-5[data-v-c2d2ef56] {
    padding-top: 3rem !important;
}
.pr-sm-5[data-v-c2d2ef56],
  .px-sm-5[data-v-c2d2ef56] {
    padding-right: 3rem !important;
}
.pb-sm-5[data-v-c2d2ef56],
  .py-sm-5[data-v-c2d2ef56] {
    padding-bottom: 3rem !important;
}
.pl-sm-5[data-v-c2d2ef56],
  .px-sm-5[data-v-c2d2ef56] {
    padding-left: 3rem !important;
}
.m-sm-n1[data-v-c2d2ef56] {
    margin: -0.25rem !important;
}
.mt-sm-n1[data-v-c2d2ef56],
  .my-sm-n1[data-v-c2d2ef56] {
    margin-top: -0.25rem !important;
}
.mr-sm-n1[data-v-c2d2ef56],
  .mx-sm-n1[data-v-c2d2ef56] {
    margin-right: -0.25rem !important;
}
.mb-sm-n1[data-v-c2d2ef56],
  .my-sm-n1[data-v-c2d2ef56] {
    margin-bottom: -0.25rem !important;
}
.ml-sm-n1[data-v-c2d2ef56],
  .mx-sm-n1[data-v-c2d2ef56] {
    margin-left: -0.25rem !important;
}
.m-sm-n2[data-v-c2d2ef56] {
    margin: -0.5rem !important;
}
.mt-sm-n2[data-v-c2d2ef56],
  .my-sm-n2[data-v-c2d2ef56] {
    margin-top: -0.5rem !important;
}
.mr-sm-n2[data-v-c2d2ef56],
  .mx-sm-n2[data-v-c2d2ef56] {
    margin-right: -0.5rem !important;
}
.mb-sm-n2[data-v-c2d2ef56],
  .my-sm-n2[data-v-c2d2ef56] {
    margin-bottom: -0.5rem !important;
}
.ml-sm-n2[data-v-c2d2ef56],
  .mx-sm-n2[data-v-c2d2ef56] {
    margin-left: -0.5rem !important;
}
.m-sm-n3[data-v-c2d2ef56] {
    margin: -1rem !important;
}
.mt-sm-n3[data-v-c2d2ef56],
  .my-sm-n3[data-v-c2d2ef56] {
    margin-top: -1rem !important;
}
.mr-sm-n3[data-v-c2d2ef56],
  .mx-sm-n3[data-v-c2d2ef56] {
    margin-right: -1rem !important;
}
.mb-sm-n3[data-v-c2d2ef56],
  .my-sm-n3[data-v-c2d2ef56] {
    margin-bottom: -1rem !important;
}
.ml-sm-n3[data-v-c2d2ef56],
  .mx-sm-n3[data-v-c2d2ef56] {
    margin-left: -1rem !important;
}
.m-sm-n4[data-v-c2d2ef56] {
    margin: -1.5rem !important;
}
.mt-sm-n4[data-v-c2d2ef56],
  .my-sm-n4[data-v-c2d2ef56] {
    margin-top: -1.5rem !important;
}
.mr-sm-n4[data-v-c2d2ef56],
  .mx-sm-n4[data-v-c2d2ef56] {
    margin-right: -1.5rem !important;
}
.mb-sm-n4[data-v-c2d2ef56],
  .my-sm-n4[data-v-c2d2ef56] {
    margin-bottom: -1.5rem !important;
}
.ml-sm-n4[data-v-c2d2ef56],
  .mx-sm-n4[data-v-c2d2ef56] {
    margin-left: -1.5rem !important;
}
.m-sm-n5[data-v-c2d2ef56] {
    margin: -3rem !important;
}
.mt-sm-n5[data-v-c2d2ef56],
  .my-sm-n5[data-v-c2d2ef56] {
    margin-top: -3rem !important;
}
.mr-sm-n5[data-v-c2d2ef56],
  .mx-sm-n5[data-v-c2d2ef56] {
    margin-right: -3rem !important;
}
.mb-sm-n5[data-v-c2d2ef56],
  .my-sm-n5[data-v-c2d2ef56] {
    margin-bottom: -3rem !important;
}
.ml-sm-n5[data-v-c2d2ef56],
  .mx-sm-n5[data-v-c2d2ef56] {
    margin-left: -3rem !important;
}
.m-sm-auto[data-v-c2d2ef56] {
    margin: auto !important;
}
.mt-sm-auto[data-v-c2d2ef56],
  .my-sm-auto[data-v-c2d2ef56] {
    margin-top: auto !important;
}
.mr-sm-auto[data-v-c2d2ef56],
  .mx-sm-auto[data-v-c2d2ef56] {
    margin-right: auto !important;
}
.mb-sm-auto[data-v-c2d2ef56],
  .my-sm-auto[data-v-c2d2ef56] {
    margin-bottom: auto !important;
}
.ml-sm-auto[data-v-c2d2ef56],
  .mx-sm-auto[data-v-c2d2ef56] {
    margin-left: auto !important;
}
}
@media (min-width: 768px) {
.m-md-0[data-v-c2d2ef56] {
    margin: 0 !important;
}
.mt-md-0[data-v-c2d2ef56],
  .my-md-0[data-v-c2d2ef56] {
    margin-top: 0 !important;
}
.mr-md-0[data-v-c2d2ef56],
  .mx-md-0[data-v-c2d2ef56] {
    margin-right: 0 !important;
}
.mb-md-0[data-v-c2d2ef56],
  .my-md-0[data-v-c2d2ef56] {
    margin-bottom: 0 !important;
}
.ml-md-0[data-v-c2d2ef56],
  .mx-md-0[data-v-c2d2ef56] {
    margin-left: 0 !important;
}
.m-md-1[data-v-c2d2ef56] {
    margin: 0.25rem !important;
}
.mt-md-1[data-v-c2d2ef56],
  .my-md-1[data-v-c2d2ef56] {
    margin-top: 0.25rem !important;
}
.mr-md-1[data-v-c2d2ef56],
  .mx-md-1[data-v-c2d2ef56] {
    margin-right: 0.25rem !important;
}
.mb-md-1[data-v-c2d2ef56],
  .my-md-1[data-v-c2d2ef56] {
    margin-bottom: 0.25rem !important;
}
.ml-md-1[data-v-c2d2ef56],
  .mx-md-1[data-v-c2d2ef56] {
    margin-left: 0.25rem !important;
}
.m-md-2[data-v-c2d2ef56] {
    margin: 0.5rem !important;
}
.mt-md-2[data-v-c2d2ef56],
  .my-md-2[data-v-c2d2ef56] {
    margin-top: 0.5rem !important;
}
.mr-md-2[data-v-c2d2ef56],
  .mx-md-2[data-v-c2d2ef56] {
    margin-right: 0.5rem !important;
}
.mb-md-2[data-v-c2d2ef56],
  .my-md-2[data-v-c2d2ef56] {
    margin-bottom: 0.5rem !important;
}
.ml-md-2[data-v-c2d2ef56],
  .mx-md-2[data-v-c2d2ef56] {
    margin-left: 0.5rem !important;
}
.m-md-3[data-v-c2d2ef56] {
    margin: 1rem !important;
}
.mt-md-3[data-v-c2d2ef56],
  .my-md-3[data-v-c2d2ef56] {
    margin-top: 1rem !important;
}
.mr-md-3[data-v-c2d2ef56],
  .mx-md-3[data-v-c2d2ef56] {
    margin-right: 1rem !important;
}
.mb-md-3[data-v-c2d2ef56],
  .my-md-3[data-v-c2d2ef56] {
    margin-bottom: 1rem !important;
}
.ml-md-3[data-v-c2d2ef56],
  .mx-md-3[data-v-c2d2ef56] {
    margin-left: 1rem !important;
}
.m-md-4[data-v-c2d2ef56] {
    margin: 1.5rem !important;
}
.mt-md-4[data-v-c2d2ef56],
  .my-md-4[data-v-c2d2ef56] {
    margin-top: 1.5rem !important;
}
.mr-md-4[data-v-c2d2ef56],
  .mx-md-4[data-v-c2d2ef56] {
    margin-right: 1.5rem !important;
}
.mb-md-4[data-v-c2d2ef56],
  .my-md-4[data-v-c2d2ef56] {
    margin-bottom: 1.5rem !important;
}
.ml-md-4[data-v-c2d2ef56],
  .mx-md-4[data-v-c2d2ef56] {
    margin-left: 1.5rem !important;
}
.m-md-5[data-v-c2d2ef56] {
    margin: 3rem !important;
}
.mt-md-5[data-v-c2d2ef56],
  .my-md-5[data-v-c2d2ef56] {
    margin-top: 3rem !important;
}
.mr-md-5[data-v-c2d2ef56],
  .mx-md-5[data-v-c2d2ef56] {
    margin-right: 3rem !important;
}
.mb-md-5[data-v-c2d2ef56],
  .my-md-5[data-v-c2d2ef56] {
    margin-bottom: 3rem !important;
}
.ml-md-5[data-v-c2d2ef56],
  .mx-md-5[data-v-c2d2ef56] {
    margin-left: 3rem !important;
}
.p-md-0[data-v-c2d2ef56] {
    padding: 0 !important;
}
.pt-md-0[data-v-c2d2ef56],
  .py-md-0[data-v-c2d2ef56] {
    padding-top: 0 !important;
}
.pr-md-0[data-v-c2d2ef56],
  .px-md-0[data-v-c2d2ef56] {
    padding-right: 0 !important;
}
.pb-md-0[data-v-c2d2ef56],
  .py-md-0[data-v-c2d2ef56] {
    padding-bottom: 0 !important;
}
.pl-md-0[data-v-c2d2ef56],
  .px-md-0[data-v-c2d2ef56] {
    padding-left: 0 !important;
}
.p-md-1[data-v-c2d2ef56] {
    padding: 0.25rem !important;
}
.pt-md-1[data-v-c2d2ef56],
  .py-md-1[data-v-c2d2ef56] {
    padding-top: 0.25rem !important;
}
.pr-md-1[data-v-c2d2ef56],
  .px-md-1[data-v-c2d2ef56] {
    padding-right: 0.25rem !important;
}
.pb-md-1[data-v-c2d2ef56],
  .py-md-1[data-v-c2d2ef56] {
    padding-bottom: 0.25rem !important;
}
.pl-md-1[data-v-c2d2ef56],
  .px-md-1[data-v-c2d2ef56] {
    padding-left: 0.25rem !important;
}
.p-md-2[data-v-c2d2ef56] {
    padding: 0.5rem !important;
}
.pt-md-2[data-v-c2d2ef56],
  .py-md-2[data-v-c2d2ef56] {
    padding-top: 0.5rem !important;
}
.pr-md-2[data-v-c2d2ef56],
  .px-md-2[data-v-c2d2ef56] {
    padding-right: 0.5rem !important;
}
.pb-md-2[data-v-c2d2ef56],
  .py-md-2[data-v-c2d2ef56] {
    padding-bottom: 0.5rem !important;
}
.pl-md-2[data-v-c2d2ef56],
  .px-md-2[data-v-c2d2ef56] {
    padding-left: 0.5rem !important;
}
.p-md-3[data-v-c2d2ef56] {
    padding: 1rem !important;
}
.pt-md-3[data-v-c2d2ef56],
  .py-md-3[data-v-c2d2ef56] {
    padding-top: 1rem !important;
}
.pr-md-3[data-v-c2d2ef56],
  .px-md-3[data-v-c2d2ef56] {
    padding-right: 1rem !important;
}
.pb-md-3[data-v-c2d2ef56],
  .py-md-3[data-v-c2d2ef56] {
    padding-bottom: 1rem !important;
}
.pl-md-3[data-v-c2d2ef56],
  .px-md-3[data-v-c2d2ef56] {
    padding-left: 1rem !important;
}
.p-md-4[data-v-c2d2ef56] {
    padding: 1.5rem !important;
}
.pt-md-4[data-v-c2d2ef56],
  .py-md-4[data-v-c2d2ef56] {
    padding-top: 1.5rem !important;
}
.pr-md-4[data-v-c2d2ef56],
  .px-md-4[data-v-c2d2ef56] {
    padding-right: 1.5rem !important;
}
.pb-md-4[data-v-c2d2ef56],
  .py-md-4[data-v-c2d2ef56] {
    padding-bottom: 1.5rem !important;
}
.pl-md-4[data-v-c2d2ef56],
  .px-md-4[data-v-c2d2ef56] {
    padding-left: 1.5rem !important;
}
.p-md-5[data-v-c2d2ef56] {
    padding: 3rem !important;
}
.pt-md-5[data-v-c2d2ef56],
  .py-md-5[data-v-c2d2ef56] {
    padding-top: 3rem !important;
}
.pr-md-5[data-v-c2d2ef56],
  .px-md-5[data-v-c2d2ef56] {
    padding-right: 3rem !important;
}
.pb-md-5[data-v-c2d2ef56],
  .py-md-5[data-v-c2d2ef56] {
    padding-bottom: 3rem !important;
}
.pl-md-5[data-v-c2d2ef56],
  .px-md-5[data-v-c2d2ef56] {
    padding-left: 3rem !important;
}
.m-md-n1[data-v-c2d2ef56] {
    margin: -0.25rem !important;
}
.mt-md-n1[data-v-c2d2ef56],
  .my-md-n1[data-v-c2d2ef56] {
    margin-top: -0.25rem !important;
}
.mr-md-n1[data-v-c2d2ef56],
  .mx-md-n1[data-v-c2d2ef56] {
    margin-right: -0.25rem !important;
}
.mb-md-n1[data-v-c2d2ef56],
  .my-md-n1[data-v-c2d2ef56] {
    margin-bottom: -0.25rem !important;
}
.ml-md-n1[data-v-c2d2ef56],
  .mx-md-n1[data-v-c2d2ef56] {
    margin-left: -0.25rem !important;
}
.m-md-n2[data-v-c2d2ef56] {
    margin: -0.5rem !important;
}
.mt-md-n2[data-v-c2d2ef56],
  .my-md-n2[data-v-c2d2ef56] {
    margin-top: -0.5rem !important;
}
.mr-md-n2[data-v-c2d2ef56],
  .mx-md-n2[data-v-c2d2ef56] {
    margin-right: -0.5rem !important;
}
.mb-md-n2[data-v-c2d2ef56],
  .my-md-n2[data-v-c2d2ef56] {
    margin-bottom: -0.5rem !important;
}
.ml-md-n2[data-v-c2d2ef56],
  .mx-md-n2[data-v-c2d2ef56] {
    margin-left: -0.5rem !important;
}
.m-md-n3[data-v-c2d2ef56] {
    margin: -1rem !important;
}
.mt-md-n3[data-v-c2d2ef56],
  .my-md-n3[data-v-c2d2ef56] {
    margin-top: -1rem !important;
}
.mr-md-n3[data-v-c2d2ef56],
  .mx-md-n3[data-v-c2d2ef56] {
    margin-right: -1rem !important;
}
.mb-md-n3[data-v-c2d2ef56],
  .my-md-n3[data-v-c2d2ef56] {
    margin-bottom: -1rem !important;
}
.ml-md-n3[data-v-c2d2ef56],
  .mx-md-n3[data-v-c2d2ef56] {
    margin-left: -1rem !important;
}
.m-md-n4[data-v-c2d2ef56] {
    margin: -1.5rem !important;
}
.mt-md-n4[data-v-c2d2ef56],
  .my-md-n4[data-v-c2d2ef56] {
    margin-top: -1.5rem !important;
}
.mr-md-n4[data-v-c2d2ef56],
  .mx-md-n4[data-v-c2d2ef56] {
    margin-right: -1.5rem !important;
}
.mb-md-n4[data-v-c2d2ef56],
  .my-md-n4[data-v-c2d2ef56] {
    margin-bottom: -1.5rem !important;
}
.ml-md-n4[data-v-c2d2ef56],
  .mx-md-n4[data-v-c2d2ef56] {
    margin-left: -1.5rem !important;
}
.m-md-n5[data-v-c2d2ef56] {
    margin: -3rem !important;
}
.mt-md-n5[data-v-c2d2ef56],
  .my-md-n5[data-v-c2d2ef56] {
    margin-top: -3rem !important;
}
.mr-md-n5[data-v-c2d2ef56],
  .mx-md-n5[data-v-c2d2ef56] {
    margin-right: -3rem !important;
}
.mb-md-n5[data-v-c2d2ef56],
  .my-md-n5[data-v-c2d2ef56] {
    margin-bottom: -3rem !important;
}
.ml-md-n5[data-v-c2d2ef56],
  .mx-md-n5[data-v-c2d2ef56] {
    margin-left: -3rem !important;
}
.m-md-auto[data-v-c2d2ef56] {
    margin: auto !important;
}
.mt-md-auto[data-v-c2d2ef56],
  .my-md-auto[data-v-c2d2ef56] {
    margin-top: auto !important;
}
.mr-md-auto[data-v-c2d2ef56],
  .mx-md-auto[data-v-c2d2ef56] {
    margin-right: auto !important;
}
.mb-md-auto[data-v-c2d2ef56],
  .my-md-auto[data-v-c2d2ef56] {
    margin-bottom: auto !important;
}
.ml-md-auto[data-v-c2d2ef56],
  .mx-md-auto[data-v-c2d2ef56] {
    margin-left: auto !important;
}
}
@media (min-width: 992px) {
.m-lg-0[data-v-c2d2ef56] {
    margin: 0 !important;
}
.mt-lg-0[data-v-c2d2ef56],
  .my-lg-0[data-v-c2d2ef56] {
    margin-top: 0 !important;
}
.mr-lg-0[data-v-c2d2ef56],
  .mx-lg-0[data-v-c2d2ef56] {
    margin-right: 0 !important;
}
.mb-lg-0[data-v-c2d2ef56],
  .my-lg-0[data-v-c2d2ef56] {
    margin-bottom: 0 !important;
}
.ml-lg-0[data-v-c2d2ef56],
  .mx-lg-0[data-v-c2d2ef56] {
    margin-left: 0 !important;
}
.m-lg-1[data-v-c2d2ef56] {
    margin: 0.25rem !important;
}
.mt-lg-1[data-v-c2d2ef56],
  .my-lg-1[data-v-c2d2ef56] {
    margin-top: 0.25rem !important;
}
.mr-lg-1[data-v-c2d2ef56],
  .mx-lg-1[data-v-c2d2ef56] {
    margin-right: 0.25rem !important;
}
.mb-lg-1[data-v-c2d2ef56],
  .my-lg-1[data-v-c2d2ef56] {
    margin-bottom: 0.25rem !important;
}
.ml-lg-1[data-v-c2d2ef56],
  .mx-lg-1[data-v-c2d2ef56] {
    margin-left: 0.25rem !important;
}
.m-lg-2[data-v-c2d2ef56] {
    margin: 0.5rem !important;
}
.mt-lg-2[data-v-c2d2ef56],
  .my-lg-2[data-v-c2d2ef56] {
    margin-top: 0.5rem !important;
}
.mr-lg-2[data-v-c2d2ef56],
  .mx-lg-2[data-v-c2d2ef56] {
    margin-right: 0.5rem !important;
}
.mb-lg-2[data-v-c2d2ef56],
  .my-lg-2[data-v-c2d2ef56] {
    margin-bottom: 0.5rem !important;
}
.ml-lg-2[data-v-c2d2ef56],
  .mx-lg-2[data-v-c2d2ef56] {
    margin-left: 0.5rem !important;
}
.m-lg-3[data-v-c2d2ef56] {
    margin: 1rem !important;
}
.mt-lg-3[data-v-c2d2ef56],
  .my-lg-3[data-v-c2d2ef56] {
    margin-top: 1rem !important;
}
.mr-lg-3[data-v-c2d2ef56],
  .mx-lg-3[data-v-c2d2ef56] {
    margin-right: 1rem !important;
}
.mb-lg-3[data-v-c2d2ef56],
  .my-lg-3[data-v-c2d2ef56] {
    margin-bottom: 1rem !important;
}
.ml-lg-3[data-v-c2d2ef56],
  .mx-lg-3[data-v-c2d2ef56] {
    margin-left: 1rem !important;
}
.m-lg-4[data-v-c2d2ef56] {
    margin: 1.5rem !important;
}
.mt-lg-4[data-v-c2d2ef56],
  .my-lg-4[data-v-c2d2ef56] {
    margin-top: 1.5rem !important;
}
.mr-lg-4[data-v-c2d2ef56],
  .mx-lg-4[data-v-c2d2ef56] {
    margin-right: 1.5rem !important;
}
.mb-lg-4[data-v-c2d2ef56],
  .my-lg-4[data-v-c2d2ef56] {
    margin-bottom: 1.5rem !important;
}
.ml-lg-4[data-v-c2d2ef56],
  .mx-lg-4[data-v-c2d2ef56] {
    margin-left: 1.5rem !important;
}
.m-lg-5[data-v-c2d2ef56] {
    margin: 3rem !important;
}
.mt-lg-5[data-v-c2d2ef56],
  .my-lg-5[data-v-c2d2ef56] {
    margin-top: 3rem !important;
}
.mr-lg-5[data-v-c2d2ef56],
  .mx-lg-5[data-v-c2d2ef56] {
    margin-right: 3rem !important;
}
.mb-lg-5[data-v-c2d2ef56],
  .my-lg-5[data-v-c2d2ef56] {
    margin-bottom: 3rem !important;
}
.ml-lg-5[data-v-c2d2ef56],
  .mx-lg-5[data-v-c2d2ef56] {
    margin-left: 3rem !important;
}
.p-lg-0[data-v-c2d2ef56] {
    padding: 0 !important;
}
.pt-lg-0[data-v-c2d2ef56],
  .py-lg-0[data-v-c2d2ef56] {
    padding-top: 0 !important;
}
.pr-lg-0[data-v-c2d2ef56],
  .px-lg-0[data-v-c2d2ef56] {
    padding-right: 0 !important;
}
.pb-lg-0[data-v-c2d2ef56],
  .py-lg-0[data-v-c2d2ef56] {
    padding-bottom: 0 !important;
}
.pl-lg-0[data-v-c2d2ef56],
  .px-lg-0[data-v-c2d2ef56] {
    padding-left: 0 !important;
}
.p-lg-1[data-v-c2d2ef56] {
    padding: 0.25rem !important;
}
.pt-lg-1[data-v-c2d2ef56],
  .py-lg-1[data-v-c2d2ef56] {
    padding-top: 0.25rem !important;
}
.pr-lg-1[data-v-c2d2ef56],
  .px-lg-1[data-v-c2d2ef56] {
    padding-right: 0.25rem !important;
}
.pb-lg-1[data-v-c2d2ef56],
  .py-lg-1[data-v-c2d2ef56] {
    padding-bottom: 0.25rem !important;
}
.pl-lg-1[data-v-c2d2ef56],
  .px-lg-1[data-v-c2d2ef56] {
    padding-left: 0.25rem !important;
}
.p-lg-2[data-v-c2d2ef56] {
    padding: 0.5rem !important;
}
.pt-lg-2[data-v-c2d2ef56],
  .py-lg-2[data-v-c2d2ef56] {
    padding-top: 0.5rem !important;
}
.pr-lg-2[data-v-c2d2ef56],
  .px-lg-2[data-v-c2d2ef56] {
    padding-right: 0.5rem !important;
}
.pb-lg-2[data-v-c2d2ef56],
  .py-lg-2[data-v-c2d2ef56] {
    padding-bottom: 0.5rem !important;
}
.pl-lg-2[data-v-c2d2ef56],
  .px-lg-2[data-v-c2d2ef56] {
    padding-left: 0.5rem !important;
}
.p-lg-3[data-v-c2d2ef56] {
    padding: 1rem !important;
}
.pt-lg-3[data-v-c2d2ef56],
  .py-lg-3[data-v-c2d2ef56] {
    padding-top: 1rem !important;
}
.pr-lg-3[data-v-c2d2ef56],
  .px-lg-3[data-v-c2d2ef56] {
    padding-right: 1rem !important;
}
.pb-lg-3[data-v-c2d2ef56],
  .py-lg-3[data-v-c2d2ef56] {
    padding-bottom: 1rem !important;
}
.pl-lg-3[data-v-c2d2ef56],
  .px-lg-3[data-v-c2d2ef56] {
    padding-left: 1rem !important;
}
.p-lg-4[data-v-c2d2ef56] {
    padding: 1.5rem !important;
}
.pt-lg-4[data-v-c2d2ef56],
  .py-lg-4[data-v-c2d2ef56] {
    padding-top: 1.5rem !important;
}
.pr-lg-4[data-v-c2d2ef56],
  .px-lg-4[data-v-c2d2ef56] {
    padding-right: 1.5rem !important;
}
.pb-lg-4[data-v-c2d2ef56],
  .py-lg-4[data-v-c2d2ef56] {
    padding-bottom: 1.5rem !important;
}
.pl-lg-4[data-v-c2d2ef56],
  .px-lg-4[data-v-c2d2ef56] {
    padding-left: 1.5rem !important;
}
.p-lg-5[data-v-c2d2ef56] {
    padding: 3rem !important;
}
.pt-lg-5[data-v-c2d2ef56],
  .py-lg-5[data-v-c2d2ef56] {
    padding-top: 3rem !important;
}
.pr-lg-5[data-v-c2d2ef56],
  .px-lg-5[data-v-c2d2ef56] {
    padding-right: 3rem !important;
}
.pb-lg-5[data-v-c2d2ef56],
  .py-lg-5[data-v-c2d2ef56] {
    padding-bottom: 3rem !important;
}
.pl-lg-5[data-v-c2d2ef56],
  .px-lg-5[data-v-c2d2ef56] {
    padding-left: 3rem !important;
}
.m-lg-n1[data-v-c2d2ef56] {
    margin: -0.25rem !important;
}
.mt-lg-n1[data-v-c2d2ef56],
  .my-lg-n1[data-v-c2d2ef56] {
    margin-top: -0.25rem !important;
}
.mr-lg-n1[data-v-c2d2ef56],
  .mx-lg-n1[data-v-c2d2ef56] {
    margin-right: -0.25rem !important;
}
.mb-lg-n1[data-v-c2d2ef56],
  .my-lg-n1[data-v-c2d2ef56] {
    margin-bottom: -0.25rem !important;
}
.ml-lg-n1[data-v-c2d2ef56],
  .mx-lg-n1[data-v-c2d2ef56] {
    margin-left: -0.25rem !important;
}
.m-lg-n2[data-v-c2d2ef56] {
    margin: -0.5rem !important;
}
.mt-lg-n2[data-v-c2d2ef56],
  .my-lg-n2[data-v-c2d2ef56] {
    margin-top: -0.5rem !important;
}
.mr-lg-n2[data-v-c2d2ef56],
  .mx-lg-n2[data-v-c2d2ef56] {
    margin-right: -0.5rem !important;
}
.mb-lg-n2[data-v-c2d2ef56],
  .my-lg-n2[data-v-c2d2ef56] {
    margin-bottom: -0.5rem !important;
}
.ml-lg-n2[data-v-c2d2ef56],
  .mx-lg-n2[data-v-c2d2ef56] {
    margin-left: -0.5rem !important;
}
.m-lg-n3[data-v-c2d2ef56] {
    margin: -1rem !important;
}
.mt-lg-n3[data-v-c2d2ef56],
  .my-lg-n3[data-v-c2d2ef56] {
    margin-top: -1rem !important;
}
.mr-lg-n3[data-v-c2d2ef56],
  .mx-lg-n3[data-v-c2d2ef56] {
    margin-right: -1rem !important;
}
.mb-lg-n3[data-v-c2d2ef56],
  .my-lg-n3[data-v-c2d2ef56] {
    margin-bottom: -1rem !important;
}
.ml-lg-n3[data-v-c2d2ef56],
  .mx-lg-n3[data-v-c2d2ef56] {
    margin-left: -1rem !important;
}
.m-lg-n4[data-v-c2d2ef56] {
    margin: -1.5rem !important;
}
.mt-lg-n4[data-v-c2d2ef56],
  .my-lg-n4[data-v-c2d2ef56] {
    margin-top: -1.5rem !important;
}
.mr-lg-n4[data-v-c2d2ef56],
  .mx-lg-n4[data-v-c2d2ef56] {
    margin-right: -1.5rem !important;
}
.mb-lg-n4[data-v-c2d2ef56],
  .my-lg-n4[data-v-c2d2ef56] {
    margin-bottom: -1.5rem !important;
}
.ml-lg-n4[data-v-c2d2ef56],
  .mx-lg-n4[data-v-c2d2ef56] {
    margin-left: -1.5rem !important;
}
.m-lg-n5[data-v-c2d2ef56] {
    margin: -3rem !important;
}
.mt-lg-n5[data-v-c2d2ef56],
  .my-lg-n5[data-v-c2d2ef56] {
    margin-top: -3rem !important;
}
.mr-lg-n5[data-v-c2d2ef56],
  .mx-lg-n5[data-v-c2d2ef56] {
    margin-right: -3rem !important;
}
.mb-lg-n5[data-v-c2d2ef56],
  .my-lg-n5[data-v-c2d2ef56] {
    margin-bottom: -3rem !important;
}
.ml-lg-n5[data-v-c2d2ef56],
  .mx-lg-n5[data-v-c2d2ef56] {
    margin-left: -3rem !important;
}
.m-lg-auto[data-v-c2d2ef56] {
    margin: auto !important;
}
.mt-lg-auto[data-v-c2d2ef56],
  .my-lg-auto[data-v-c2d2ef56] {
    margin-top: auto !important;
}
.mr-lg-auto[data-v-c2d2ef56],
  .mx-lg-auto[data-v-c2d2ef56] {
    margin-right: auto !important;
}
.mb-lg-auto[data-v-c2d2ef56],
  .my-lg-auto[data-v-c2d2ef56] {
    margin-bottom: auto !important;
}
.ml-lg-auto[data-v-c2d2ef56],
  .mx-lg-auto[data-v-c2d2ef56] {
    margin-left: auto !important;
}
}
@media (min-width: 1200px) {
.m-xl-0[data-v-c2d2ef56] {
    margin: 0 !important;
}
.mt-xl-0[data-v-c2d2ef56],
  .my-xl-0[data-v-c2d2ef56] {
    margin-top: 0 !important;
}
.mr-xl-0[data-v-c2d2ef56],
  .mx-xl-0[data-v-c2d2ef56] {
    margin-right: 0 !important;
}
.mb-xl-0[data-v-c2d2ef56],
  .my-xl-0[data-v-c2d2ef56] {
    margin-bottom: 0 !important;
}
.ml-xl-0[data-v-c2d2ef56],
  .mx-xl-0[data-v-c2d2ef56] {
    margin-left: 0 !important;
}
.m-xl-1[data-v-c2d2ef56] {
    margin: 0.25rem !important;
}
.mt-xl-1[data-v-c2d2ef56],
  .my-xl-1[data-v-c2d2ef56] {
    margin-top: 0.25rem !important;
}
.mr-xl-1[data-v-c2d2ef56],
  .mx-xl-1[data-v-c2d2ef56] {
    margin-right: 0.25rem !important;
}
.mb-xl-1[data-v-c2d2ef56],
  .my-xl-1[data-v-c2d2ef56] {
    margin-bottom: 0.25rem !important;
}
.ml-xl-1[data-v-c2d2ef56],
  .mx-xl-1[data-v-c2d2ef56] {
    margin-left: 0.25rem !important;
}
.m-xl-2[data-v-c2d2ef56] {
    margin: 0.5rem !important;
}
.mt-xl-2[data-v-c2d2ef56],
  .my-xl-2[data-v-c2d2ef56] {
    margin-top: 0.5rem !important;
}
.mr-xl-2[data-v-c2d2ef56],
  .mx-xl-2[data-v-c2d2ef56] {
    margin-right: 0.5rem !important;
}
.mb-xl-2[data-v-c2d2ef56],
  .my-xl-2[data-v-c2d2ef56] {
    margin-bottom: 0.5rem !important;
}
.ml-xl-2[data-v-c2d2ef56],
  .mx-xl-2[data-v-c2d2ef56] {
    margin-left: 0.5rem !important;
}
.m-xl-3[data-v-c2d2ef56] {
    margin: 1rem !important;
}
.mt-xl-3[data-v-c2d2ef56],
  .my-xl-3[data-v-c2d2ef56] {
    margin-top: 1rem !important;
}
.mr-xl-3[data-v-c2d2ef56],
  .mx-xl-3[data-v-c2d2ef56] {
    margin-right: 1rem !important;
}
.mb-xl-3[data-v-c2d2ef56],
  .my-xl-3[data-v-c2d2ef56] {
    margin-bottom: 1rem !important;
}
.ml-xl-3[data-v-c2d2ef56],
  .mx-xl-3[data-v-c2d2ef56] {
    margin-left: 1rem !important;
}
.m-xl-4[data-v-c2d2ef56] {
    margin: 1.5rem !important;
}
.mt-xl-4[data-v-c2d2ef56],
  .my-xl-4[data-v-c2d2ef56] {
    margin-top: 1.5rem !important;
}
.mr-xl-4[data-v-c2d2ef56],
  .mx-xl-4[data-v-c2d2ef56] {
    margin-right: 1.5rem !important;
}
.mb-xl-4[data-v-c2d2ef56],
  .my-xl-4[data-v-c2d2ef56] {
    margin-bottom: 1.5rem !important;
}
.ml-xl-4[data-v-c2d2ef56],
  .mx-xl-4[data-v-c2d2ef56] {
    margin-left: 1.5rem !important;
}
.m-xl-5[data-v-c2d2ef56] {
    margin: 3rem !important;
}
.mt-xl-5[data-v-c2d2ef56],
  .my-xl-5[data-v-c2d2ef56] {
    margin-top: 3rem !important;
}
.mr-xl-5[data-v-c2d2ef56],
  .mx-xl-5[data-v-c2d2ef56] {
    margin-right: 3rem !important;
}
.mb-xl-5[data-v-c2d2ef56],
  .my-xl-5[data-v-c2d2ef56] {
    margin-bottom: 3rem !important;
}
.ml-xl-5[data-v-c2d2ef56],
  .mx-xl-5[data-v-c2d2ef56] {
    margin-left: 3rem !important;
}
.p-xl-0[data-v-c2d2ef56] {
    padding: 0 !important;
}
.pt-xl-0[data-v-c2d2ef56],
  .py-xl-0[data-v-c2d2ef56] {
    padding-top: 0 !important;
}
.pr-xl-0[data-v-c2d2ef56],
  .px-xl-0[data-v-c2d2ef56] {
    padding-right: 0 !important;
}
.pb-xl-0[data-v-c2d2ef56],
  .py-xl-0[data-v-c2d2ef56] {
    padding-bottom: 0 !important;
}
.pl-xl-0[data-v-c2d2ef56],
  .px-xl-0[data-v-c2d2ef56] {
    padding-left: 0 !important;
}
.p-xl-1[data-v-c2d2ef56] {
    padding: 0.25rem !important;
}
.pt-xl-1[data-v-c2d2ef56],
  .py-xl-1[data-v-c2d2ef56] {
    padding-top: 0.25rem !important;
}
.pr-xl-1[data-v-c2d2ef56],
  .px-xl-1[data-v-c2d2ef56] {
    padding-right: 0.25rem !important;
}
.pb-xl-1[data-v-c2d2ef56],
  .py-xl-1[data-v-c2d2ef56] {
    padding-bottom: 0.25rem !important;
}
.pl-xl-1[data-v-c2d2ef56],
  .px-xl-1[data-v-c2d2ef56] {
    padding-left: 0.25rem !important;
}
.p-xl-2[data-v-c2d2ef56] {
    padding: 0.5rem !important;
}
.pt-xl-2[data-v-c2d2ef56],
  .py-xl-2[data-v-c2d2ef56] {
    padding-top: 0.5rem !important;
}
.pr-xl-2[data-v-c2d2ef56],
  .px-xl-2[data-v-c2d2ef56] {
    padding-right: 0.5rem !important;
}
.pb-xl-2[data-v-c2d2ef56],
  .py-xl-2[data-v-c2d2ef56] {
    padding-bottom: 0.5rem !important;
}
.pl-xl-2[data-v-c2d2ef56],
  .px-xl-2[data-v-c2d2ef56] {
    padding-left: 0.5rem !important;
}
.p-xl-3[data-v-c2d2ef56] {
    padding: 1rem !important;
}
.pt-xl-3[data-v-c2d2ef56],
  .py-xl-3[data-v-c2d2ef56] {
    padding-top: 1rem !important;
}
.pr-xl-3[data-v-c2d2ef56],
  .px-xl-3[data-v-c2d2ef56] {
    padding-right: 1rem !important;
}
.pb-xl-3[data-v-c2d2ef56],
  .py-xl-3[data-v-c2d2ef56] {
    padding-bottom: 1rem !important;
}
.pl-xl-3[data-v-c2d2ef56],
  .px-xl-3[data-v-c2d2ef56] {
    padding-left: 1rem !important;
}
.p-xl-4[data-v-c2d2ef56] {
    padding: 1.5rem !important;
}
.pt-xl-4[data-v-c2d2ef56],
  .py-xl-4[data-v-c2d2ef56] {
    padding-top: 1.5rem !important;
}
.pr-xl-4[data-v-c2d2ef56],
  .px-xl-4[data-v-c2d2ef56] {
    padding-right: 1.5rem !important;
}
.pb-xl-4[data-v-c2d2ef56],
  .py-xl-4[data-v-c2d2ef56] {
    padding-bottom: 1.5rem !important;
}
.pl-xl-4[data-v-c2d2ef56],
  .px-xl-4[data-v-c2d2ef56] {
    padding-left: 1.5rem !important;
}
.p-xl-5[data-v-c2d2ef56] {
    padding: 3rem !important;
}
.pt-xl-5[data-v-c2d2ef56],
  .py-xl-5[data-v-c2d2ef56] {
    padding-top: 3rem !important;
}
.pr-xl-5[data-v-c2d2ef56],
  .px-xl-5[data-v-c2d2ef56] {
    padding-right: 3rem !important;
}
.pb-xl-5[data-v-c2d2ef56],
  .py-xl-5[data-v-c2d2ef56] {
    padding-bottom: 3rem !important;
}
.pl-xl-5[data-v-c2d2ef56],
  .px-xl-5[data-v-c2d2ef56] {
    padding-left: 3rem !important;
}
.m-xl-n1[data-v-c2d2ef56] {
    margin: -0.25rem !important;
}
.mt-xl-n1[data-v-c2d2ef56],
  .my-xl-n1[data-v-c2d2ef56] {
    margin-top: -0.25rem !important;
}
.mr-xl-n1[data-v-c2d2ef56],
  .mx-xl-n1[data-v-c2d2ef56] {
    margin-right: -0.25rem !important;
}
.mb-xl-n1[data-v-c2d2ef56],
  .my-xl-n1[data-v-c2d2ef56] {
    margin-bottom: -0.25rem !important;
}
.ml-xl-n1[data-v-c2d2ef56],
  .mx-xl-n1[data-v-c2d2ef56] {
    margin-left: -0.25rem !important;
}
.m-xl-n2[data-v-c2d2ef56] {
    margin: -0.5rem !important;
}
.mt-xl-n2[data-v-c2d2ef56],
  .my-xl-n2[data-v-c2d2ef56] {
    margin-top: -0.5rem !important;
}
.mr-xl-n2[data-v-c2d2ef56],
  .mx-xl-n2[data-v-c2d2ef56] {
    margin-right: -0.5rem !important;
}
.mb-xl-n2[data-v-c2d2ef56],
  .my-xl-n2[data-v-c2d2ef56] {
    margin-bottom: -0.5rem !important;
}
.ml-xl-n2[data-v-c2d2ef56],
  .mx-xl-n2[data-v-c2d2ef56] {
    margin-left: -0.5rem !important;
}
.m-xl-n3[data-v-c2d2ef56] {
    margin: -1rem !important;
}
.mt-xl-n3[data-v-c2d2ef56],
  .my-xl-n3[data-v-c2d2ef56] {
    margin-top: -1rem !important;
}
.mr-xl-n3[data-v-c2d2ef56],
  .mx-xl-n3[data-v-c2d2ef56] {
    margin-right: -1rem !important;
}
.mb-xl-n3[data-v-c2d2ef56],
  .my-xl-n3[data-v-c2d2ef56] {
    margin-bottom: -1rem !important;
}
.ml-xl-n3[data-v-c2d2ef56],
  .mx-xl-n3[data-v-c2d2ef56] {
    margin-left: -1rem !important;
}
.m-xl-n4[data-v-c2d2ef56] {
    margin: -1.5rem !important;
}
.mt-xl-n4[data-v-c2d2ef56],
  .my-xl-n4[data-v-c2d2ef56] {
    margin-top: -1.5rem !important;
}
.mr-xl-n4[data-v-c2d2ef56],
  .mx-xl-n4[data-v-c2d2ef56] {
    margin-right: -1.5rem !important;
}
.mb-xl-n4[data-v-c2d2ef56],
  .my-xl-n4[data-v-c2d2ef56] {
    margin-bottom: -1.5rem !important;
}
.ml-xl-n4[data-v-c2d2ef56],
  .mx-xl-n4[data-v-c2d2ef56] {
    margin-left: -1.5rem !important;
}
.m-xl-n5[data-v-c2d2ef56] {
    margin: -3rem !important;
}
.mt-xl-n5[data-v-c2d2ef56],
  .my-xl-n5[data-v-c2d2ef56] {
    margin-top: -3rem !important;
}
.mr-xl-n5[data-v-c2d2ef56],
  .mx-xl-n5[data-v-c2d2ef56] {
    margin-right: -3rem !important;
}
.mb-xl-n5[data-v-c2d2ef56],
  .my-xl-n5[data-v-c2d2ef56] {
    margin-bottom: -3rem !important;
}
.ml-xl-n5[data-v-c2d2ef56],
  .mx-xl-n5[data-v-c2d2ef56] {
    margin-left: -3rem !important;
}
.m-xl-auto[data-v-c2d2ef56] {
    margin: auto !important;
}
.mt-xl-auto[data-v-c2d2ef56],
  .my-xl-auto[data-v-c2d2ef56] {
    margin-top: auto !important;
}
.mr-xl-auto[data-v-c2d2ef56],
  .mx-xl-auto[data-v-c2d2ef56] {
    margin-right: auto !important;
}
.mb-xl-auto[data-v-c2d2ef56],
  .my-xl-auto[data-v-c2d2ef56] {
    margin-bottom: auto !important;
}
.ml-xl-auto[data-v-c2d2ef56],
  .mx-xl-auto[data-v-c2d2ef56] {
    margin-left: auto !important;
}
}
.text-monospace[data-v-c2d2ef56] {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}
.text-justify[data-v-c2d2ef56] {
  text-align: justify !important;
}
.text-wrap[data-v-c2d2ef56] {
  white-space: normal !important;
}
.text-nowrap[data-v-c2d2ef56] {
  white-space: nowrap !important;
}
.text-truncate[data-v-c2d2ef56] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left[data-v-c2d2ef56] {
  text-align: left !important;
}
.text-right[data-v-c2d2ef56] {
  text-align: right !important;
}
.text-center[data-v-c2d2ef56] {
  text-align: center !important;
}
@media (min-width: 576px) {
.text-sm-left[data-v-c2d2ef56] {
    text-align: left !important;
}
.text-sm-right[data-v-c2d2ef56] {
    text-align: right !important;
}
.text-sm-center[data-v-c2d2ef56] {
    text-align: center !important;
}
}
@media (min-width: 768px) {
.text-md-left[data-v-c2d2ef56] {
    text-align: left !important;
}
.text-md-right[data-v-c2d2ef56] {
    text-align: right !important;
}
.text-md-center[data-v-c2d2ef56] {
    text-align: center !important;
}
}
@media (min-width: 992px) {
.text-lg-left[data-v-c2d2ef56] {
    text-align: left !important;
}
.text-lg-right[data-v-c2d2ef56] {
    text-align: right !important;
}
.text-lg-center[data-v-c2d2ef56] {
    text-align: center !important;
}
}
@media (min-width: 1200px) {
.text-xl-left[data-v-c2d2ef56] {
    text-align: left !important;
}
.text-xl-right[data-v-c2d2ef56] {
    text-align: right !important;
}
.text-xl-center[data-v-c2d2ef56] {
    text-align: center !important;
}
}
.text-lowercase[data-v-c2d2ef56] {
  text-transform: lowercase !important;
}
.text-uppercase[data-v-c2d2ef56] {
  text-transform: uppercase !important;
}
.text-capitalize[data-v-c2d2ef56] {
  text-transform: capitalize !important;
}
.font-weight-light[data-v-c2d2ef56] {
  font-weight: 300 !important;
}
.font-weight-lighter[data-v-c2d2ef56] {
  font-weight: lighter !important;
}
.font-weight-normal[data-v-c2d2ef56] {
  font-weight: 400 !important;
}
.font-weight-bold[data-v-c2d2ef56] {
  font-weight: 700 !important;
}
.font-weight-bolder[data-v-c2d2ef56] {
  font-weight: bolder !important;
}
.font-italic[data-v-c2d2ef56] {
  font-style: italic !important;
}
.text-white[data-v-c2d2ef56] {
  color: #fff !important;
}
.text-primary[data-v-c2d2ef56] {
  color: #007bff !important;
}
a.text-primary[data-v-c2d2ef56]:focus,
a.text-primary[data-v-c2d2ef56]:hover {
  color: #0056b3 !important;
}
.text-secondary[data-v-c2d2ef56] {
  color: #6c757d !important;
}
a.text-secondary[data-v-c2d2ef56]:focus,
a.text-secondary[data-v-c2d2ef56]:hover {
  color: #494f54 !important;
}
.text-success[data-v-c2d2ef56] {
  color: #28a745 !important;
}
a.text-success[data-v-c2d2ef56]:focus,
a.text-success[data-v-c2d2ef56]:hover {
  color: #19692c !important;
}
.text-info[data-v-c2d2ef56] {
  color: #17a2b8 !important;
}
a.text-info[data-v-c2d2ef56]:focus,
a.text-info[data-v-c2d2ef56]:hover {
  color: #0f6674 !important;
}
.text-warning[data-v-c2d2ef56] {
  color: #ffc107 !important;
}
a.text-warning[data-v-c2d2ef56]:focus,
a.text-warning[data-v-c2d2ef56]:hover {
  color: #ba8b00 !important;
}
.text-danger[data-v-c2d2ef56] {
  color: #dc3545 !important;
}
a.text-danger[data-v-c2d2ef56]:focus,
a.text-danger[data-v-c2d2ef56]:hover {
  color: #a71d2a !important;
}
.text-light[data-v-c2d2ef56] {
  color: #f8f9fa !important;
}
a.text-light[data-v-c2d2ef56]:focus,
a.text-light[data-v-c2d2ef56]:hover {
  color: #cbd3da !important;
}
.text-dark[data-v-c2d2ef56] {
  color: #343a40 !important;
}
a.text-dark[data-v-c2d2ef56]:focus,
a.text-dark[data-v-c2d2ef56]:hover {
  color: #121416 !important;
}
.text-body[data-v-c2d2ef56] {
  color: #212529 !important;
}
.text-muted[data-v-c2d2ef56] {
  color: #6c757d !important;
}
.text-black-50[data-v-c2d2ef56] {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50[data-v-c2d2ef56] {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide[data-v-c2d2ef56] {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none[data-v-c2d2ef56] {
  text-decoration: none !important;
}
.text-break[data-v-c2d2ef56] {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.text-reset[data-v-c2d2ef56] {
  color: inherit !important;
}
.visible[data-v-c2d2ef56] {
  visibility: visible !important;
}
.invisible[data-v-c2d2ef56] {
  visibility: hidden !important;
}
@media print {
*[data-v-c2d2ef56],[data-v-c2d2ef56]::after,[data-v-c2d2ef56]::before {
    text-shadow: none !important;
    box-shadow: none !important;
}
a[data-v-c2d2ef56]:not(.btn) {
    text-decoration: underline;
}
abbr[title][data-v-c2d2ef56]::after {
    content: " (" attr(title) ")";
}
pre[data-v-c2d2ef56] {
    white-space: pre-wrap !important;
}
blockquote[data-v-c2d2ef56],
  pre[data-v-c2d2ef56] {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
}
thead[data-v-c2d2ef56] {
    display: table-header-group;
}
img[data-v-c2d2ef56],
  tr[data-v-c2d2ef56] {
    page-break-inside: avoid;
}
h2[data-v-c2d2ef56],
  h3[data-v-c2d2ef56],
  p[data-v-c2d2ef56] {
    orphans: 3;
    widows: 3;
}
h2[data-v-c2d2ef56],
  h3[data-v-c2d2ef56] {
    page-break-after: avoid;
}
@page {
    size: a3;
}
body[data-v-c2d2ef56] {
    min-width: 992px !important;
}
.container[data-v-c2d2ef56] {
    min-width: 992px !important;
}
.navbar[data-v-c2d2ef56] {
    display: none;
}
.badge[data-v-c2d2ef56] {
    border: 1px solid #000;
}
.table[data-v-c2d2ef56] {
    border-collapse: collapse !important;
}
.table td[data-v-c2d2ef56],
  .table th[data-v-c2d2ef56] {
    background-color: #fff !important;
}
.table-bordered td[data-v-c2d2ef56],
  .table-bordered th[data-v-c2d2ef56] {
    border: 1px solid #dee2e6 !important;
}
.table-dark[data-v-c2d2ef56] {
    color: inherit;
}
.table-dark tbody + tbody[data-v-c2d2ef56],
  .table-dark td[data-v-c2d2ef56],
  .table-dark th[data-v-c2d2ef56],
  .table-dark thead th[data-v-c2d2ef56] {
    border-color: #dee2e6;
}
.table .thead-dark th[data-v-c2d2ef56] {
    color: inherit;
    border-color: #dee2e6;
}
}
