input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* css for every dashboard Start*/
/* css for every dashboard Start*/
button:focus,
select:focus,
input:focus,
textarea:focus {
  box-shadow: none !important;
}
.form-floating input {
  padding-top: 41px !important;
  padding-bottom: 27px !important;
}
.form-floating label {
  padding-top: 21px;
}
/* css for every dashboard End*/

/* Custom ToolTips Design Start*/
.custom-tooltip {
  --bs-tooltip-bg: #f8f0e3;
  --bs-tooltip-color: var(--bs-dark);
}
/* Custom ToolTips Design End*/

/* Navigation Custom Css Start*/
.nav-link {
  font-size: 19px;
  color: #1a1a1a;
  border-bottom: 2px solid transparent;
}
.nav-link:focus {
  color: #3864dd;
  border-bottom: 2px solid #3864dd;
}
.rightUl .nav-link:focus {
  background-color: #dbeafe !important;
  border-bottom: 2px solid transparent;
  border-radius: 7px;
}
/* Navigation Custom Css End*/

/* Tools Dashboard Css Start */
.tools-heading {
  padding: 38px 0 30px;
}
.box {
  padding: 28px 20px 0px;
  border: 1px solid #cbd5e1;
  width: 350px;
  cursor: pointer;
}
.box:hover {
  border-color: #3864dd !important;
}
.secondRow {
  margin-top: 38px !important;
}
/* Tools Dashboard Css End */

/* Welcome Dashboard Css Start */
.welcome-heading {
  padding: 38px 0 43px;
}
tbody {
  gap: 30px;
}
tbody tr {
  line-height: 45px !important;
}
/* Welcome Dashboard Css End */

/* Lead Generation Dashboard Css Start */
.leadGen-heading {
  padding: 8px 0 20px;
}
.calculation-section hr {
  width: 80% !important;
}
.calcBtn {
  width: 12%;
}
/* Lead Generation Dashboard Css End */

/* Models Dashboard Css Start */
.backBtn {
  color: #3864dd;
}
.customBtn {
  background-color: #3864dd;
}
.goalNameSection .avatar,
td .avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
/* Models Dashboard Css End */

/* Model Name Dashboard Start*/
.calcRow {
  gap: 1rem !important;
}
.calcRow .calcBox {
  width: 31% !important;
}
.calcRow .calcBox p {
  font-size: 13px !important;
}
.searchBtn {
  border-color: #ced4da !important;
}
.input-group input:focus + .searchBtn {
  border-color: #86b7fe !important;
}
.input-group label {
  z-index: 3 !important;
}
/* Model Name Dashboard End*/

/* Compare Model Css Start */
.slotHeading {
  bottom: 50% !important;
  left: 27% !important;
}
/* Compare Model Css End */

/* All Projects Css Start */
.allProjects .avatar {
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 50%;
}
.allProjects .member-avatar {
  margin-left: -13px !important;
}
.modelTextarea {
  padding-top: 33px !important;
  height: 150px !important;
}
/* .dateIcon{
    position: absolute;
    top: 42%;
    left: 86%;
    height: 20px;
    width: 22px;
    background-color: #fff;
    z-index: 3;
} */
.plusIcon {
  position: absolute;
  top: 1.4rem !important;
  right: 4%;
}
.fileField {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}
.dashed {
  border-style: dashed !important;
}
.searchBar:focus {
  border-color: #cbd5e1;
}
/* All Projects Css End */

/* Goal Name Page Start*/
.sideNav {
  width: 8% !important;
  min-height: 100vh !important;
}
.sideNav a span {
  color: #6b7280 !important;
  font-weight: 500 !important;
}
.goalNameSection {
  width: 92%;
}
.editIcon {
  margin-right: 7rem !important;
}
.dataTable tr {
  line-height: 27px !important;
}
/* .dateField .dateIcon{
    width: 47px !important;
} */
/* Goal Name Page End*/

/* richFeelProject Start */
.projectNameSection {
  padding-right: 6rem;
}
.goalsTable-Tab tbody tr {
  line-height: 1 !important;
}
.goalsTable-Tab tbody .sideIcon {
  position: absolute !important;
  top: 16%;
  left: -2%;
}
.nestedItem {
  width: 22% !important;
}
.nestedLines {
  height: calc(100% - 106px);
  border-bottom-left-radius: 10px;
}
.accordionLine {
  position: absolute !important;
  width: 1.2rem !important;
  top: 23% !important;
  margin-left: -25px !important;
}
.general-accordion {
  opacity: 0;
  height: 0;
  transition: 0.2s ease-in-out;
}
.addKey-btn:hover {
  background-color: #dbeafe !important;
}
.sideIcon {
  position: absolute !important;
  top: 10%;
  left: -2%;
}
.modelDes {
  line-height: normal !important;
}
/* richFeelProject End */

/* Login Page Css Start */
.loginCover {
  min-height: 100vh !important;
}
/* Login Page Css End */

/* Ideas(UntitledIdea) Css Start */
.selectOpt {
  height: 3.8rem !important;
}
.teamMatesBadge {
  top: 2rem !important;
  left: 0.9rem !important;
}
.teamMatesBadge p {
  background-color: #f1f5f9 !important;
}
.form-floating-custom input {
  height: 3.8rem !important;
  padding-top: 1.625rem !important;
  padding-bottom: 0.625rem !important;
}
.form-floating-custom .avatar {
  width: 20px !important;
  height: 20px !important;
}
.form-floating-custom label {
  padding: 1rem 0.75rem !important;
}
.deleteIcon {
  top: 0.8rem !important;
  right: 0.8rem !important;
}
.checklist {
  border-color: #cbd5e1 !important;
}
.checklist-label {
  color: #6f7174 !important;
}
/* Ideas(UntitledIdea) Css End */

/* Ideas(toGetMoreLeads) Css Start */
.scoreBox {
  background-color: #1bb854 !important;
}
/* Ideas(toGetMoreLeads) Css End */

/* Ideas(retargetingAds) Css Start */
.openDialogIcon {
  bottom: 0.8rem !important;
  right: 0.8rem !important;
}
/* Ideas(retargetingAds) Css End */

/* Ideas(edit,nominate,etc) Css Start */
.uploadBox {
  padding: 1.5rem !important;
}
/* Ideas(edit,nominate,etc) Css End */

/* Tasks(untitledTask) Css Start */
.selectMembers select {
  background: none !important;
}
.selectMembers .plusIcon {
  z-index: -3 !important;
}
/* Tasks(untitledTask) Css End */

/* learnings page Css Start */
.favouriteCheck {
  color: #ca8a04;
  border-color: #ca8a04 !important;
}
.favouriteCheck .form-check-input:checked {
  background-color: #ca8a04 !important;
  border-color: #ca8a04 !important;
}
.sortTag {
  width: 60% !important;
}
.header .profilePic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
/* learnings page Css End */

/* User Management Pages Css Start */
.sideNav2 {
  width: 15% !important;
  min-height: 100vh !important;
}
.sideNav2 .customLink:focus {
  color: #3864dd !important;
}
.userManagementSection {
  width: 85% !important;
  background: #f3f4f6 !important;
  min-height: 100vh !important;
}
.userManagementSection tbody tr {
  line-height: normal !important;
}
.scrollTable {
  min-height: 19rem !important;
  max-height: 19rem !important;
}
.highlightCheck {
  border-color: #3864dd !important;
}
.highlightCheck .form-check-input:checked {
  border-color: #3864dd !important;
}
/* User Management Pages Css End */

/* Responsiveness for Dashboards */

@media only screen and (max-width: 991px) {
  .navbar-brand img {
    margin-right: 13px !important;
  }
  .brandName::after {
    content: "Scalez Media";
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
    border: 1px solid #3864dd;
  }
  .nav-link:focus {
    color: #3864dd;
    border-bottom: 2px solid transparent;
  }
  .rightUl {
    display: flex;
    flex-direction: row;
    margin-left: 32px;
  }
  .rightUl .nav-link:focus {
    border-bottom: 2px solid #3864dd;
  }
  .tasks {
    padding-right: 0.75rem !important;
  }
  .projects {
    padding-left: 0.75rem !important;
    padding-top: 16px !important;
  }
  .calculation-section hr {
    width: 100% !important;
  }
  .pad {
    padding-left: 10px !important;
  }
  .formSec {
    display: flex;
    justify-content: center;
  }
  .form-pad {
    padding-right: 0 !important;
  }
  .calcBtn {
    width: 100%;
  }
  .calcRow-responsive {
    margin-top: 3rem !important;
  }
  .output-heading {
    margin-top: 2rem !important;
  }
  .slotHeading {
    bottom: 25% !important;
  }
  .slotHeading h3 {
    font-size: 20px !important;
  }
  .btnOne {
    width: 50% !important;
  }
  .sideIcon {
    left: -3% !important;
  }
  .favouriteCheck {
    width: 40% !important;
  }
  .sideNav2 {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .newModel {
    display: none;
  }
  .buttonsSide {
    margin-top: 10px;
    justify-content: start !important;
  }
  .allProjectsHeading .buttonsSide {
    margin-top: 14px;
    align-items: start !important;
  }
  .allProjects .modelsTab .fa-arrow-up {
    display: none !important;
  }
  .allProjects .pad {
    padding-left: 0px !important;
  }
  .allProjects .modelsTab {
    padding: 0 !important;
    font-size: 13px !important;
    overflow-x: scroll;
  }
  .allProjects .modelsTab tbody tr {
    line-height: 20px !important;
  }
  .goalNameSection .buttonsSide {
    margin-top: 20px;
    flex-direction: row-reverse !important;
    justify-content: start !important;
    align-items: center !important;
  }
  .metricGoal {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .metricGoal a {
    margin-right: 0 !important;
  }
  .sideNav {
    width: 15% !important;
  }
  .goalNameSection {
    width: 85% !important;
  }
  .sideIcon {
    left: -4% !important;
  }
  .nestedLines-Two {
    margin-top: 3rem !important;
  }
  .projectNameSection select {
    background-image: none !important;
  }
  /* .projectNameSection .dateIcon{
        width: 46px !important;
    }
     */
  .nominatedBtn {
    margin-bottom: 0 !important;
    padding-left: 0 !important;
  }
  .sideNav2 {
    width: 18% !important;
  }
  .userManagementSection {
    width: 82% !important;
    padding-right: 1rem !important;
  }
}

@media only screen and (max-width: 425px) {
  .brandName::after {
    content: "";
  }
  .model-section .pad {
    padding-left: 0px !important;
  }
  .modelsTab {
    padding: 0 !important;
  }
  .modelsTab tbody tr {
    line-height: normal !important;
  }
  .sideNav {
    width: 19% !important;
  }
  .goalNameSection {
    width: 81% !important;
    padding-right: 10px !important;
    padding-left: 20px !important;
  }
  .metricGoal-btn {
    padding: 5px 0 !important;
    width: 75px;
    font-size: 15px;
  }
  .sideIcon {
    left: -5% !important;
  }
  .nestedLines-Two {
    margin-top: 5rem !important;
  }
  /* .projectNameSection .dateIcon{
        width: 29px !important;
    } */
}
@media only screen and (min-width: 1500px) {
  .goalsTable-Tab tbody .sideIcon {
    left: -1.2rem !important;
  }
}
